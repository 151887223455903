import { Chip } from '@mui/material';
import { useRefinementList } from 'react-instantsearch';
import { Product_Label_Name_Enum } from 'kheops-graphql';
import LabelsIcon from '../../assets/icons/labels.svg?react';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import { logoLabels } from '../../packaging/packaging-formatter';

export default function ProductLabelsTags(): React.JSX.Element {
  const { refine } = useRefinementList({ attribute: 'labels' });
  const currentRefinements = useNormalizedCurrentRefinements('labels') as string[];

  return (
    <>
      {currentRefinements.map((refinement) => (
        <Chip
          key={refinement}
          variant="filter"
          icon={<LabelsIcon />}
          label={logoLabels[refinement as Product_Label_Name_Enum].alt}
          onDelete={() => refine(refinement)}
        />
      ))}
    </>
  );
}
