import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import GeolocIcon from '../../assets/icons/geoloc.svg?react';
import { currentContextAtom } from '../../state';
import { computeDistanceInKm } from '../utils/common.utils';

interface CompanyDistanceChipProps {
  targetGeoLoc: { lat: number, lng: number };
}

export default function CompanyDistanceChip({ targetGeoLoc }: CompanyDistanceChipProps): React.JSX.Element {
  const { companyGeoloc } = useAtomValue(currentContextAtom);

  const distance = useMemo((): string => {
    if (!companyGeoloc || !targetGeoLoc) {
      return '';
    }

    const result = computeDistanceInKm(
      targetGeoLoc.lat,
      targetGeoLoc.lng,
      companyGeoloc.lat,
      companyGeoloc.lng,
    ).toFixed(0);

    return `${result} km`;
  }, [targetGeoLoc, companyGeoloc]);

  return (
    <Chip variant="tag" icon={<GeolocIcon />} label={distance} />
  );
}
