import { Box, Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import useOpenable from '../../../hooks/useOpenable';
import { currentContextAtom } from '../../../state';
import FormDrawer from '../FormDrawer';
import Address from './Address';
import AddressForm, { AddressDraft } from './AddressForm';
import { useUpsertAdditionalAddressMutation } from '../../../mutations/__generated__/upsertAdditionalAddress.generated';
import { useUpdateCompanyMutation } from '../../../mutations/__generated__/updateCompany.generated';
import { UserRoleDocument } from '../../../queries/__generated__/userRole.generated';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import useResolvedAdditionalAddress from './hook/useResolvedAdditionalAddress';

export default function DeliveryAddressInfo(): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const { open, close, isOpen } = useOpenable();
  const [upsertDeliveryAddress] = useUpsertAdditionalAddressMutation();
  const [updateCompany] = useUpdateCompanyMutation({ refetchQueries: [UserRoleDocument], awaitRefetchQueries: true });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { companyId, companyName, companyAddress, deliveryInstructions, deliveryPhoneNumber, deliveryEmail, deliveryAddress } = useAtomValue(currentContextAtom);

  const currentDeliveryAddress = useResolvedAdditionalAddress({ companyName, companyAddress, additionalAddress: deliveryAddress });

  const onSubmit = async (values: AddressDraft): Promise<void> => {
    const { data } = await upsertDeliveryAddress({
      variables: {
        address: values.address,
      },
    });

    if (data?.insert_additional_address_one?.id) {
      await updateCompany({
        variables: {
          companyId,
          companyUpdateData: {
            delivery_address_id: data.insert_additional_address_one.id,
            delivery_instructions: values.additionalInfo,
            delivery_email: values.email,
            delivery_phone_number: values.phoneNumber,
          },
        },
      });

      setCommonSnackbarProps({
        label: t('settings:delivery_address_updated'),
        snackbarProps: {
          open: true,
        },
      });
    }

    close();
  };

  return (
    <>
      <Box
        sx={{
          '& .MuiTypography-root': {
            lineHeight: 'normal',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            '& div:nth-child(2)': {
              backgroundColor: 'surfaceContainer.main',
            },
          }}
        >
          <Address
            address={currentDeliveryAddress}
            additionalInfoTitle={t('settings:delivery_instructions')}
            additionalInfo={deliveryInstructions}
            email={deliveryEmail}
            phoneNumber={deliveryPhoneNumber}
          />
          <Button
            variant="tonal"
            onClick={open}
            startIcon={<EditOutlinedIcon />}
            sx={{
              alignSelf: 'end',
            }}
          >
            {t('common:modify')}
          </Button>
        </Box>
      </Box>
      <FormDrawer
        title={t('settings:edit_delivery_address')}
        open={isOpen}
        onClose={close}
        FormComponent={AddressForm}
        formProps={{
          address: currentDeliveryAddress,
          phoneNumber: deliveryPhoneNumber,
          email: deliveryEmail,
          additionalInfo: deliveryInstructions,
          additionalInfoLabel: 'settings:delivery_instructions',
          onSubmit,
        }}
      />
    </>
  );
}
