import { Box, Card, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../routes/AppRoutes';
import FileUtils from '../../common/utils/file.utils';
import SizedImage from '../../common/components/SizedImage';
import { ProductFragment } from '../products';
import BaseUnitStatus from '../common/BaseUnitStatus';
import ProductListPackagingInfo from './ProductListPackagingInfo';
import BaseUnitGtin from '../common/BaseUnitGtin';

interface ProductListItemProps {
  product: ProductFragment;
}

export default function ProductListItem({ product }: ProductListItemProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  const productPageLink = useMemo((): string => {
    return generatePath(RoutePaths.PRODUCT, { productSku: `${product.sku}`, companyId: product.company_id });
  }, [product]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }} key={product.id}>
      <Box
        component={Link}
        to={productPageLink}
        sx={{
          textDecoration: 'none',
          color: 'unset',
          alignSelf: 'start',
        }}
      >
        <Typography variant="bodyLarge">
          {product.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          flexWrap: 'wrap',
          pt: 0.5,
          pl: 0.5,
          gap: {
            xs: 1,
            sm: 1.5,
          },
        }}
      >
        {product.base_units.map((baseUnit, baseUnitIndex) => (
          <Card
            component={Link}
            to={productPageLink}
            sx={{
              textDecoration: 'none',
              backgroundColor: 'surfaceContainerLow.main',
              color: 'unset',
              display: 'flex',
              p: 1.5,
              borderRadius: 4,
            }}
            key={baseUnit.id}
          >
            <SizedImage
              src={FileUtils.BuildPackagingImageUrl({
                photo: baseUnit.main_photo,
                type: product.sub_family,
                size: {
                  height: 96,
                  width: 96,
                  fit: 'contain',
                },
              })}
              alt={t('products:csu')}
              width={{
                xs: 72,
                md: 96,
              }}
              height={{
                xs: 72,
                md: 96,
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, rowGap: 1, width: '100%' }}>
              <BaseUnitStatus baseUnit={baseUnit} baseUnitIndex={baseUnitIndex} product={product} variant="bodyMedium" />
              {!!baseUnit.gtin && (
                <BaseUnitGtin gtin={baseUnit.gtin} />
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                {baseUnit.packagings.map((packaging) => (
                  <ProductListPackagingInfo baseUnit={baseUnit} packaging={packaging} key={packaging.sku} />
                ))}
              </Box>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
