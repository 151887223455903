import { Box, Button, Divider, Grid2, Typography, useTheme } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Barcode } from 'scandit-web-datacapture-barcode';
import ClearFiltersButton from '../filters/ClearFiltersButton';
import QueryTag from '../filters/QueryTag';
import GtinTags from '../filters/GtinTags';
import ProductFamilyTags from '../filters/ProductFamilyTags';
import CompanyTags from '../filters/CompanyTags';
import FullTextSearch from '../../common/components/FullTextSearch';
import GtinFilter from '../filters/GtinFilter';
import ProductFamiliesFilter from '../filters/ProductFamiliesFilter';
import CompanyFilter from '../filters/CompanyFilter';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import { DisplayMode, singleOrderPageDisplayModeAtom } from '../state/state';
import BarcodeCaptureButton from './BarcodeCaptureButton';
import useUpdateBasketByBarCode from './useUpdateBasketByBarCode';

interface SearchProductPageToolsHeaderProps {
  setFullTextSearchValue: Dispatch<SetStateAction<string | undefined>>;
  fullTextSearchValue: string | undefined;
}

export default function SearchProductPageToolsHeader({ setFullTextSearchValue, fullTextSearchValue }: SearchProductPageToolsHeaderProps): React.JSX.Element {
  const theme = useTheme();
  const breakpoint = useCurrentBreakpoint();
  const { t } = useTranslation(['search', 'products', 'contracts']);
  const [displayMode, setDisplayMode] = useAtom(singleOrderPageDisplayModeAtom);
  const { upsertPackagingByBarCode } = useUpdateBasketByBarCode();

  useEffect(() => {
    const isSmall = breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md';

    if (displayMode === DisplayMode.LIST && isSmall) {
      setDisplayMode(DisplayMode.GRID);
    }
  }, [breakpoint]);

  const handleDisplayModeClick = useCallback((): void => {
    setDisplayMode(displayMode === DisplayMode.GRID ? DisplayMode.LIST : DisplayMode.GRID);
  }, [displayMode]);

  const handleBarcodeCapture = (barcodes: Barcode[]): void => {
    if (barcodes[0].data) {
      setFullTextSearchValue(barcodes[0].data);
      upsertPackagingByBarCode(barcodes[0].data);
    }
  };

  return (
    <>
      <Box sx={{ pt: 2 }}>
        <Typography
          variant="headlineMedium"
          sx={{
            mb: 2,
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        >
          {t('search:place_an_order_at_your_suppliers')}
        </Typography>
        <Grid2 container columnSpacing={{ xs: 0.5, sm: 1.5 }}>
          <Grid2
            size={{ xs: 6, sm: 8, xl: 7 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'surfaceContainer.main',
                borderRadius: 10,
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                '& .MuiDivider-root': {
                  mx: 1,
                  height: '24px',
                },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <CompanyFilter attribute="company" />
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
              />
              <Box
                sx={{
                  flex: 1,
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
              >
                <ProductFamiliesFilter attribute="family" variant="text" />
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    display: 'none',
                  },
                }}
              />
              <Box
                sx={{
                  flex: 1,
                  [theme.breakpoints.down('lg')]: {
                    display: 'none',
                  },
                }}
              >
                <GtinFilter />
              </Box>
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 6, sm: 4, lg: 3, xl: 4 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FullTextSearch
              inputProps={{
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  height: 48,
                  backgroundColor: 'surfaceContainerHighest.main',
                  borderRadius: 10,
                  display: 'flex',
                  width: '100%',
                },
              }}
              value={fullTextSearchValue}
              onChange={setFullTextSearchValue}
            />
          </Grid2>
          <Grid2
            size={{ xs: 1 }}
            sx={{
              display: 'flex',
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
              alignItems: 'center',
            }}
          >
            <Button variant="outlined" onClick={handleDisplayModeClick}>
              {displayMode === DisplayMode.GRID ? <ListIcon /> : <GridViewIcon />}
            </Button>
          </Grid2>
        </Grid2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 1,
            pt: 1,
            px: 1.5,
            pb: {
              xs: 0,
              sm: 2,
            },
          }}
        >
          <CompanyTags attribute="company" />
          <ProductFamilyTags attribute="family" />
          <GtinTags />
          <QueryTag />
          <ClearFiltersButton
            attributesToCheck={[
              'company',
              'family',
              'hasGtin',
            ]}
          />
        </Box>
      </Box>
      <BarcodeCaptureButton onCapture={handleBarcodeCapture} />
    </>
  );
}
