import { Box, Chip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Base_Unit, Base_Unit_Type_Enum, Measurement_Unit_Enum, Packaging } from 'kheops-graphql';
import { FormatLogisticalPackaging, FormatMeasurementUnit, FormatPackagingUnitPrice, FormatPackagingVolumePrice, formatPriceByBillingType, formatSuggestedRetailPrice } from 'kheops-utils';
import useCurrencyFormat from '../../../../hooks/useCurrencyFormat';
import { formatPackagingPriceIncludingDescriptor } from '../../../../common/utils/common.utils';

interface PackagingInfoProps {
  packaging: Pick<Packaging, 'content_measurement_unit' | 'net_content' | 'price' | 'trade_item_unit_descriptor' | 'unit_quantity'>;
  baseUnit: Pick<Base_Unit, 'content_measurement_unit' | 'billing_type' | 'unit_net_content' | 'unit_quantity' | 'unit_type' | 'suggested_retail_price'>;
}

export default function PackagingInfo({ packaging, baseUnit }: PackagingInfoProps): React.JSX.Element {
  const { t } = useTranslation(['price', 'products']);
  const currencyFormat = useCurrencyFormat();

  const volumePrice = useMemo(
    () => FormatPackagingVolumePrice(packaging, baseUnit),
    [],
  );

  const formattedVolumePrice = useMemo((): string => {
    return `${currencyFormat.format(volumePrice.value)}/${FormatMeasurementUnit(volumePrice.unit, 'fr', 1)}`;
  }, [volumePrice]);

  const suggestedRetailPrice = useMemo((): string => {
    if (!baseUnit.suggested_retail_price) {
      return '';
    }

    return formatSuggestedRetailPrice(
      {
        suggestedRetailPrice: baseUnit.suggested_retail_price,
        billingType: baseUnit.billing_type,
        netContent: (baseUnit.unit_net_content || packaging.net_content) as number,
        unitType: baseUnit.unit_type!,
        measurementUnit: (baseUnit.content_measurement_unit || packaging.content_measurement_unit) as Measurement_Unit_Enum,
      },
    );
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'surfaceContainer.main',
        borderRadius: 3,
        px: 2,
        py: 1,
      }}
    >
      <Box>
        <Typography color="primary" variant="titleMedium">
          {
            baseUnit.unit_type !== Base_Unit_Type_Enum.Bulk
              ? formatPriceByBillingType(currencyFormat.format(FormatPackagingUnitPrice(packaging)), baseUnit.billing_type)
              : formattedVolumePrice
          }
        </Typography>
        {baseUnit.suggested_retail_price && (
          <Typography variant="bodySmall">
            {t('price:suggest_retail_price_acronym')} : {suggestedRetailPrice}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            columnGap: 1,
            rowGap: 0.5,
            mb: 0.5,
          }}
        >
          <Chip
            variant="tag"
            label={FormatLogisticalPackaging(packaging, 'fr', baseUnit)}
            sx={{
              backgroundColor: 'surfaceContainerHighest.main',
            }}
          />
          <Typography variant="bodySmall">
            {formatPackagingPriceIncludingDescriptor(t, formatPriceByBillingType(currencyFormat.format(packaging.price), baseUnit.billing_type), packaging.trade_item_unit_descriptor)}
          </Typography>
        </Box>
        {baseUnit.unit_type !== Base_Unit_Type_Enum.Bulk && (
          <Typography variant="bodySmall" color="secondary">
            {formattedVolumePrice}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
