import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { FixedSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import TrumpeterButterflyImage from '../../../assets/images/trumpeter_butterfly.png';
import SubCatalogFormPackagingCard from './SubCatalogFormPackagingCard';
import { displayedCompanyPackagingsAtom } from './state';
import EmptyPagePlaceholder from '../../../common/components/EmptyPagePlaceholder';

export default function SubCatalogPackagingsForm(): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['products']);
  const displayedSubCatalogPackagings = useAtomValue(displayedCompanyPackagingsAtom);
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));

  const { columnCount, rowHeight, columnMargin } = useMemo(() => {
    if (isDownLg) {
      return { columnCount: 1, rowHeight: 150, columnMargin: 16 };
    }

    return { columnCount: 2, rowHeight: 150, columnMargin: 8 };
  }, [isDownLg]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const VirtualCell: any = useCallback(({ columnIndex, rowIndex, style }: any) => {
    const cellIndex = rowIndex * columnCount + columnIndex;

    if (cellIndex < displayedSubCatalogPackagings.length) {
      return (
        <div style={style}>
          <Box
            sx={{
              backgroundColor: 'surfaceContainerLow.main',
              mr: {
                xs: 0,
                lg: cellIndex % 2 === 0 ? 1.5 : 0,
              },
              borderRadius: 3,
            }}
          >
            <SubCatalogFormPackagingCard packaging={displayedSubCatalogPackagings[cellIndex]} />
          </Box>
        </div>
      );
    }
  }, [displayedSubCatalogPackagings]);

  return (
    <Box sx={{ flex: '1 1 auto', mx: 1 }}>
      {displayedSubCatalogPackagings.length > 0
        ? (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeGrid
                height={height}
                columnWidth={width / columnCount - columnMargin}
                rowHeight={rowHeight}
                columnCount={columnCount}
                width={width}
                rowCount={Math.ceil(displayedSubCatalogPackagings.length / columnCount)}
              >
                {VirtualCell}
              </FixedSizeGrid>
            )}
          </AutoSizer>
        )
        : (
          <EmptyPagePlaceholder
            imageSrc={TrumpeterButterflyImage}
            title={t('products:no_product')}
          />
        )}

    </Box>
  );
}
