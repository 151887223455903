import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { generatePath, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Configure, InstantSearch } from 'react-instantsearch';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useSearchClient } from '../hooks/useSearchClient';
import { SearchContract } from '../search/product/SearchProductHit';
import SearchProductHits from '../search/product/SearchProductHits';
import { HistoryIndexUiState, OnStateChangeArgs, companyPageDisplayModeAtom } from '../search/state/state';
import CompanyProductFilters from './CompanyProductFilters';
import { currentContextAtom } from '../state';
import { EmptyPagePlaceholderProps } from '../common/components/EmptyPagePlaceholder';
import TrumpeterButterflyImage from '../assets/images/trumpeter_butterfly.png';
import { RoutePaths } from '../routes/AppRoutes';
import SearchPagination from '../search/SearchPagination';
import FilterUtils from '../search/filters/filter.utils';
import { isWaitingForSubCatalogsSyncAtom, refreshCompanyProductListAtom } from './state';

interface CompanyProductSearchProps {
  companyId: string;
  contextCompanyId: string;
  contract?: SearchContract;
}

export default function CompanyProductSearch({ companyId, contextCompanyId, contract }: CompanyProductSearchProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const { realm } = useAtomValue(currentContextAtom);
  const searchClient = useSearchClient();
  const location = useLocation();
  const setRefresh = useSetAtom(refreshCompanyProductListAtom);
  const setIsWaitingForSubcatalogsSync = useSetAtom(isWaitingForSubCatalogsSyncAtom);
  const displayMode = useAtomValue(companyPageDisplayModeAtom);
  const theme = useTheme();
  const isDownXl = useMediaQuery(theme.breakpoints.down('xl'));
  const productFilters = useMemo(() => {
    return `company_id:${companyId}${contract ? ` AND ${FilterUtils.visiblePackagingsForBuyerFilter(contract.buyingCompanyId)}` : ''}`;
  }, []);

  const emptyPagePlaceholderProps = useMemo((): EmptyPagePlaceholderProps => {
    let props: EmptyPagePlaceholderProps;

    if (realm === Business_Profile_Enum.Buyer) {
      props = {
        title: t('products:no_product'),
        description: t('products:no_product_available_for_this_supplier'),
        imageSrc: TrumpeterButterflyImage,
      };
    } else {
      props = {
        title: t('products:no_product'),
        description: t('products:no_product_available_description'),
        imageSrc: TrumpeterButterflyImage,
        buttonLabel: t('products:see_my_products'),
        linkDestination: generatePath(RoutePaths.COMPANY_PRODUCTS),
      };
    }

    return props;
  }, []);

  useEffect(() => {
    if (location.state?.newSubCatalogId) {
      setIsWaitingForSubcatalogsSync(true);
      const timeout = setTimeout(
        () => {
          setRefresh(true);
          setIsWaitingForSubcatalogsSync(false);
        },
        4000, // We need to wait for the indexation of packagings records. 4s corresponds to a high waiting time for a company with over 200 products.
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  const handleStateChange = ({ uiState, setUiState }: OnStateChangeArgs): void => {
    window.history.replaceState({
      ...window.history.state,
      indexUiState: uiState[import.meta.env.REACT_APP_ALGOLIA_PACKAGING_SORT_ALPHA_INDEX],
    }, '');
    setUiState(uiState);
  };

  return (
    <InstantSearch
      indexName={import.meta.env.REACT_APP_ALGOLIA_PACKAGING_SORT_ALPHA_INDEX}
      searchClient={searchClient}
      initialUiState={{
        [import.meta.env.REACT_APP_ALGOLIA_PACKAGING_SORT_ALPHA_INDEX]: {
          ...window.history.state.indexUiState as HistoryIndexUiState,
        },
      }}
      onStateChange={handleStateChange}
    >
      <Configure
        hitsPerPage={isDownXl ? 24 : 25}
        filters={productFilters}
      />
      <CompanyProductFilters contextCompanyId={contextCompanyId} />
      <Box sx={{ mt: 2 }}>
        <SearchProductHits
          hitProps={{ hideCompany: true }}
          displayMode={displayMode}
          cardSizes={{ md: 4 }}
          currentContract={contract}
          companyId={companyId}
          emptyPlaceholderProps={emptyPagePlaceholderProps}
        />
        <SearchPagination sx={{ width: 'fit-content', mt: 2 }} />
      </Box>
    </InstantSearch>
  );
}
