import { Box, Chip, CircularProgress, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { RoutePaths } from '../routes/AppRoutes';
import { useBasketOrders } from '../hooks/useBasketOrders';
import FileUtils from '../common/utils/file.utils';
import { PageWrapper } from '../common/components/PageWrapper';
import BackBar from '../common/components/BackBar';
import { currentContextAtom } from '../state';
import { BasketItem } from './state/basket';
import { basketDataAtom, isBasketLoadingAtom } from './state/state';
import BasketOrderSummary from './BasketOrderSummary';
import BasketSummary from './BasketSummary';
import { BasketSummaryEmpty } from './BasketSummaryEmpty';
import BackButton from '../common/components/BackButton';

export function BasketSummaryPage(): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { realm } = useAtomValue(currentContextAtom);
  const dataBasket = useAtomValue(basketDataAtom);
  const loading = useAtomValue(isBasketLoadingAtom);

  const basketItems = (dataBasket?.items || []) as BasketItem[];
  const orders = useBasketOrders({ basketItems: basketItems || [], photoSize: { height: 156, width: 156 } });

  return (
    <>
      <BackBar to={RoutePaths.ROOT} />
      <PageWrapper>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading && !dataBasket
          ? (
            <Box sx={{ display: 'flex', width: '100%', marginTop: 16, justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          )
          : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <BackButton
                  to={RoutePaths.ROOT}
                  compact
                  sx={{
                    mr: 2,
                    display: {
                      xs: 'none',
                      lg: 'inline-flex',
                    },
                  }}
                />
                <Typography variant="titleLarge">
                  {
                    realm === Business_Profile_Enum.Buyer
                      ? t('basket:my_basket')
                      : t('basket:my_order_suggestion', { count: Object.keys(orders).length })
                  }
                </Typography>
                <Chip
                  variant="tag"
                  color="surfaceContainer"
                  sx={{ ml: 1 }}
                  label={`${basketItems.length} ${t('common:product', { count: basketItems.length })}`}
                />
              </Box>
              {basketItems && (
                <>
                  <Grid2
                    container
                    spacing={2}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        mb: 25,
                      },
                    }}
                  >
                    <Grid2
                      size={{ xs: 12, md: 8 }}
                    >
                      {basketItems.length
                        ? Object.keys(orders).sort((a, b) => orders[a].name.localeCompare(orders[b].name)).map((contractId) => {
                          return (
                            <Box
                              sx={{
                                p: {
                                  xs: 1,
                                  lg: 1.5,
                                },
                                marginBottom: 1.5,
                              }}
                              key={contractId}
                            >
                              <BasketOrderSummary
                                items={orders[contractId].items}
                                name={orders[contractId].name}
                                photoUrl={orders[contractId].photoUrl ? orders[contractId].photoUrl : FileUtils.GetBusinessPlaceHolderImage('SUPPLIER')}
                                contractReferenceId={contractId}
                              />
                            </Box>
                          );
                        })
                        : (
                          <Box
                            sx={{
                              backgroundColor: 'surfaceContainerLowest.main',
                              borderRadius: 4,
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <BasketSummaryEmpty />
                          </Box>
                        )}
                    </Grid2>
                    {!isDownMd && (
                      <Grid2 size={{ xs: 12, sm: 4 }}>
                        <Box
                          sx={{
                            backgroundColor: 'surfaceContainerLowest.main',
                            borderRadius: 4,
                            padding: 2,
                            position: 'sticky',
                            top: 100,
                          }}
                        >
                          <BasketSummary items={basketItems} />
                        </Box>
                      </Grid2>
                    )}
                  </Grid2>
                  {isDownMd && (
                    <Box
                      sx={{
                        backgroundColor: 'surfaceContainerLowest.main',
                        borderRadius: 4,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        p: 3,
                        position: 'fixed',
                        bottom: {
                          xs: 72,
                          md: 0,
                        },
                        left: 0,
                        width: `calc(100% - ${theme.spacing(6)})`,
                        boxShadow: '0px -4px 16px 0px rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      <BasketSummary items={basketItems} />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
      </PageWrapper>
    </>
  );
}
