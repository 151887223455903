import { Box, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { BusinessProfile, Business_Profile_Enum, Company, Contract } from 'kheops-graphql';
import { currentContextAtom } from '../state';
import { DisplayPhoto } from '../common/common';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import { useCreateGroupChannelMutation } from '../mutations/__generated__/createGroupChannel.generated';
import { ContractsByCompanyIdDocument } from '../queries/__generated__/contractsByCompanyId.generated';
import { currentChannelAtom, newChannelSlideOpenAtom } from './state';
import useJoinDefaultChannel from './hooks/useJoinDefaultChannel';
import { ContractsAsSupplierDocument } from '../queries/__generated__/contractsAsSupplier.generated';
import { ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';

type ContactableCompany = Pick<Company, 'id' | 'tradeName'> & { photos: DisplayPhoto[] };
type ContactableContract = Pick<Contract, 'id'>
  & {
    buying_company: ContactableCompany;
    supplying_company: ContactableCompany;
  };

export interface ContactableCompanyRowProps {
  contract: ContactableContract;
  channelUrl?: string;
}

export default function ContactableCompanyRow({ contract, channelUrl }: ContactableCompanyRowProps): React.JSX.Element {
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const { t } = useTranslation(['common']);
  const { realm } = useAtomValue(currentContextAtom);
  const [createGroupChannel, { data: createGroupChannelData, loading: createLoading }] = useCreateGroupChannelMutation({ refetchQueries: [ContractsByCompanyIdDocument, ContractsAsSupplierDocument, ContractsAsBuyerDocument] });
  const [joinDefaultChannel, { joined, loading: joinLoading }] = useJoinDefaultChannel();
  const setCurrentChannel = useSetAtom(currentChannelAtom);
  const setNewChannelSlideOpen = useSetAtom(newChannelSlideOpenAtom);
  const company = realm === Business_Profile_Enum.Buyer ? contract.supplying_company : contract.buying_company;
  const [enableClick, setEnableClick] = useState(true);

  const handleRowClick = (): void => {
    setEnableClick(false);

    if (channelUrl) {
      joinDefaultChannel({
        channelUrl,
        contractId: contract.id,
      });
    } else {
      createGroupChannel({
        variables: {
          buyingCompanyId: contract.buying_company.id,
          supplyingCompanyId: contract.supplying_company.id,
          fromBusinessProfile: realm as unknown as BusinessProfile,
        },
      });
    }
  };

  useEffect((): void => {
    const updateCurrentChannel = async (newChannelUrl: string): Promise<void> => {
      const channelInstance = await sdkInstance?.groupChannel.getChannel(newChannelUrl);

      setCurrentChannel(channelInstance);
      setNewChannelSlideOpen(false);
    };

    if (createGroupChannelData?.createGroupChannel?.channelUrl) {
      updateCurrentChannel(createGroupChannelData?.createGroupChannel?.channelUrl);
    } else if (joined) {
      updateCurrentChannel(channelUrl!);
      setNewChannelSlideOpen(false);
    }
  }, [createGroupChannelData, joined]);

  return (
    <Box
      component={LoadingButton}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: 2,
        p: 1,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      onClick={handleRowClick}
      disabled={!enableClick}
      loading={createLoading || joinLoading}
      loadingIndicator={<CircularProgress size={20} color="primary" />}
    >
      <SizedImage
        src={
          FileUtils.BuildCompanyImageUrl({
            photo: company.photos[0]?.photo,
            size: { height: 40, width: 40 },
            type: realm === Business_Profile_Enum.Buyer ? Business_Profile_Enum.Supplier : Business_Profile_Enum.Buyer,
          })
        }
        borderRadius={4}
        alt={t('common:company')}
        height={40}
        width={40}
      />
      {!createLoading && !joinLoading && (
        <Typography variant="bodySmall" color="onSurface" sx={{ ml: 1 }}>
          {company.tradeName}
        </Typography>
      )}
    </Box>
  );
}
