import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, Drawer, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import useOpenable from '../../hooks/useOpenable';
import { DraftCustomPriceList } from './state';
import CustomPriceListInfoForm from './CustomPriceListInfoForm';

interface CustomPriceListInfoFormDrawerButtonProps {
  name: string;
  contracts: string[];
  setName: Dispatch<SetStateAction<string>>;
  setContracts: Dispatch<SetStateAction<string[]>>;
}

export default function CustomPriceListInfoFormDrawerButton({ setName, setContracts, name, contracts }: CustomPriceListInfoFormDrawerButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const { watch, resetField, formState } = useFormContext<DraftCustomPriceList>();
  const customPriceListFormContracts = watch('contracts');
  const customPriceListFormName = watch('name');
  const { isOpen, open, close } = useOpenable();

  const handleConfirmUpdates = (): void => {
    setName(customPriceListFormName);
    setContracts(customPriceListFormContracts);
    close();
  };

  const handleQuitForm = (): void => {
    resetField('name', { defaultValue: name, keepDirty: true }); // We keep it dirty to not deactivate form button if user quits drawer on second changes
    resetField('contracts', { defaultValue: contracts, keepDirty: true });
    close();
  };

  return (
    <>
      <Button variant="text" onClick={open}>
        <SettingsIcon />
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleQuitForm}
        anchor="bottom"
        PaperProps={{
          sx: {
            height: 480,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <SettingsIcon />
              <Typography variant="titleLarge">{t('settings:settings')}</Typography>
            </Box>
            <Button variant="text" onClick={close}>
              <CloseIcon />
            </Button>
          </Box>
          <CustomPriceListInfoForm />
          <Button
            variant="contained"
            disabled={!formState.isValid}
            fullWidth
            onClick={handleConfirmUpdates}
          >
            {t('common:update')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
