import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
import { useAtomValue } from 'jotai';
import { Payment_Method_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentDownloadFile } from '../../hooks/useDownloadFile';
import useOrderInvoiceType from '../../hooks/useOrderInvoiceType';
import { documentDownloadUrlsAtom, orderInvoiceAtom } from '../state/state';

interface OrderInvoiceButtonProps {
  orderFriendlyId: string;
  hasCommission: boolean;
  hasInvoiceGeneration: boolean;
  paymentMethod: Payment_Method_Enum;
}

export default function OrderInvoiceButton({ orderFriendlyId, hasCommission, hasInvoiceGeneration, paymentMethod }: OrderInvoiceButtonProps): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const invoiceType = useOrderInvoiceType(hasCommission, hasInvoiceGeneration, paymentMethod);
  const documentDownloadUrls = useAtomValue(documentDownloadUrlsAtom);
  const invoice = useAtomValue(orderInvoiceAtom);
  const invoiceReference = invoice?.revised_reference || invoice?.reference;

  const downloadUrl = useMemo(
    () => documentDownloadUrls.get(invoiceType),
    [invoiceType, documentDownloadUrls],
  );

  const handleButtonClick = useDocumentDownloadFile({
    documentType: invoiceType,
    downloadUrl: downloadUrl!,
    orderId: orderFriendlyId,
    documentReference: invoiceReference!,
  });

  return (
    <LoadingButton
      variant="outlined"
      onClick={handleButtonClick}
      loading={!downloadUrl}
      startIcon={<FileDownloadOutlinedIcon />}
      sx={{ flex: 'auto' }}
    >
      {t('order:see_invoice')}
    </LoadingButton>
  );
}
