import { AlgoliaHit } from 'instantsearch.js';
import { Base_Unit_Type_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { FormatMeasurementUnit, formatPriceByBillingType } from 'kheops-utils';
import { ProductHit } from '../../search/search';
import { UserDeliveryZone } from '../models/user.models';
import { insensitiveStringIncludes } from './common.utils';

export default class SearchUtils {
  static getFormatedUnitPrice(hit: AlgoliaHit<ProductHit>, formatter: (value: number | bigint) => string): string {
    return hit.baseUnitType === Base_Unit_Type_Enum.Bulk
      ? `${formatter(hit.volumePrice.value)}/${FormatMeasurementUnit(hit.volumePrice.unit as Measurement_Unit_Enum, 'fr', 1)}`
      : formatPriceByBillingType(formatter((hit.price) / hit.unitQuantity), hit.billingType);
  }

  static getFormatedSuggestedRetailPrice(hit: AlgoliaHit<ProductHit>, formatter: (value: number | bigint) => string): string {
    return hit.baseUnitType === Base_Unit_Type_Enum.Bulk
      ? `${formatter(hit.suggestedRetailPrice!)}/${FormatMeasurementUnit(hit.volumePrice.unit as Measurement_Unit_Enum, 'fr', 1)}`
      : formatPriceByBillingType(formatter(hit.suggestedRetailPrice!), hit.billingType);
  }

  /**
   * This function computes wether an area can be delivered given reference delivery areas
   * @param referenceDeliveryAreas
   * @param administrativeAreaLvl2ToDeliver  This has to be an administrative area of level 2
   */
  static canItDeliverAnArea(referenceDeliveryAreas: UserDeliveryZone[] | string[] | undefined, administrativeAreaLvl2ToDeliver: string): boolean {
    if (referenceDeliveryAreas === undefined || referenceDeliveryAreas.length === 0) {
      // Here we fallback on can deliver if company did not specify their delivery zones
      return true;
    }

    if (typeof referenceDeliveryAreas[0] === 'string') {
      return (referenceDeliveryAreas as string[]).some((area) => insensitiveStringIncludes(area, administrativeAreaLvl2ToDeliver || '')) || (referenceDeliveryAreas as string[]).includes('ALL_AREAS');
    }

    return (referenceDeliveryAreas as UserDeliveryZone[]).some((deliveryZone) => (
      deliveryZone.administrative_areas[0].area === 'ALL_AREAS'
      || deliveryZone.administrative_areas.some((administrativeArea) => insensitiveStringIncludes(administrativeArea.area, administrativeAreaLvl2ToDeliver || ''))
    ));
  }
}
