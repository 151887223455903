import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function BasketSummaryEmpty(): React.JSX.Element {
  const { t } = useTranslation('basket');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2 }}>
      <ShoppingCartOutlinedIcon sx={{ color: 'onSurfaceVariant.main', height: 48, width: 48 }} />
      <Typography variant="titleMedium" sx={{ mt: 2, mb: 0.5 }}>
        {t('your_basket_is_empty')}
      </Typography>
      <Typography variant="bodyMedium">
        {t('order_new_products')}
      </Typography>
    </Box>
  );
}
