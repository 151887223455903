import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../../routes/AppRoutes';
import BackButton from '../../../common/components/BackButton';

export interface SubCatalogFormHeaderProps {
  isEditing?: boolean;
}

export default function SubCatalogFormHeader({ isEditing }: SubCatalogFormHeaderProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs']);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <BackButton
          to={RoutePaths.ROOT}
          compact
        />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t(isEditing ? 'catalogs:update_the_catalog' : 'catalogs:create_a_sub_catalog')}
        </Typography>
      </Box>
      <Typography variant="bodyMedium" color="secondary">
        {t('catalogs:create_a_sub_catalog_description')}
      </Typography>
    </Box>
  );
}
