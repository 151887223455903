import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Sub_Catalog_Packaging_Insert_Input } from 'kheops-graphql';
import { useSetAtom } from 'jotai';
import ConfirmDialog from '../../../common/components/ConfirmDialog';
import { SubCatalogsByCompanyIdDocument, useSubCatalogsByCompanyIdLazyQuery } from '../../../queries/__generated__/subCatalogsByCompanyId.generated';
import { useAddPackagingsToSubCatalogsMutation } from '../../../mutations/__generated__/addPackagingsToSubCatalogs.generated';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import { useUpdateSupplierPackagingsVisibilityMutation } from '../../../mutations/__generated__/updateSupplierPackagingsVisibility.mutation.generated';

interface AddPackagingsToSubCatalogsDialogProps {
  companyId: string;
  newPackagingsSkus: number[];
  open: boolean;
  close: () => void;
}

export default function AddPackagingsToSubCatalogsDialog({ open, close, newPackagingsSkus, companyId }: AddPackagingsToSubCatalogsDialogProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs', 'common']);
  const [getCompanySubCatalogs, { data: subCatalogsData, error: getSubCatalogsError, previousData: previousSubCatalogsData }] = useSubCatalogsByCompanyIdLazyQuery({ variables: { companyId } });
  const [addPackagingsToSubCatalogs, { data: addPackagingsData, error: addPackagingsError }] = useAddPackagingsToSubCatalogsMutation({ refetchQueries: [SubCatalogsByCompanyIdDocument] });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [updateSupplierPackagingsVisibilityMutation] = useUpdateSupplierPackagingsVisibilityMutation();

  const handleCloseDialog = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      getCompanySubCatalogs();
    } else {
      // when new packagings are not added to sub catalog, they are not visible for buyers of these subcatalogs
      // we resync invisibleForBuyers field
      updateSupplierPackagingsVisibilityMutation({
        variables: {
          supplierCompanyId: companyId,
        },
      });
      setCommonSnackbarProps({
        label: t('catalogs:packagings_not_added'),
        snackbarProps: {
          open: true,
        },
      });
    }

    close();
  };

  useEffect(() => {
    if (subCatalogsData?.sub_catalog.length && !previousSubCatalogsData) {
      const subCatalogsIds = subCatalogsData.sub_catalog.map(({ id }) => id);
      const subCatalogsPackagingsInput: Sub_Catalog_Packaging_Insert_Input[] = subCatalogsIds.flatMap((subCatalogId) => (
        newPackagingsSkus.map((newPackagingSku) => ({
          supplier_company_id: companyId,
          sub_catalog_id: subCatalogId,
          packaging_sku: newPackagingSku,
        }))
      ));

      addPackagingsToSubCatalogs({
        variables: {
          subCatalogsIds,
          subCatalogPackagingsData: subCatalogsPackagingsInput,
          now: new Date().toISOString(),
        },
      });
    }
  }, [subCatalogsData]);

  useEffect(() => {
    if (addPackagingsData?.update_sub_catalog) {
      setCommonSnackbarProps({
        label: t('catalogs:packagings_added_successfully'),
        snackbarProps: {
          open: true,
        },
      });

      return;
    }

    if (getSubCatalogsError || addPackagingsError) {
      setCommonSnackbarProps({
        label: t('catalogs:error_add_packagings_to_sub_catalogs'),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'error',
        },
      });
    }
  }, [addPackagingsData, addPackagingsError, getSubCatalogsError]);

  return (
    <ConfirmDialog
      open={open}
      onClose={handleCloseDialog}
      title={t('catalogs:add_packagings_to_your_sub_catalogs')}
      message={t('catalogs:add_packagings_to_sub_catalogs_description')}
      confirmLabel={t('common:add')}
      cancelLabel={t('common:not_add')}
    />
  );
}
