import { Maybe } from 'graphql/jsutils/Maybe';
import { Atom, atom } from 'jotai';
import {
  Base_Unit,
  Business_Profile_Enum,
  Comment,
  Company,
  Contract,
  Delivery_Hours,
  Document,
  NextActions,
  Order,
  Order_History,
  Order_Item,
  Order_Status_Enum,
  Packaging_History,
  Product,
  Product_Product_Labels_Product_Label,
  User,
} from 'kheops-graphql';
import { Document_Type_Enum } from 'kheops-graphql/packages/graphql/types';
import { FormatPackagingVolumePrice, ValueWithUnit, round } from 'kheops-utils';
import { DisplayPhoto, RawPhoto } from '../../common/common';
import { DocumentsByOrderQuery } from '../../queries/__generated__/documentsByOrder.generated';

export type BaseUser = Pick<User, 'first_name' | 'last_name' | 'auth_provider_avatar_url'> & { default_avatar?: Maybe<RawPhoto> };

export type CompanyDisplay = Pick<Company, 'id' | 'tradeName' | 'brand'> & { photos: DisplayPhoto[] };

export type ContractOrder = Pick<Contract, 'id' | 'supplyingCompanyId' | 'buyingCompanyId' | 'minimum_order_value' | 'supplier_commission_rate' | 'discount' | 'custom_price_list_id' | 'custom_price_list'> & {
  supplying_company: CompanyDisplay;
} & {
  buying_company: CompanyDisplay & {
    delivery_hours: Pick<Delivery_Hours, 'week_day' | 'opening_hour' | 'closing_hour'>[]
  };
};

export type PreviousOrder = Pick<Order_History, 'status' | 'id' | 'total_price_excl_tax'>
& {
  items: Pick<Order_Item, 'packagingId' | 'quantity' | 'orderId' | 'weight' | 'price'>[];
};

export type OrderDisplay = Pick<
  Order,
  'id' | 'status' | 'created_at' | 'updated_at' | 'reference_id' | 'delivery_date' | 'payment_method' | 'friendly_id' | 'cancelled_by' | 'total_price_incl_tax' | 'entered_status_at' | 'suggestor_id' | 'sdd_payment_date' | 'total_price_excl_tax' | 'has_invoice_generation' | 'total_price_to_pay_excl_tax' | 'total_price_to_pay_incl_tax'
> & {
  contract: ContractOrder;
  items: OrderablePackaging[];
  previousOrders: PreviousOrder[];
  buying_company_formatted_address: string;
  user?: Maybe<BaseUser>;
};

export type LabelDisplay = Pick<Product_Product_Labels_Product_Label, 'label'>;

export type OrderComment = Pick<Comment, 'id' | 'content' | 'created_date' | 'modified_date' | 'order_reference_id' | 'user_id' | 'reference_id' | 'deleted'>
  & {
    user: BaseUser;
  };

export type ProductDisplay = Pick<
  Product,
  'id'
  | 'name'
  | 'sku'
  | 'sub_family'
  | 'vat_rate'
  | 'ingredients'
  | 'allergens'
  | 'calories'
  | 'calories_kj'
  | 'fat'
  | 'saturated_fat'
  | 'carbohydrate'
  | 'sugars'
  | 'sodium'
  | 'protein'
> & {
  labels: LabelDisplay[];
};

export type PackagingDisplay = Pick<
  Packaging_History,
  | 'id'
  | 'company_id'
  | 'unit_quantity'
  | 'net_content'
  | 'trade_item_unit_descriptor'
  | 'gtin'
  | 'content_measurement_unit'
  | 'sku'
> & {
  base_unit?: Maybe<
    Pick<
      Base_Unit,
      | 'id'
      | 'gtin'
      | 'sku'
      | 'content_measurement_unit'
      | 'unit_quantity'
      | 'unit_type'
      | 'unit_net_content'
      | 'billing_type'
    > & {
      main_photo?: Maybe<RawPhoto>;
    }
  >;
  product?: Maybe<ProductDisplay>;
  price: number;
  quantity?: number;
};

export interface OrderablePackaging {
  packaging: PackagingDisplay;
  quantity: number;
  price: number;
  defaultPrice: number;
  previousPrice?: number;
  defaultPackagingWeight?: ValueWithUnit;
  weight?: number | null;
  defaultWeight?: number | null;
  previousWeight?: number | null;
  defaultQuantity?: number;
  previousQuantity?: number;
  hasJustBeenAdded?: boolean;
  notPresentInPreviousVersion?: boolean;
}

export interface EditItemsParam {
  id: string;
  quantity: number;
  adjustedWeight?: number;
  newItem?: OrderablePackaging;
}

export type OrderDocument = Pick<Document, 'domain' | 'path' | 'type' | 'id'>;

export type DocumentMap = {[type in Document_Type_Enum]?: OrderDocument};

export const orderStatusFlow: Order_Status_Enum[] = [
  Order_Status_Enum.OrderToBeValidatedByBuyer,
  Order_Status_Enum.OrderToBeValidatedBySupplier,
  Order_Status_Enum.OrderToBeReceivedByBuyer,
  Order_Status_Enum.OrderToBeBilledBySupplier,
  Order_Status_Enum.OrderToBePaidByBuyer,
  Order_Status_Enum.OrderBeingPaidByBuyer,
  Order_Status_Enum.OrderClosed,
];

export const currentOrderItemsAtom = atom<OrderablePackaging[]>([]);
const baseEditedOrderItemsAtom = atom<OrderablePackaging[]>([]);

export const editedOrderItemsAtom = atom<OrderablePackaging[], EditItemsParam[], void>(
  (get) => get(baseEditedOrderItemsAtom),
  (get, set, ...quantities) => {
    const currentItems = get(currentOrderItemsAtom);
    const updateEditedPackage = (
      { id, quantity, adjustedWeight, newItem }: EditItemsParam,
      previousState: OrderablePackaging[],
    ): OrderablePackaging[] => {
      if (newItem) {
        return [
          newItem,
          ...previousState,
        ];
      }

      // packaging quantity is either set from database or was just added by user
      const storedPackaging = (currentItems.find(
        ({ packaging }) => id === packaging.id,
      )
        || previousState.find(
          ({ packaging }) => id === packaging.id,
        ))!;

      // Check if quantity has already been registered
      const exist = previousState.find(({ packaging }) => id === packaging.id);

      // Check if item edit is triggered with it's same weight or quantity values
      const itemHasBeenEditedWithItsInitialValues = storedPackaging.quantity === quantity
        && (storedPackaging.weight === adjustedWeight || adjustedWeight === undefined);

      // If it doesn't, create a new item record
      if (!exist && !itemHasBeenEditedWithItsInitialValues) {
        return [
          ...previousState,
          {
            ...storedPackaging,
            price: round(
              adjustedWeight !== undefined
                ? FormatPackagingVolumePrice(storedPackaging.packaging, storedPackaging.packaging.base_unit!).value * adjustedWeight
                : quantity * storedPackaging.packaging.price,
            ),
            weight: adjustedWeight,
            quantity,
          },
        ];
      }

      const initialQuantity = storedPackaging.defaultQuantity;
      // If not, change existing item record
      // weight is reset to default
      if (quantity !== initialQuantity && adjustedWeight === undefined) {
        return previousState.map((previousValue) => (
          previousValue.packaging.id === id
            ? {
              ...previousValue,
              quantity,
              weight: storedPackaging.defaultPackagingWeight ? storedPackaging.defaultPackagingWeight.value * quantity : undefined,
              price: round(quantity * previousValue.packaging.price),
            }
            : previousValue
        ));
      }

      if (adjustedWeight && adjustedWeight !== storedPackaging.weight) {
        return previousState.map((previousValue) => (
          previousValue.packaging.id === id
            ? {
              ...previousValue,
              quantity,
              weight: adjustedWeight,
              price: round(FormatPackagingVolumePrice(previousValue.packaging, previousValue.packaging.base_unit!).value * adjustedWeight),
            }
            : previousValue
        ));
      }

      // If item has been set back to inital quantity, remove it
      return previousState.filter(
        (previousValue) => previousValue.packaging.id !== id,
      );
    };

    let newEditedItems: OrderablePackaging[] = [];

    if (quantities.length) {
      newEditedItems = quantities.reduce(
        (acc, item) => updateEditedPackage(item, acc),
        get(baseEditedOrderItemsAtom),
      );
    }

    set(baseEditedOrderItemsAtom, newEditedItems);
  },
);

export const viewOrderItemsAtom = atom<OrderablePackaging[]>((get) => {
  const items = get(currentOrderItemsAtom);
  const editedItems = get(editedOrderItemsAtom);

  const addedItems = editedItems.filter((quantity) => quantity.hasJustBeenAdded);

  const viewItems = items.map((item) => {
    const editedQuantity = editedItems.find((i) => i.packaging.id === item.packaging.id);

    return editedQuantity || item;
  });

  return [...addedItems, ...viewItems];
});

export const viewOrderItemByIdAtom = (
  id: string,
): Atom<OrderablePackaging | undefined> =>
  atom<OrderablePackaging | undefined>(
    (get) => get(viewOrderItemsAtom).find(
      (viewOrderItem) => viewOrderItem.packaging.id === id,
    ),
  );

/**
 * This atom returns true if one of the following conditions is met
 * * An item's quantity has changed
 * * An item's weight has changed
 * * A new item has been added (new packaging added)
 */
export const areOrderItemsEditedAtom = atom<boolean>(
  (get) => get(editedOrderItemsAtom).length > 0,
);

/**
 * This atom returns true only if an item's quantity has changed
 */
export const thereAreOrderItemsWithUpdatedQuantitiesAtom = atom<boolean>(
  (get) => get(editedOrderItemsAtom).some((item) => item.quantity !== item.defaultQuantity),
);

export const isPerformingActionAtom = atom(false);
export const commentsAtom = atom<OrderComment[]>([]);
export const loadingCommentsAtom = atom<boolean>(false);
export const orderAtom = atom<OrderDisplay>({} as OrderDisplay);
export const orderDeliveryDateAtom = atom<Date | null | undefined>(undefined);
export const orderSddPaymentDateAtom = atom<Date | null | undefined>(undefined);
export const orderActionsAtom = atom<NextActions | undefined>(undefined);

export const documentsAtom = atom<DocumentMap>({});
const baseDocumentDownloadUrlsAtom = atom<Map<Document_Type_Enum, string>>(new Map());
export const documentDownloadUrlsAtom = atom<Map<Document_Type_Enum, string>, { key: Document_Type_Enum, value: string }[], void>(
  (get) => get(baseDocumentDownloadUrlsAtom),
  (get, set, update) => {
    const currentMap = get(baseDocumentDownloadUrlsAtom);

    const updatedMap = new Map(currentMap);
    updatedMap.set(update.key, update.value);

    set(baseDocumentDownloadUrlsAtom, updatedMap);
  },
);

export const orderInvoiceAtom = atom<Required<DocumentsByOrderQuery>['documentAsBuyer'][number]['invoice']>(undefined);

export enum OrdersCategories {
  ALL = 'ALL',
  VALIDATION = 'VALIDATION',
  DELIVERY = 'DELIVERY',
  PAYMENT = 'PAYMENT',
  COMPLETED = 'COMPLETED',
}

export const orderCatogoryToStatusesMap = new Map<OrdersCategories, Order_Status_Enum[]>([
  [OrdersCategories.ALL, [
    Order_Status_Enum.OrderToBeValidatedByBuyer,
    Order_Status_Enum.OrderToBeValidatedBySupplier,
    Order_Status_Enum.OrderClosed,
    Order_Status_Enum.OrderCancelled,
    Order_Status_Enum.OrderArchived,
    Order_Status_Enum.OrderBeingPaidByBuyer,
    Order_Status_Enum.OrderToBeBilledBySupplier,
    Order_Status_Enum.OrderToBePaidByBuyer,
    Order_Status_Enum.OrderToBeDeliveredBySupplier,
    Order_Status_Enum.OrderToBeReceivedByBuyer,
    Order_Status_Enum.OrderToBePreparedBySupplier,
  ]],
  [OrdersCategories.VALIDATION, [
    Order_Status_Enum.OrderToBeValidatedByBuyer,
    Order_Status_Enum.OrderToBeValidatedBySupplier,
  ]],
  [OrdersCategories.COMPLETED, [
    Order_Status_Enum.OrderClosed,
    Order_Status_Enum.OrderCancelled,
    Order_Status_Enum.OrderArchived,
  ]],
  [OrdersCategories.PAYMENT, [
    Order_Status_Enum.OrderBeingPaidByBuyer,
    Order_Status_Enum.OrderToBeBilledBySupplier,
    Order_Status_Enum.OrderToBePaidByBuyer,

  ]],
  [OrdersCategories.DELIVERY, [
    Order_Status_Enum.OrderToBeDeliveredBySupplier,
    Order_Status_Enum.OrderToBeReceivedByBuyer,
    Order_Status_Enum.OrderToBePreparedBySupplier,
  ]],
]);

export const contextToActionNeededStatusMap = new Map<Business_Profile_Enum, Order_Status_Enum[]>([
  [Business_Profile_Enum.Buyer, [
    Order_Status_Enum.OrderToBeValidatedByBuyer,
    Order_Status_Enum.OrderToBeReceivedByBuyer,
    Order_Status_Enum.OrderToBePaidByBuyer,
    Order_Status_Enum.OrderBeingPaidByBuyer,
  ]],
  [Business_Profile_Enum.Supplier, [
    Order_Status_Enum.OrderToBeValidatedBySupplier,
    Order_Status_Enum.OrderToBePreparedBySupplier,
    Order_Status_Enum.OrderToBeDeliveredBySupplier,
    Order_Status_Enum.OrderToBeBilledBySupplier,
  ]],
]);

export interface PartnerOption {
  value: string;
  label: string;
  photo: string;
}
export interface OrderListUiState {
  selectedPartners: PartnerOption[];
  onlyMyOrders: boolean;
  onlyOrdersWhereActionIsNeeded: boolean;
  currentSetCategory: OrdersCategories;
}

export const orderListUiStateAtom = atom<OrderListUiState>({
  selectedPartners: [],
  onlyMyOrders: false,
  onlyOrdersWhereActionIsNeeded: false,
  currentSetCategory: OrdersCategories.ALL,
});

export const orderListPageAtom = atom<number>(1);
export const orderTerminationStatuses = [Order_Status_Enum.OrderArchived, Order_Status_Enum.OrderCancelled, Order_Status_Enum.OrderClosed];
