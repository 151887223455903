import { Box, Button, Chip, Divider, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { ProductFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import { currentFamilyAtom, currentSubFamilyAtom, discoveryViewAtom } from '../state';
import { DiscoveryNavigationItem, getProductFamilyNavigationItems, getProductSubFamilyNavigationItems } from '../discovery.utils';
import useNavigateByProductFamily from '../hooks/useNavigateByProductFamily';

export default function DiscoveryCarrouselNavigation(): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery', 'product-family']);
  const currentFamily = useAtomValue(currentFamilyAtom);
  const currentSubFamily = useAtomValue(currentSubFamilyAtom);
  const discoveryView = useAtomValue(discoveryViewAtom);
  const { navigate } = useNavigateByProductFamily();

  const items = useMemo((): DiscoveryNavigationItem[] => {
    return currentFamily !== 'ALL'
      ? getProductSubFamilyNavigationItems(t, currentFamily)
      : getProductFamilyNavigationItems(t);
  }, [currentFamily]);

  const title = useMemo(() => {
    const isProductView = discoveryView === 'PRODUCTS';

    if (currentFamily === 'ALL') {
      return t(isProductView ? 'common:all_products' : 'discovery:all_suppliers');
    }

    return t(`product-family:${ProductFamilyTypeIntl[currentFamily]}`);
  }, [currentFamily, currentSubFamily, discoveryView]);

  const handleNavigation = (family: Product_Family_Name_Enum | Product_Sub_Family_Name_Enum | 'ALL'): void => {
    if (currentFamily !== 'ALL') {
      navigate(currentFamily, family as Product_Sub_Family_Name_Enum);
    } else {
      navigate(family as Product_Family_Name_Enum, 'ALL');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        backgroundColor: 'transparent',
        pt: 0,
        borderRadius: 0,
        pb: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
          {(currentFamily !== 'ALL') && (
            <Button variant="text" onClick={() => navigate('ALL', 'ALL')}>
              <ArrowBackIcon />
            </Button>
          )}
          <Typography variant="headlineMedium">
            {title}
          </Typography>
        </Box>
      </Box>
      <Tabs
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: 1,
          },
          alignItems: 'center',
          gap: 1,
          minHeight: 0,
        }}
        variant="scrollable"
        TabScrollButtonProps={{
          slots: {
            StartScrollButtonIcon: ArrowBackIcon,
            EndScrollButtonIcon: ArrowForwardIcon,
          },
          sx: {
            '&.Mui-disabled': {
              opacity: 1,
              color: 'text.disabled',
            },
            display: {
              xs: 'none',
              md: 'inline-flex',
            },
            opacity: 1,
            backgroundColor: 'white',
            alignSelf: 'center',
            borderRadius: 10,
          },
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            borderRadius: 10,
            cursor: 'pointer',
          }}
          onClick={() => navigate(currentFamily, 'ALL')}
        >
          <Chip
            variant="filter"
            label={t('common:see_all')}
            className={currentSubFamily === 'ALL' ? 'Kheops-selected' : ''}
            sx={{ borderRadius: 10 }}
          />
        </Box>
        <Divider flexItem orientation="vertical" sx={{ my: 0.5 }} />
        {items.map((item) => {
          return (
            <Tab
              key={item.family}
              value={item}
              disableRipple
              label={(
                <Chip
                  variant="filter"
                  avatar={(
                    <Box
                      component="img"
                      src={item.img}
                      sx={{
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  label={item.label}
                  className={item.family === currentSubFamily ? 'Kheops-selected' : ''}
                />
              )}
              onClick={() => handleNavigation(item.family)}
              sx={{
                p: 0,
                minHeight: 32,
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
