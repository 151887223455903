import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { useNavigate } from 'react-router';
import { SubCatalogsByCompanyIdDocument, SubCatalogsByCompanyIdQuery } from '../../../queries/__generated__/subCatalogsByCompanyId.generated';
import { RoutePaths } from '../../../routes/AppRoutes';
import useOpenable from '../../../hooks/useOpenable';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import { useDeleteSubCatalogByIdMutation } from '../../../mutations/__generated__/deleteSubCatalogById.generated';
import { ContractsAsSupplierDocument } from '../../../queries/__generated__/contractsAsSupplier.generated';
import SubCatalogDeleteDialog from './SubCatalogDeleteDialog';
import PropertyCard from '../../../common/components/PropertyCard';
import StoresChip from '../../StoresChip';

export interface SubCatalogCardProps {
  subCatalog: SubCatalogsByCompanyIdQuery['sub_catalog'][number];
  supplierPackagingsCount: number;
  shouldDisplayOptionsMenu: boolean;
}

export default function SubCatalogCard({ subCatalog, supplierPackagingsCount, shouldDisplayOptionsMenu }: SubCatalogCardProps): React.JSX.Element {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation(['catalogs', 'common']);
  const { open, close, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [deleteSubCatalogById] = useDeleteSubCatalogByIdMutation({
    refetchQueries: [SubCatalogsByCompanyIdDocument, ContractsAsSupplierDocument],
    awaitRefetchQueries: true,
  });

  const packagingsChipLabel = useMemo(() => {
    if (subCatalog.sub_catalog_packagings.length === 0) {
      return t('catalogs:no_packaging');
    }

    if (subCatalog.sub_catalog_packagings.length === supplierPackagingsCount) {
      return t('catalogs:all_packagings');
    }

    return t('catalogs:N_packaging', { count: subCatalog.sub_catalog_packagings.length, formattedCount: subCatalog.sub_catalog_packagings.length });
  }, [subCatalog, supplierPackagingsCount]);

  const handleOptionsButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleConfirm = (confirm: boolean): void => {
    close();

    if (confirm) {
      deleteSubCatalogById({ variables: { id: subCatalog.id } });

      setCommonSnackbarProps({
        label: t('common:delete_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  };

  const chips = [
    <StoresChip
      storesList={
        subCatalog.contracts.map((contract) => ({ tradeName: contract.buying_company.tradeName, brand: contract.buying_company.brand! }))
      }
    />,
    <Chip
      variant="tag"
      label={packagingsChipLabel}
      icon={<Box component={RemoveRedEyeOutlinedIcon} sx={{ width: 16, height: 16 }} />}
      sx={{
        backgroundColor: (theme) => `${theme.palette.surfaceContainerHighest.main}!important`,
      }}
    />,
  ];

  return (
    <>
      <PropertyCard
        title={subCatalog.name}
        onClick={() => navigate(`${RoutePaths.SUB_CATALOG}?subCatalogId=${subCatalog.id}`)}
        subTitles={chips}
        actionButton={shouldDisplayOptionsMenu
          ? (
            <Button
              variant="text"
              onClick={handleOptionsButtonClick}
              sx={{ mt: -0.5 }}
            >
              <MoreVertIcon />
            </Button>
          )
          : undefined}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 4,
            gap: 2,
          },
          '& .MuiMenuItem-root': {
            py: 1,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 26,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
            color: 'black',
          },
        }}
      >
        <MenuItem onClick={() => navigate(`${RoutePaths.SUB_CATALOG}?subCatalogId=${subCatalog.id}`)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:modify')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`${RoutePaths.SUB_CATALOG}?copySubCatalogId=${subCatalog.id}`)}>
          <ListItemIcon>
            <ContentCopyOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:duplicate')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={open}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <SubCatalogDeleteDialog
        subCatalog={subCatalog}
        supplierPackagingsCount={supplierPackagingsCount}
        isOpen={isOpen}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
