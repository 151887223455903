import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { alpha, Box, Button, Input, OutlinedInput } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getStateLayerColor, StateLayer } from '../../../theme/color.utils';

export interface QuantitySelectorProps {
  quantity: number;
  onQuantityChange: (newQuantity: number) => void;
  defaultValue?:number;
  shouldDisplayTitle?: boolean;
  shouldDisplayButtons?: boolean;
  index?: number;
}

export default function QuantitySelector({
  quantity,
  onQuantityChange,
  defaultValue,
  shouldDisplayTitle,
  shouldDisplayButtons = true,
  index,
}: QuantitySelectorProps): React.JSX.Element {
  const [displayedValue, setDisplayedValue] = useState<number | ''>(quantity);
  const { t } = useTranslation(['basket']);
  const inputName = 'quantitySelector';
  const { setValue } = useForm({
    defaultValues: {
      [inputName]: defaultValue,
    },
  });

  // We should care for form dirtiness only if there is a default value
  const displayShoppingCart = useMemo(() => !quantity && defaultValue === undefined && shouldDisplayButtons, [quantity]);

  useEffect(() => {
    setDisplayedValue(quantity);
    setValue(inputName, quantity, { shouldDirty: true });
  }, [quantity]);

  const handleChange = (event: ChangeEvent): void => {
    const inputField = event.target as HTMLInputElement;
    let newQuantity = parseInt(inputField.value.replace(/[+-]/, ''), 10);

    if (Number.isNaN(newQuantity)) {
      setDisplayedValue('');
    } else {
      newQuantity = Math.min(newQuantity, 999);
      onQuantityChange(newQuantity);
      setDisplayedValue(newQuantity);
    }

    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const handleDecrementClick = (): void => {
    const newQuantity = Math.max(quantity - 1, 0);

    onQuantityChange(newQuantity);
    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const handleIncrementClick = (): void => {
    const newQuantity = Math.min(quantity + 1, 999);

    onQuantityChange(newQuantity);
    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const addToCartButton = shouldDisplayTitle
    ? (
      <Button
        startIcon={<AddShoppingCartIcon />}
        variant="contained"
        onClick={handleIncrementClick}
        className="KheopsButton-addToCart"
      >
        {t('basket:add_to_basket')}
      </Button>
    )
    : (
      <Button variant="contained" onClick={handleIncrementClick}>
        <AddShoppingCartIcon />
      </Button>
    );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!displayShoppingCart ? (
        <Box
          component="form"
          sx={{
            '& .MuiInputBase-input': {
              maxWidth: 40,
              textAlign: 'center',
              '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                display: 'none',
              },
            },
          }}
        >
          {shouldDisplayButtons
            ? (
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderRadius: 100,
                  maxWidth: 112,
                  px: 0.5,
                  height: 40,
                  backgroundColor: 'surfaceContainer.main',
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleDecrementClick}
                  sx={(theme) => ({
                    color: theme.palette.onSurfaceVariant.main,
                    backgroundColor: theme.palette.surfaceContainerHighest.main,
                    '&:hover': {
                      backgroundColor: getStateLayerColor(StateLayer.Layer8, theme.palette.surfaceContainerHighest.main, theme.palette.onSurfaceVariant.main),
                    },
                    '&:focus': {
                      backgroundColor: getStateLayerColor(StateLayer.Layer12, theme.palette.surfaceContainerHighest.main, theme.palette.onSurfaceVariant.main),
                    },
                    '&:focus:not(:focus-visible)': {
                      backgroundColor: theme.palette.surfaceContainerHighest.main,
                    },
                    '&:active': {
                      backgroundColor: getStateLayerColor(StateLayer.Layer16, theme.palette.surfaceContainerHighest.main, theme.palette.onSurfaceVariant.main),
                    },
                  })}
                >
                  {displayedValue as number > 1 ? <RemoveIcon /> : <DeleteOutlinedIcon />}
                </Button>
                <Input
                  onChange={handleChange}
                  inputProps={{
                    min: 0,
                    'data-testid': `quantity-${index}`,
                  }}
                  name={inputName}
                  disableUnderline
                  sx={(theme) => ({
                    maxWidth: 40,
                    borderRadius: 10,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, StateLayer.Layer8),
                    },
                    '&:active, &:has(.MuiInputBase-input:focus)': {
                      backgroundColor: alpha(theme.palette.primary.main, StateLayer.Layer12),
                    },
                  })}
                  type="number"
                  value={displayedValue}
                />
                <Button variant="contained" size="small" onClick={handleIncrementClick}>
                  <AddIcon />
                </Button>
              </Box>
            )
            : (
              <OutlinedInput
                onChange={handleChange}
                inputProps={{
                  min: 0,
                  'data-testid': `quantity-${index}`,
                }}
                name={inputName}
                sx={{
                  fontSize: '0.875rem',
                  height: 32,
                  '& .MuiOutlinedInput-input': {
                    px: 0,
                  },
                }}
                type="number"
                value={displayedValue}
              />
            )}
        </Box>
      ) : (
        addToCartButton
      )}
    </>
  );
}
