import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { deliveryHoursDrawerOpenAtom } from '../state';

export default function EmptyDeliveryHours(): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const setDeliveryHoursDrawerOpen = useSetAtom(deliveryHoursDrawerOpenAtom);

  return (
    <>
      <Typography variant="titleMedium" sx={{ mb: 1 }}>
        {t('settings:no_delivery_hours')}
      </Typography>
      <Typography variant="bodyMedium" color="onSurfaceVariant">
        {t('settings:delivery_hours_description')}
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="tonal"
          startIcon={<AddIcon />}
          onClick={() => setDeliveryHoursDrawerOpen(true)}
        >
          {t('common:add')}
        </Button>
      </Box>
    </>
  );
}
