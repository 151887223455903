import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../common/components/PageWrapper';
import OrderList from './OrderList';

export default function OrderListPage(): React.JSX.Element {
  const { t } = useTranslation('order');

  return (
    <PageWrapper>
      <Typography variant="headlineMedium" sx={{ mb: 2 }}>
        {t('order:track_orders')}
      </Typography>
      <OrderList />
    </PageWrapper>
  );
}
