import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import useSortedProductFamiliesMap, { ProductFamily } from '../../hooks/useSortedProductFamiliesMap';
import { ProductSearchBarProps } from './ProductSearchBar';

interface UseSearchProductsOutput {
  productFamilyMap: ProductFamily[];
  selectedProductFamilyMap: ProductFamily[];
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  setProductFamilies :Dispatch<SetStateAction<Product_Sub_Family_Name_Enum[]>>;
}

export default function useSearchProducts({ onChange, productFamiliesOptions, defaultValues }: ProductSearchBarProps): UseSearchProductsOutput {
  const productFamilyMap = useSortedProductFamiliesMap(productFamiliesOptions);
  const [productFamilies, setProductFamilies] = useState<Product_Sub_Family_Name_Enum[]>(defaultValues?.productFamilies || []);
  const selectedProductFamilyMap = useSortedProductFamiliesMap(productFamilies);
  const [query, setQuery] = useState(defaultValues?.query || '');

  useEffect((): void => {
    onChange(productFamilies, query);
  }, [productFamilies, query]);

  return {
    productFamilyMap,
    selectedProductFamilyMap,
    query,
    setQuery,
    setProductFamilies,
  };
}
