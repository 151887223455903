import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  List,
  ListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps,
  ListItemText as MuiListItemText,
  ListItemTextProps,
  styled,
  Typography,
  Box,
  Button,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { ContractPreview } from '../hooks/useContracts';
import { CompanyInfo } from './companyInfo/CompanyInfoCard';
import { isRichTextEmpty } from '../common/utils/common.utils';
import { longDateFormat } from '../common/state/state';

export interface CompanyContactInfoProps {
  company: CompanyInfo;
  contract?: ContractPreview;
}

export default function CompanyContactInfo({ company, contract }: CompanyContactInfoProps): React.JSX.Element {
  const ListItemIcon = styled(MuiListItemIcon)<ListItemIconProps>(
    ({ theme }) => ({ minWidth: theme.spacing(3) }),
  );
  const ListItemText = styled((props: ListItemTextProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiListItemText primaryTypographyProps={{ variant: 'bodyMedium' }} {...props} />
  ))({});

  const { t } = useTranslation(['common', 'contracts']);
  const [displayMoreInfo, setDisplayMoreInfo] = useState(!contract);
  const currencyFormatter = useCurrencyFormat({ maximumFractionDigits: 0 });

  useEffect(() => {
    setDisplayMoreInfo(contract?.status !== Contract_Status_Enum.Signed);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      {contract?.status === Contract_Status_Enum.Signed && (
        <List
          dense
          disablePadding
        >
          <>
            <ListItem disableGutters>
              <ListItemIcon>
                💰
              </ListItemIcon>
              <ListItemText
                primary={`${currencyFormatter.format(contract?.total_revenue || 0)} ${t(company.business_profile === Business_Profile_Enum.Buyer ? 'contracts:total_sale_excluding_tax' : 'contracts:total_purchase_excluding_tax')}`}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                ✨
              </ListItemIcon>
              <ListItemText primary={t('contracts:N_placed_orders', { count: contract.order_count! })} />
            </ListItem>
            {!!contract.order_count && (
              <ListItem disableGutters>
                <ListItemIcon>
                  🚚
                </ListItemIcon>
                <ListItemText
                  primary={
                    t('contracts:last_order_on', { date: format(contract.last_order_date!, longDateFormat) })
                  }
                />
              </ListItem>
            )}
          </>
        </List>
      )}
      {company.business_profile === Business_Profile_Enum.Supplier && !!company.description && !isRichTextEmpty(company.description) && (
        <>
          {!!contract && (
            <Button
              variant="text"
              onClick={() => setDisplayMoreInfo(!displayMoreInfo)}
              startIcon={displayMoreInfo ? <RemoveIcon /> : <AddIcon />}
            >
              {t(`common:${displayMoreInfo ? 'less_information' : 'more_information'}`)}
            </Button>
          )}
          <Typography
            variant="bodyMedium"
            sx={{ display: displayMoreInfo ? 'block' : 'none', color: 'text.primary' }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(company.description) }}
          />
        </>
      )}
    </Box>
  );
}
