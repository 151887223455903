import { Box, Button, Chip, CircularProgress } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Brand_Enum, BusinessProfile, Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { CreateGroupChannelMutation, useCreateGroupChannelMutation } from '../../mutations/__generated__/createGroupChannel.generated';
import { ContractsByCompanyIdDocument } from '../../queries/__generated__/contractsByCompanyId.generated';
import { CompanyInfo } from './CompanyInfoCard';
import ContractRequestButton from '../ContractRequestButton';
import { RoutePaths } from '../../routes/AppRoutes';
import useJoinDefaultChannel from '../../chats/hooks/useJoinDefaultChannel';
import { currentContextAtom } from '../../state';
import { ContractPreview } from '../../hooks/useContracts';
import { ContractsAsBuyerDocument } from '../../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import useOpenable from '../../hooks/useOpenable';
import CompanyContactDialog from './CompanyContactDialog';
import BuyerInfoDialog from '../buyer/dialog/BuyerInfoDialog';
import ExportProductsButton from './ExportProductsButton';

export interface ContactBoxProps {
  company: CompanyInfo;
  contract?: ContractPreview;
}

const BRANDS_ALLOWED_TO_EXPORT_PRODUCTS = [
  Brand_Enum.CarrefourMarket,
  Brand_Enum.CarrefourHyper,
  Brand_Enum.SuperU,
  Brand_Enum.HyperU,
  Brand_Enum.UExpress,
  Brand_Enum.IntermarcheHyper,
  Brand_Enum.IntermarcheSuper,
  Brand_Enum.IntermarcheExpress,
  Brand_Enum.IntermarcheContact,
];

export default function ContactBox({ company, contract }: ContactBoxProps): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'contracts']);
  const { realm, companyId, companyBrand } = useAtomValue(currentContextAtom);
  const [createGroupChannel] = useCreateGroupChannelMutation({ refetchQueries: [ContractsByCompanyIdDocument, ContractsAsBuyerDocument, ContractsAsSupplierDocument] });
  const [joinDefaultChannel] = useJoinDefaultChannel();
  const [loading, setLoading] = useState(false);

  const { isOpen: isContactDialogOpen, open: openContactDialog, close: closeContactDialog } = useOpenable();
  const { isOpen: isBuyerInfoDialogOpen, open: openBuyerInfoDialog, close: closeBuyerInfoDialog } = useOpenable();
  const isContractSigned = contract?.status === Contract_Status_Enum.Signed;

  const handleSeeDiscussionClick = useCallback(async (): Promise<void> => {
    let joined = false;
    let data: CreateGroupChannelMutation | null | undefined;

    setLoading(true);

    if (contract?.channel_url) {
      joined = await joinDefaultChannel({ channelUrl: contract.channel_url, contractId: contract.id });
    } else {
      let buyingCompanyId: string;
      let supplyingCompanyId: string;

      if (realm === Business_Profile_Enum.Buyer) {
        buyingCompanyId = companyId;
        supplyingCompanyId = company.id;
      } else {
        buyingCompanyId = company.id;
        supplyingCompanyId = companyId;
      }

      data = (await createGroupChannel({ variables: { buyingCompanyId, supplyingCompanyId, fromBusinessProfile: realm as unknown as BusinessProfile } })).data;
    }

    if (data?.createGroupChannel?.channelUrl) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${data.createGroupChannel.channelUrl}`);
    } else if (joined) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${contract!.channel_url}`);
    }

    setLoading(false);
  }, [contract]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: {
          xs: 'row',
        },
        flexWrap: 'wrap',
        gap: {
          xs: 1,
        },
      }}
    >
      {!!contract && (
        <>
          {isContractSigned && company.business_profile === Business_Profile_Enum.Buyer && (
            <>
              <Chip
                variant="assistive"
                icon={<InfoOutlinedIcon />}
                label={t('common:more_information')}
                onClick={openBuyerInfoDialog}
              />
              <BuyerInfoDialog close={closeBuyerInfoDialog} isOpen={isBuyerInfoDialogOpen} companyId={company.id} />
            </>
          )}
          {company.business_profile === Business_Profile_Enum.Supplier && (
            <>
              {!isContractSigned && (
                <Button
                  variant="outlined"
                  startIcon={<PersonOutlineIcon />}
                  onClick={() => openContactDialog()}
                >
                  {t('common:contacts')}
                </Button>
              )}
              {isContractSigned && (
                <Chip
                  variant="assistive"
                  icon={<PersonOutlineIcon />}
                  label={t('common:contacts')}
                  onClick={openContactDialog}
                />
              )}
              {contract.status === Contract_Status_Enum.Signed
                && BRANDS_ALLOWED_TO_EXPORT_PRODUCTS.includes(companyBrand as Brand_Enum)
                && (<ExportProductsButton supplierCompany={company} />)}
              <CompanyContactDialog
                company={company}
                isOpen={isContactDialogOpen}
                onClose={closeContactDialog}
                contractRequestButton={
                  isContractSigned
                    ? undefined
                    : (
                      <LoadingButton
                        variant="contained"
                        onClick={() => handleSeeDiscussionClick()}
                        loading={loading}
                        startIcon={<AddBusinessOutlinedIcon />}
                      >
                        {t('contracts:resume_negociation')}
                      </LoadingButton>
                    )
                }
              />
            </>
          )}
          {!isContractSigned && (
            <LoadingButton
              variant="contained"
              onClick={() => handleSeeDiscussionClick()}
              loading={loading}
              startIcon={<AddBusinessOutlinedIcon />}
            >
              {t('contracts:resume_negociation')}
            </LoadingButton>
          )}
        </>
      )}
      {isContractSigned && (
        <Chip
          disabled={loading}
          variant="assistive"
          icon={loading ? <CircularProgress /> : <MessageOutlinedIcon />}
          label={t('contracts:see_discussion')}
          onClick={() => handleSeeDiscussionClick()}
        />
      )}
      {!contract && realm === Business_Profile_Enum.Buyer && (
        <ContractRequestButton
          companyId={company.id}
          toolTipWording=""
          buttonWording={t('contracts:contact')}
          buttonProps={{
            startIcon: <AddBusinessOutlinedIcon sx={{ ml: 0.5 }} />,
            sx: {
              minWidth: {
                xs: '100%',
                sm: 'unset',
              },
            },
          }}
          whenDisableTooltipHover
        />
      )}
    </Box>
  );
}
