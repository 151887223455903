import { Business_Profile_Enum, Contract, Custom_Price_List, Maybe, Sub_Catalog } from 'kheops-graphql';
import { TFunction } from 'i18next';
import { CompanyDisplay, ContractOrder } from '../../orders/state/state';
import { ContractConditions, ContractOfferInfo } from '../../chats/state';
import { Context, UserDeliveryZone } from '../models/user.models';
import SearchUtils from './search.utils';
import i18n from '../../i18n';

export type ContractPricingInfo = Pick<Contract, 'discount' | 'custom_price_list_id' | 'sub_catalog_id'> &
{
  custom_price_list?: Maybe<Pick<Custom_Price_List, 'name'>>;
  sub_catalog?: Maybe<Pick<Sub_Catalog, 'name'>>;
}

export default class ContractsUtils {
  /**
   * given current profile get other company given contract
   */
  static getOtherCompany(contract: ContractOrder, realm: Business_Profile_Enum): CompanyDisplay {
    return realm === Business_Profile_Enum.Buyer ? contract.supplying_company : contract.buying_company;
  }

  static getDefaultContractConditions(contract: ContractOfferInfo, context: Context): Partial<ContractConditions> | undefined {
    if (contract.minimum_order_value) {
      return {
        minimum_order_value: contract.minimum_order_value,
        discount: contract.discount,
      };
    }

    const deliveryZones = context.realm === Business_Profile_Enum.Supplier ? context.deliveryZones : contract.supplying_company.delivery_zones;

    if (!deliveryZones?.length) {
      return undefined;
    }

    const buyingCompanyAddress = context.realm === Business_Profile_Enum.Supplier
      ? contract.buying_company.addresses[0].administrativeAreaLevel2
      : context.companyAddress.administrativeAreaLevel2;

    const areaMatch = deliveryZones.find((deliveryArea) => SearchUtils.canItDeliverAnArea([deliveryArea] as UserDeliveryZone[], buyingCompanyAddress!));
    const minimumOrderValue = areaMatch?.minimum_order_value;

    return {
      minimum_order_value: minimumOrderValue,
    };
  }

  static displayPriceMode(realm: Business_Profile_Enum, contract: ContractPricingInfo): boolean {
    return realm === Business_Profile_Enum.Supplier || !!(contract.custom_price_list_id || contract.discount);
  }

  static getPriceModeLabel(t: TFunction, realm: Business_Profile_Enum, discount?: number, customPriceListName?: string): string {
    const isSupplier = realm === Business_Profile_Enum.Supplier;
    let label = '';

    if (discount) {
      const percentFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'percent', maximumFractionDigits: 2 });
      const percent = percentFormatter.format(discount || 0);

      label = t('contracts:percent_of_discount', { percent });
    } else if (customPriceListName) {
      label = isSupplier ? customPriceListName : t('contracts:preferential_prices');
    } else if (isSupplier) {
      label = t('contracts:default_price');
    }

    return label;
  }
}
