import { useTranslation } from 'react-i18next';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { Chat_Message_Custom_Type } from 'kheops-utils';
import { useUpdateContractMutation } from '../../mutations/__generated__/updateContract.generated';
import { ContractPropositionInfoDocument } from '../../queries/__generated__/contractPropositionInfo.generated';
import { currentContextAtom, userAtom } from '../../state';
import { ContractConditions } from '../state';
import useHandleBuyerAckNotification from './useHandleBuyerAckNotification';
import { ContractsAsBuyerDocument } from '../../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import { commonSnackbarPropsAtom } from '../../common/state/state';

export type UseMakeAnOfferResponse = (channel: GroupChannel, contract: ContractConditions, contractId: string) => Promise<void>;

export default function useMakeAnOffer(): UseMakeAnOfferResponse {
  const { t } = useTranslation(['contracts']);
  const { realm } = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const [updateContract] = useUpdateContractMutation();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { handleBuyerAckNotification } = useHandleBuyerAckNotification();

  const makeAnOffer = async (channel: GroupChannel, offerInput: ContractConditions, contractId: string): Promise<void> => {
    const { data } = await updateContract({
      variables: {
        contractId,
        contractInput: {
          minimum_order_value: offerInput.minimum_order_value,
          discount: offerInput.discount,
          custom_price_list_id: offerInput.custom_price_list_id,
          proposer_user_id: userId,
          proposer_business_profile: realm,
          sub_catalog_id: offerInput.sub_catalog_id,
        },
      },
      refetchQueries: [ContractPropositionInfoDocument, realm === Business_Profile_Enum.Buyer ? ContractsAsBuyerDocument : ContractsAsSupplierDocument],
      awaitRefetchQueries: true,
    });

    if (!data?.update_contract_by_pk) {
      setCommonSnackbarProps({
        label: t('contracts:commercial_offer_changed_by_others'),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'error',
        },
      });

      return;
    }

    const messageRequestHandler = await channel.sendUserMessage({
      customType: Chat_Message_Custom_Type.OFFER,
      data: JSON.stringify({
        ...offerInput,
        contract_id: data.update_contract_by_pk.id,
      }),
      message: t('contracts:new_commercial_offer'),
    });

    messageRequestHandler.onSucceeded(() => {
      handleBuyerAckNotification(channel);
    });
  };

  return makeAnOffer;
}
