import { Box, Chip, ChipOwnProps } from '@mui/material';
import { MouseEventHandler } from 'react';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { productSubFamilyImageMap } from '../../discovery/state';

interface ProductFamilyChipProps {
  label: string;
  selected?: boolean;
  subFamily?: Product_Sub_Family_Name_Enum;
  color?: ChipOwnProps['color'];
  variant?: ChipOwnProps['variant'];
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function ProductFamilyChip({ label, selected, subFamily, color, variant, onClick }: ProductFamilyChipProps): React.JSX.Element {
  return (
    <Chip
      label={label}
      color={color}
      variant={variant || 'filter'}
      className={selected ? 'Kheops-selected' : ''}
      onClick={onClick}
      avatar={(
        <Box
          component="img"
          src={subFamily && productSubFamilyImageMap[subFamily]}
          sx={{
            objectFit: 'cover',
          }}
        />
      )}
    />
  );
}
