import { ListItemIcon, MenuItem, MenuList, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { NavigationItem } from '../common/state/navigation';
import useAuth from '../hooks/useAuth/useAuth';

export interface NavigationSectionProps {
  items: NavigationItem[];
}

export default function NavigationSection({ items }: NavigationSectionProps): React.JSX.Element {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['common', 'catalogs']);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleMenuItemClick = (item: NavigationItem): void => {
    if (item.to) {
      navigate(item.to);
    } else if (item.action === 'logout') {
      logout();
    }
  };

  return (
    <MenuList
      sx={{
        py: {
          xs: 1,
          md: 0,
        },
        '& .MuiMenuItem-root': {
          p: 2,
          borderRadius: 4,
        },
        '& .MuiListItemIcon-root': {
          minWidth: 32,
        },
      }}
    >
      {items.filter((item) => (isDownMd && !item.desktopOnly) || (!isDownMd && !item.mobileOnly)).map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
          <ListItemIcon>
            <item.icon width={24} height={24} color="secondary" />
          </ListItemIcon>
          <Typography variant="bodyMedium">
            {t(item.labelKey)}
          </Typography>
        </MenuItem>
      ))}
    </MenuList>
  );
}
