import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, Link, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Additional_Address, Maybe } from 'kheops-graphql';
import { getGMapUrlForAddress } from '../../../common/utils/common.utils';

export type Address = Omit<Additional_Address, 'id'> & { id?: Maybe<string> };

export interface AddressProps {
  address: Address;
  additionalInfoTitle: string;
  additionalInfo?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  email?: Maybe<string>;
  hidePhone?: boolean;
  enableMapLink?: boolean;
}

export default function Address({ address, additionalInfoTitle, additionalInfo, phoneNumber, email, hidePhone, enableMapLink }: AddressProps): React.JSX.Element {
  const formattedAddress = useMemo((): string => {
    let result: string;

    if (!address.id) {
      result = address.address_1.replace(/, /g, '\n');
    } else {
      result = address.address_1;

      if (address.address_2) {
        result += `\n${address.address_2}`;
      }

      result += `\n${address.postal_code} ${address.city}\n${address.country}`;
    }

    return result;
  }, [address]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      <Box>
        <Typography variant="bodyLarge" sx={{ pb: 0.5 }}>
          {address.name}
        </Typography>
        {enableMapLink
          ? (
            <Link
              href={getGMapUrlForAddress(formattedAddress)}
              target="_blank"
              sx={{
                color: 'text.primary',
                textDecorationColor: (theme) => theme.palette.text.primary,
              }}
            >
              <Typography variant="bodyMedium" sx={{ whiteSpace: 'pre-line', display: 'inline' }}>
                {formattedAddress}
              </Typography>
              <OpenInNewOutlinedIcon sx={{ width: 18, height: 18, verticalAlign: 'middle', ml: 0.5 }} />
            </Link>
          ) : (
            <Typography variant="bodyMedium" sx={{ whiteSpace: 'pre-line' }}>
              {formattedAddress}
            </Typography>
          )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          backgroundColor: 'surfaceContainerLow.main',
          p: 1,
          gap: 0.5,
        }}
      >
        <Typography variant="bodySmall">
          {additionalInfoTitle}
        </Typography>
        <Typography variant="bodyMedium">
          {additionalInfo || '-'}
        </Typography>
      </Box>
      {!hidePhone && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="bodyMedium">
            ☎️ {phoneNumber || '-'}
          </Typography>
          <Typography variant="bodyMedium">
            ✉️ {email || '-'}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
