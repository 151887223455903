import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { Barcode } from 'scandit-web-datacapture-barcode';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import FullTextSearch from '../common/components/FullTextSearch';
import { companyPageDisplayModeAtom, DisplayMode } from '../search/state/state';
import BarcodeCaptureButton from '../search/product/BarcodeCaptureButton';
import { currentContextAtom } from '../state';
import CompanyProductSubFamiliesFilter from './CompanyProductSubFamiliesFilter';
import useUpdateBasketByBarCode from '../search/product/useUpdateBasketByBarCode';
import { refreshCompanyProductListAtom } from './state';

export interface CompanyProductFiltersProps {
  contextCompanyId?: string;
}

export default function CompanyProductFilters({ contextCompanyId }: CompanyProductFiltersProps): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['products', 'discovery']);
  const { realm, companyId: userCompanyId } = useAtomValue(currentContextAtom);
  const { query } = useSearchBox();
  const { refresh } = useInstantSearch();
  const [displayMode, setDisplayMode] = useAtom(companyPageDisplayModeAtom);
  const [fullTextSearchValue, setFullTextSearchValue] = useState(query || '');
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const { upsertPackagingByBarCode } = useUpdateBasketByBarCode();
  const [refreshCompanyProducts, setRefreshCompanyProducts] = useAtom(refreshCompanyProductListAtom);

  const displayBarCodeScanner = realm === Business_Profile_Enum.Supplier && contextCompanyId !== userCompanyId;

  useEffect(() => {
    if (isSmall && displayMode === DisplayMode.LIST) {
      setDisplayMode(DisplayMode.GRID);
    }
  }, [isSmall]);

  const handleDisplayModeClick = useCallback((): void => {
    setDisplayMode(displayMode === DisplayMode.GRID ? DisplayMode.LIST : DisplayMode.GRID);
  }, [displayMode]);

  const handleBarcodeCapture = (barcodes: Barcode[]): void => {
    if (barcodes[0].data) {
      setFullTextSearchValue(barcodes[0].data);
      upsertPackagingByBarCode(barcodes[0].data);
    }
  };

  useEffect(() => {
    if (refreshCompanyProducts) {
      refresh();

      setRefreshCompanyProducts(false);
    }
  }, [refreshCompanyProducts]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'space-between',
          justifyContent: 'center',
          width: '100%',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          gap: 1,
        }}
      >
        <CompanyProductSubFamiliesFilter />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FullTextSearch
            value={fullTextSearchValue}
            onChange={(value) => setFullTextSearchValue(value)}
            inputProps={{
              sx: {
                height: 48,
                backgroundColor: 'surfaceContainerHighest.main',
                width: {
                  xs: '100%',
                  md: 420,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },
              placeholder: t('discovery:search_a_product'),
              inputProps: {
                sx: {
                  py: 1.5,
                },
              },
            }}
          />
          <Button
            variant="outlined"
            sx={{
              display: {
                [theme.breakpoints.down('lg')]: {
                  display: 'none',
                },
              },
            }}
            onClick={handleDisplayModeClick}
          >
            {displayMode === DisplayMode.GRID ? <ListIcon /> : <GridViewIcon />}
          </Button>
        </Box>
      </Box>
      {displayBarCodeScanner && <BarcodeCaptureButton onCapture={handleBarcodeCapture} />}
    </>
  );
}
