import { Box, Card, Typography, buttonBaseClasses, useMediaQuery, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import InventoryIcon from '@mui/icons-material/Inventory2Outlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { format } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { ActionType, Billing_Type_Enum, Business_Profile_Enum, Order_Status_Enum, Payment_Method_Enum } from 'kheops-graphql';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { convertSqlTimeToDate, getFormattedHour } from 'kheops-utils';
import { editedOrderItemsAtom, orderActionsAtom, orderAtom, orderInvoiceAtom, viewOrderItemsAtom } from '../state/state';
import { OrderButtonType } from '../../common/models/order.models';
import { currentContextAtom, userAtom } from '../../state';
import DateUtils, { isDeliveryDay } from '../../common/utils/date.utils';
import OrderStatusStepper from './stepper/OrderStatusStepper';
import useOrderFormButtons from './hooks/useOrderFormButtons';
import OrderInvoiceDetails from './OrderInvoiceDetails';
import BankTransferInformation from './BankTransferInformation';
import { longDateFormat } from '../../common/state/state';

interface OrderWording {
  title: string;
  description: string;
  helper?: string;
}

type PaymentAutoInvoice = `${Payment_Method_Enum}_AUTO_INVOICE`;
type OrderContext = Partial<Record<Payment_Method_Enum | 'SUGGESTION' | 'NEW_ORDER' | PaymentAutoInvoice | 'DEFAULT', OrderWording>>;

type OrderWordingMap = {
  [context in Business_Profile_Enum]: {
    [state in Order_Status_Enum]: OrderContext;
  }
};

const ctaByActions = new Map<ActionType, OrderButtonType>([
  [ActionType.Approve, OrderButtonType.APPROVE],
  [ActionType.ApproveWithPreparation, OrderButtonType.APPROVE],
  [ActionType.ApproveWithoutPreparation, OrderButtonType.APPROVE],
  [ActionType.Modify, OrderButtonType.MODIFY],
]);

const ORDER_STATE_WORDING_MAP: OrderWordingMap = {
  BUYER: {
    order_to_be_validated_by_supplier: {
      DEFAULT: {
        title: 'order_to_be_validated_by_supplier_title_for_buyer',
        description: 'order_to_be_validated_by_supplier_description_for_buyer',
      },
      // NEW_ORDER duplicates DEFAULT to avoid weird fallback in orderStateWordings useMemo which would make the code less stable and maintainable
      NEW_ORDER: {
        title: 'order_to_be_validated_by_supplier_title_for_buyer',
        description: 'order_to_be_validated_by_supplier_description_for_buyer',
      },
    },
    order_to_be_validated_by_buyer: {
      DEFAULT: {
        title: 'order_to_be_validated_by_buyer_title_for_buyer',
        description: 'order_to_be_validated_by_buyer_description_for_buyer',
      },
      SUGGESTION: {
        title: 'order_suggestion_to_be_validated_by_buyer_title_for_buyer',
        description: 'order_suggestion_to_be_validated_by_buyer_description_for_buyer',
      },
    },
    order_to_be_delivered_by_supplier: {
      DEFAULT: {
        title: 'order_to_be_delivered_by_supplier_title_for_buyer',
        description: 'order_to_be_delivered_by_supplier_description_for_buyer',
      },
    },
    order_to_be_prepared_by_supplier: {
      DEFAULT: {
        title: 'order_to_be_delivered_by_supplier_title_for_buyer',
        description: 'order_to_be_delivered_by_supplier_description_for_buyer',
      },
    },
    order_to_be_received_by_buyer: {
      DEFAULT: {
        title: 'order_to_be_received_by_buyer_title_for_buyer',
        description: 'order_to_be_received_by_buyer_description_for_buyer',
      },
    },
    order_to_be_billed_by_supplier: {
      NO_PAYMENT: {
        title: 'order_to_be_billed_by_supplier_title_for_buyer',
        description: 'order_to_be_billed_by_supplier_description_for_buyer',
      },
    },
    order_to_be_paid_by_buyer: {
      NO_PAYMENT: {
        title: 'order_to_be_paid_by_buyer_title_for_buyer',
        description: 'order_to_be_paid_by_buyer_description_for_buyer',
      },
      NO_PAYMENT_AUTO_INVOICE: {
        title: 'order_to_be_paid_by_buyer_title_for_buyer',
        description: 'order_to_be_paid_by_buyer_description_for_buyer',
      },
      BANK_TRANSFER: {
        title: 'order_to_be_paid_by_buyer_title_for_buyer_bank_transfer',
        description: 'order_to_be_paid_by_buyer_description_for_buyer_bank_transfer',
      },
    },
    order_being_paid_by_buyer: {
      BANK_TRANSFER: {
        title: 'order_being_paid_by_buyer_title_for_buyer',
        description: 'order_being_paid_by_buyer_description_for_buyer',
      },
      SDD_AUTO: {
        title: 'order_being_paid_by_buyer_title_for_buyer_sdd_auto',
        description: 'order_being_paid_by_buyer_description_for_buyer_sdd_auto',
      },
      SDD_TRIGGERED: {
        title: 'order_being_paid_by_buyer_title_for_buyer_sdd_triggered',
        description: 'order_being_paid_by_buyer_description_for_buyer_sdd_triggered',
      },
    },
    order_closed: {
      BANK_TRANSFER: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_buyer_bank_transfer',
      },
      NO_PAYMENT: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_buyer',
      },
      SDD_AUTO: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_buyer_sdd',
      },
      SDD_TRIGGERED: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_buyer_sdd',
      },
    },
    order_cancelled: {
      DEFAULT: {
        title: 'order_cancelled_title',
        description: 'order_cancelled_description_for_buyer_by_',
      },
    },
    order_archived: {
      DEFAULT: {
        title: 'order_archived_title',
        description: 'order_archived_description_buyer',
      },
    },
  },
  SUPPLIER: {
    order_to_be_validated_by_supplier: {
      DEFAULT: {
        title: 'order_to_be_validated_by_supplier_title_for_supplier',
        description: 'order_to_be_validated_by_supplier_description_for_supplier',
        helper: 'order_to_be_validated_by_supplier_weight_helper',
      },
      NEW_ORDER: {
        title: 'order_to_be_validated_by_supplier_title_for_supplier',
        description: 'new_order_to_be_validated_by_supplier_description_for_supplier',
        helper: 'order_to_be_validated_by_supplier_weight_helper',
      },
    },
    order_to_be_validated_by_buyer: {
      DEFAULT: {
        title: 'order_to_be_validated_by_buyer_title_for_supplier',
        description: 'order_to_be_validated_by_buyer_description_for_supplier',
      },
      SUGGESTION: {
        title: 'order_to_be_validated_by_buyer_title_for_supplier',
        description: 'order_suggestion_to_be_validated_by_buyer_description_for_supplier',
      },
    },
    order_to_be_delivered_by_supplier: {
      DEFAULT: {
        title: 'order_to_be_delivered_by_supplier_title_for_supplier',
        description: 'order_to_be_delivered_by_supplier_description_for_supplier',
        helper: 'order_to_be_delivered_by_supplier_weight_helper',
      },
    },
    order_to_be_prepared_by_supplier: {
      DEFAULT: {
        title: 'order_to_be_delivered_by_supplier_title_for_supplier',
        description: 'order_to_be_prepared_by_supplier_description_for_supplier',
      },
    },
    order_to_be_received_by_buyer: {
      DEFAULT: {
        title: 'order_to_be_received_by_buyer_title_for_supplier',
        description: 'order_to_be_received_by_buyer_description_for_supplier',
      },
    },
    order_to_be_billed_by_supplier: {
      NO_PAYMENT: {
        title: 'order_to_be_billed_by_supplier_title_for_supplier',
        description: 'order_to_be_billed_by_supplier_description_for_supplier',
      },
    },
    order_to_be_paid_by_buyer: {
      NO_PAYMENT: {
        title: 'order_to_be_paid_by_buyer_title_for_supplier',
        description: 'order_to_be_paid_by_buyer_description_for_supplier',
      },
      NO_PAYMENT_AUTO_INVOICE: {
        title: 'order_to_be_paid_by_buyer_title_for_supplier',
        description: 'order_to_be_paid_by_buyer_description_auto_invoice_for_supplier',
      },
      BANK_TRANSFER: {
        title: 'order_to_be_paid_by_buyer_title_for_supplier',
        description: 'order_to_be_paid_by_buyer_description_auto_invoice_for_supplier',
      },
      SDD_TRIGGERED: {
        title: 'order_to_be_paid_by_buyer_title_for_supplier',
        description: 'order_to_be_paid_by_buyer_description_for_supplier',
      },
      SDD_TRIGGERED_AUTO_INVOICE: {
        title: 'order_to_be_paid_by_buyer_title_for_supplier',
        description: 'order_to_be_paid_by_buyer_description_auto_invoice_for_supplier',
      },
    },
    order_being_paid_by_buyer: {
      BANK_TRANSFER: {
        title: 'order_being_paid_by_buyer_title_for_supplier',
        description: 'order_being_paid_by_buyer_description_for_supplier',
      },
      SDD_AUTO: {
        title: 'order_being_paid_by_buyer_title_for_supplier_sdd_auto',
        description: 'order_being_paid_by_buyer_description_for_supplier_sdd_auto',
      },
      SDD_TRIGGERED: {
        title: 'order_being_paid_by_buyer_title_for_supplier_sdd_triggered',
        description: 'order_being_paid_by_buyer_description_for_supplier_sdd_triggered',
      },
    },
    order_closed: {
      BANK_TRANSFER: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_supplier_payment_handled',
      },
      NO_PAYMENT: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_supplier',
      },
      SDD_AUTO: {
        title: 'order_closed_title',
        description: 'order_closed_description_for_supplier_payment_handled',
      },
    },
    order_cancelled: {
      DEFAULT: {
        title: 'order_cancelled_title',
        description: 'order_cancelled_description_for_supplier_by_',
      },
    },
    order_archived: {
      DEFAULT: {
        title: 'order_archived_title',
        description: 'order_archived_description_supplier',
      },
    },
  },
};

const iconByOrderTerminationStatus = new Map<Order_Status_Enum, ReactElement>([
  [Order_Status_Enum.OrderClosed, <CheckIcon sx={{ backgroundColor: 'primary.main', color: 'white', borderRadius: '50%', p: 1 }} />],
  [Order_Status_Enum.OrderArchived, <InventoryIcon sx={{ backgroundColor: 'secondary.main', color: 'white', borderRadius: '50%', p: 1 }} />],
  [Order_Status_Enum.OrderCancelled, <CloseIcon sx={{ backgroundColor: 'error.main', color: 'white', borderRadius: '50%', p: 1 }} />],
]);

export default function OrderStatus(): React.JSX.Element {
  const { t } = useTranslation();
  const orderNextActions = useAtomValue(orderActionsAtom);
  const order = useAtomValue(orderAtom);
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { realm } = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const orderInvoice = useAtomValue(orderInvoiceAtom);
  const items = useAtomValue(viewOrderItemsAtom);
  const [availableButtons, setAvailableButtons] = useState<OrderButtonType[]>([]);
  const editItems = useSetAtom(editedOrderItemsAtom);
  const orderFormButtons = useOrderFormButtons({ availableButtons, userId });

  // Display rules described here:
  // https://www.notion.so/kheops/66a82611a7634ac7a397a14dcfbf1453?v=2ca17cdbe0b2471f9da7143f9b6d5aa8
  useEffect(() => {
    if (orderNextActions?.actions) {
      setAvailableButtons(orderNextActions.actions.map((action) => ctaByActions.get(action)!));

      if (!orderNextActions.actions.length) {
        setAvailableButtons([]);
      }
    }
  }, [orderNextActions]);

  useEffect(() => {
    editItems();
  }, [order]);

  const orderStateWordings = useMemo(() => {
    const wordingsByContext = ORDER_STATE_WORDING_MAP[realm][order.status! as Order_Status_Enum];
    const autoInvoice = `${order.payment_method!}_AUTO_INVOICE` as PaymentAutoInvoice;
    let wordings: OrderWording;

    switch (order.status) {
      case Order_Status_Enum.OrderToBeValidatedByBuyer:
        wordings = !order.previousOrders.length && order.suggestor_id ? wordingsByContext.SUGGESTION! : wordingsByContext.DEFAULT!;

        break;
      case Order_Status_Enum.OrderToBeValidatedBySupplier:
        wordings = !order.previousOrders.length ? wordingsByContext.NEW_ORDER! : wordingsByContext.DEFAULT!;

        break;
      case Order_Status_Enum.OrderToBePaidByBuyer:
      case Order_Status_Enum.OrderToBeBilledBySupplier:
      case Order_Status_Enum.OrderBeingPaidByBuyer:
      case Order_Status_Enum.OrderClosed:
        wordings = order.has_invoice_generation && order.payment_method === Payment_Method_Enum.NoPayment
          ? wordingsByContext[autoInvoice] || wordingsByContext[order.payment_method!]!
          : wordingsByContext[order.payment_method!]!;

        wordings = (wordings || wordingsByContext[Payment_Method_Enum.NoPayment])!;

        break;
      default:
        wordings = wordingsByContext.DEFAULT!;
    }

    if (order.status === Order_Status_Enum.OrderCancelled) {
      return {
        ...wordings,
        description: `${wordings!.description}${order.cancelled_by}`,
      };
    }
    return wordings;
  }, [order, orderInvoice]);

  const isAItemWeightDependent = useMemo(() => (
    !!items.find((packagingQt) => (packagingQt.packaging.base_unit?.billing_type === Billing_Type_Enum.WeightDependent))
  ), [items]);

  const orderInPaymentStatus = useMemo(() => (
    order.status === Order_Status_Enum.OrderToBePaidByBuyer || order.status === Order_Status_Enum.OrderBeingPaidByBuyer
  ), [order]);

  const shouldDisplayBankTransferInformation = useMemo(() => (
    orderInPaymentStatus && order.payment_method === Payment_Method_Enum.BankTransfer && realm === Business_Profile_Enum.Buyer
  ), [order, orderInPaymentStatus]);

  const orderTerminationStatusIcon = useMemo(() => {
    return iconByOrderTerminationStatus.get(order.status);
  }, [order]);

  const orderDescriptionBody = useMemo(() => (
    <Trans
      i18nKey={`order:${orderStateWordings?.description}`}
      components={{ primary: <Typography variant="bodyMedium" color="primary" sx={{ display: 'inline' }} /> }}
      shouldUnescape
      values={{
        orderInvoiceReference: orderInvoice?.reference || '',
        formatted_payment_day: order.status === Order_Status_Enum.OrderClosed ? format(DateUtils.AddWorkingDaysToDate(new Date(order.entered_status_at!), 2), longDateFormat) : undefined,
        sdd_payment_date: order.sdd_payment_date ? format(order.sdd_payment_date, longDateFormat) : '',
      }}
    />
  ), [order, orderInvoice]);

  const displayDeliveryHours = useMemo((): boolean => {
    return (order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier || order.status === Order_Status_Enum.OrderToBePreparedBySupplier)
      && isDeliveryDay(new Date(order.delivery_date!).getDay(), order.contract.buying_company.delivery_hours);
  }, [order]);

  const shouldDisplayInvoiceDetails = useMemo(() => (
    orderInPaymentStatus || (order.status === Order_Status_Enum.OrderClosed && !!orderInvoice?.comment)
  ), [orderInPaymentStatus, orderInvoice]);

  return (
    <Card
      sx={{
        backgroundColor: 'surfaceContainerLow.main',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {!orderTerminationStatusIcon && (
        <OrderStatusStepper
          sx={{
            backgroundColor: 'surfaceContainerHigh.main',
            py: 3,
            borderRadius: 6,
          }}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            gap: {
              xs: 3,
              sm: 1,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: {
                xs: 'center',
                sm: 'normal',
              },
            }}
          >
            {orderTerminationStatusIcon}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="headlineSmall">
                {t(
                  `order:${orderStateWordings?.title}`,
                  {
                    formatted_delivery_date: order.delivery_date ? format(order.delivery_date, longDateFormat) : '',
                    interpolation: { escapeValue: false },
                    sdd_payment_date: order.sdd_payment_date ? format(order.sdd_payment_date, longDateFormat) : '',
                  },
                )}
                {displayDeliveryHours && (
                  <>
                    &nbsp;
                    {t(
                      'order:between_hours',
                      {
                        openingHour: getFormattedHour(convertSqlTimeToDate(order.contract.buying_company.delivery_hours[0].opening_hour)),
                        closingHour: getFormattedHour(convertSqlTimeToDate(order.contract.buying_company.delivery_hours[0].closing_hour)),
                      },
                    )}
                  </>
                )}
              </Typography>
              {!isDownSm && (
                <Typography variant="bodySmall">
                  {orderDescriptionBody}
                </Typography>
              )}
            </Box>
          </Box>
          {isDownSm && (
            <Typography variant="bodySmall" sx={{ mt: -2 }}>
              {orderDescriptionBody}
            </Typography>
          )}

          {orderFormButtons.length === 1 && !orderInPaymentStatus && (
            <Box
              sx={{
                display: 'flex',
                height: 40,
                [`& .${buttonBaseClasses.root}`]: {
                  lineHeight: '18px',
                  px: 2,
                },
              }}
            >
              {orderFormButtons}
            </Box>
          )}
        </Box>

        {
          isAItemWeightDependent && !!orderStateWordings?.helper && (
            <Typography variant="bodyMedium">{t(`order:${orderStateWordings.helper}`)}</Typography>
          )
        }
      </Box>
      {shouldDisplayInvoiceDetails && (<OrderInvoiceDetails availableButtons={availableButtons} />)}
      {shouldDisplayBankTransferInformation && (<BankTransferInformation />)}
      {orderFormButtons.length > 1 && !shouldDisplayInvoiceDetails && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
            flexDirection: {
              xs: 'column-reverse',
              md: 'row',
            },
            [`& .${buttonBaseClasses.root}`]: {
              lineHeight: '18px',
            },
          }}
        >
          {orderFormButtons}
        </Box>
      )}
    </Card>
  );
}
