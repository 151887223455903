import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Button, Badge } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import LoadingDots from '../common/components/LoadingDots';
import BasketSummaryDrawer from './BasketSummaryDrawer';
import { basketDataAtom, isBasketDrawerOpenAtom, isBasketLoadingAtom, isRemoteBasketEmptyAtom } from './state/state';

export default function BasketButton(): React.JSX.Element {
  const isRemoteBasketEmpty = useAtomValue(isRemoteBasketEmptyAtom);
  const basketData = useAtomValue(basketDataAtom);
  const isBasketLoading = useAtomValue(isBasketLoadingAtom);
  const setIsBasketDrawerOpen = useSetAtom(isBasketDrawerOpenAtom);

  const handleButtonClick = (): void => {
    setIsBasketDrawerOpen(true);
  };

  return (
    <>
      <LoadingDots loading={isBasketLoading}>
        <Badge
          color="primary"
          variant="dot"
          invisible={isRemoteBasketEmpty}
          sx={{
            '& .MuiBadge-badge': {
              top: 6,
              right: 6,
            },
          }}
        >
          <Button variant="text" onClick={handleButtonClick}>
            <ShoppingCartOutlinedIcon />
          </Button>
        </Badge>
      </LoadingDots>
      {basketData && <BasketSummaryDrawer />}
    </>
  );
}
