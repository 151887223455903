import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import PrivacySettingSelect from './PrivacySettingSelect';
import { userAtom } from '../../state';

export default function EmailContactInformationInput(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const user = useAtomValue(userAtom);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        mt: 2,
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
        <Typography variant="bodyMedium">{t('common:address')}</Typography>
        <Typography
          variant="bodyMedium"
          sx={{
            border: '1px solid',
            borderColor: 'outlineVariant.main',
            opacity: '38%',
            wordBreak: 'break-word',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 2,
            px: 1,
            minHeight: 40,
          }}
        >
          {user.email}
        </Typography>
      </Box>
      <PrivacySettingSelect fieldName="email" />
    </Box>
  );
}
