import { Box, Card, Typography } from '@mui/material';
import React from 'react';

export interface CompanyInfoSectionProps {
  title: string;
  icon: string;
  children: React.ReactNode;
}

export default function CompanyInfoSection({ title, icon, children }: CompanyInfoSectionProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        width: {
          xs: '100%',
          lg: 600,
        },
      }}
    >
      <Typography variant="titleMedium">
        {icon} {title}
      </Typography>
      <Card sx={{ backgroundColor: 'surfaceContainerLow.main' }}>
        {children}
      </Card>
    </Box>
  );
}
