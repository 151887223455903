import { Box, Button, Card, Dialog, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import DeliveryZoneDisplay from './DeliveryZoneDisplay';
import DeliveryIcon from '../../assets/icons/delivery.svg?react';
import { RoutePaths } from '../../routes/AppRoutes';
import useOpenable from '../../hooks/useOpenable';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';
import DeliveryZoneSettings from '../../user/settingsDialog/DeliveryZoneSettings';
import { currentContextAtom } from '../../state';
import DeleteDeliveryZoneDialog from './DeleteDeliveryZoneDialog';

export enum CompanyPageSearchParams {
  OpenDialog = 'openDialog'
}

export default function CompanyDeliveryZones(): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const context = useAtomValue(currentContextAtom);
  const [searchParams] = useSearchParams();
  const { open, close, isOpen } = useOpenable();
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);

  const deleteZone = useCallback((index: number) => {
    setIndexToDelete(index);
  }, []);

  useEffect(() => {
    if (searchParams.has(CompanyPageSearchParams.OpenDialog)) {
      open();
    }
  }, [searchParams]);

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.NAVIGATION_MAIN}
          shouldCheckHistory={false}
          compact
        />
        <DeliveryIcon height={32} width={32} />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t('settings:delivery_zones')}
          {context.deliveryZones?.length && ` (${context.deliveryZones.length})`}
        </Typography>
      </TopBar>
      <Box
        sx={{
          height: {
            lg: 'calc(100% - 48px)',
          },
          overflow: {
            lg: 'auto',
          },
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          p: 2,
          mt: {
            xs: 8,
            lg: 0,
          },
          mb: {
            xs: 10,
            lg: 0,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            mb: {
              xs: -3,
              lg: 0,
            },
          }}
        >
          <Typography variant="titleLarge" sx={{ display: { xs: 'none', lg: 'block' } }}>
            {t('settings:delivery_zones')} ({context.deliveryZones?.length})
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={open}
            sx={{
              position: {
                xs: 'fixed',
                lg: 'initial',
              },
              width: {
                xs: 'calc(100% - 32px)',
                lg: 'initial',
              },
              bottom: 32,
              left: 16,
              zIndex: 10,
            }}
          >
            {t('settings:add_new_delivery_zone')}
          </Button>
        </Box>
        {context.deliveryZones?.length
          ? (
            <>
              {context.deliveryZones.map((deliveryZone, index, deliveryZones) => (
                <DeliveryZoneDisplay
                  key={deliveryZone.id}
                  deliveryZone={deliveryZone}
                  index={index}
                  zoneListLength={deliveryZones.length}
                  deleteZone={() => deleteZone(index)}
                />
              ))}
            </>
          ) : (
            <Card
              sx={{
                backgroundColor: 'surfaceContainer.main',
                borderRadius: 4,
                textAlign: 'center',
                p: 2,
                my: 2,
              }}
            >
              <Typography variant="titleMedium">
                {t('settings:no_delivery_zones_defined')}
              </Typography>
              <Typography variant="bodyMedium" color="onSurfaceVariant" sx={{ my: 1 }}>
                {t('settings:no_delivery_zones_defined_description')}
              </Typography>
            </Card>
          )}
        <Dialog
          open={isOpen}
          onClose={(): void => { /* noop */ }}
        >
          <DeliveryZoneSettings next={close} standAlone addNewZone />
        </Dialog>
        {indexToDelete !== null && (
          <DeleteDeliveryZoneDialog
            deliveryZone={context.deliveryZones![indexToDelete]}
            index={indexToDelete}
            numberOfZones={context.deliveryZones!.length}
            onClose={() => setIndexToDelete(null)}
          />
        )}
      </Box>
    </>
  );
}
