import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import GuitaristButterflyImage from '../../assets/images/guitarist_butterfly.png';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import { currentContextAtom } from '../../state';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import { RoutePaths } from '../../routes/AppRoutes';
import CustomPriceListInfoBox from './CustomPriceListInfoBox';
import { useCustomPriceListsByCompanyIdQuery } from '../../queries/__generated__/customPriceListsByCompanyId.generated';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';

export default function CustomPriceListsList(): React.JSX.Element {
  const { t } = useTranslation(['common', 'price', 'products']);
  const { companyId } = useAtomValue(currentContextAtom);
  const { data: customPriceListsData, loading, called } = useCustomPriceListsByCompanyIdQuery({
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const emptyPlaceholder = useMemo((): React.JSX.Element | undefined => {
    if (loading || !called) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    if (!customPriceListsData?.packaging_aggregate?.aggregate?.count) {
      return (
        <EmptyPagePlaceholder
          title={t('products:no_product')}
          description={t('products:no_product_description')}
          imageSrc={TrumpeterButterflyImage}
          sx={{ pt: 0 }}
          linkDestination={RoutePaths.PRODUCT_ADD_INFO}
          buttonLabel={t('products:add_a_product')}
          buttonProps={{
            startIcon: <AddIcon />,
          }}
        />
      );
    }

    if (!customPriceListsData?.custom_price_list.length) {
      return (
        <EmptyPagePlaceholder
          title={t('price:no_custom_price_list')}
          imageSrc={GuitaristButterflyImage}
          sx={{ pt: 0 }}
          linkDestination={RoutePaths.CUSTOM_PRICE_LIST}
          buttonLabel={t('price:create_custom_price_list')}
          buttonProps={{
            startIcon: <AddIcon />,
          }}
        />
      );
    }

    return undefined;
  }, [loading, called, customPriceListsData]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        overflow: 'auto',
        height: {
          lg: 'calc(100% - 48px)',
        },
        backgroundColor: 'transparent',
      }}
    >
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          flexDirection: 'column',
          alignItems: 'start',
          p: 1.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            my: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BackButton
              to={RoutePaths.NAVIGATION_PRICES}
              shouldCheckHistory={false}
              Icon={ArrowBackIcon}
              compact
            />
            <DiscountIcon height={28} width={28} />
            <Typography variant="titleLarge">
              {t('common:prices')}
            </Typography>
          </Box>
        </Box>
      </TopBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            sm: 'center',
          },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
            alignItems: 'center',
            gap: 1,
            flex: 1,
          }}
        >
          <DiscountIcon height={28} width={28} />
          <Typography variant="titleLarge">
            {t('common:prices')}
          </Typography>
        </Box>
        {!!customPriceListsData?.custom_price_list.length && (
          <Button
            sx={{
              display: {
                xs: 'none',
                lg: 'flex',
              },
            }}
            variant="contained"
            startIcon={<AddIcon />}
            component={Link}
            to={RoutePaths.CUSTOM_PRICE_LIST}
          >
            {t('price:create_custom_price_list')}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          mt: {
            xs: 7,
            lg: 0,
          },
        }}
      >
        <Typography variant="titleMedium">
          {t('price:custom_price_lists')}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          {t('price:prices_custom_price_list_description')}
        </Typography>
      </Box>
      {
        emptyPlaceholder || (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {customPriceListsData!.custom_price_list.map((customPriceList) => (
              <CustomPriceListInfoBox customPriceList={customPriceList} key={customPriceList.id} />
            ))}
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              component={Link}
              to={RoutePaths.CUSTOM_PRICE_LIST}
              sx={{
                display: {
                  xs: 'flex',
                  lg: 'none',
                },
                bottom: 0,
                position: 'fixed',
                width: 'calc(100vw - 32px)',
                maxWidth: 320,
                mx: 'auto',
                left: 0,
                right: 0,
                mb: 2,
              }}
            >
              {t('price:create_custom_price_list')}
            </Button>
          </Box>
        )
      }
    </Card>
  );
}
