import { Box, Button, Card, Grid2, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import LastOrderBgXs from '../assets/images/last_order_bg_xs.png';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';
import { CompanyInfo } from './companyInfo/CompanyInfoCard';
import useOpenable from '../hooks/useOpenable';
import ContractConditionsDialog from '../common/components/ContractConditionsDialog';
import { useUpdateContractMutation } from '../mutations/__generated__/updateContract.generated';
import PriceModeChip from '../common/components/PriceModeChip';
import ContractsUtils from '../common/utils/contracts.utils';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { ContractConditions } from '../chats/state';
import { currentContextAtom } from '../state';
import { ContractPreview } from '../hooks/useContracts';
import { ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../queries/__generated__/contractsAsSupplier.generated';
import PackagingPricesDetails from './prices/PackagingPricesDetails';
import CatalogChip from '../common/components/CatalogChip';
import { isWaitingForSubCatalogsSyncAtom, refreshCompanyProductListAtom } from './state';

export interface ContractInfoCardProps {
  contract: ContractPreview;
  company: CompanyInfo;
}

export default function ContractInfoCard({ contract, company }: ContractInfoCardProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs', 'contracts', 'common']);
  const { realm } = useAtomValue(currentContextAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { open, isOpen, close } = useOpenable();
  const { open: openPackagingDetails, isOpen: isPackagingDetailsOpen, close: closePackagingDetails } = useOpenable();
  const [updateContract, { data }] = useUpdateContractMutation({
    refetchQueries: [ContractsAsBuyerDocument, ContractsAsSupplierDocument],
    awaitRefetchQueries: true,
  });
  const setRefresh = useSetAtom(refreshCompanyProductListAtom);
  const isWaitingForSubCatalogsSync = useAtomValue(isWaitingForSubCatalogsSyncAtom);
  const [loading, setLoading] = useState(false);
  const [subCatalogChanged, setSubCatalogChanged] = useState(false);

  const handleContractUpdate = useCallback((response?: Partial<ContractConditions>) => {
    close();

    if (response) {
      setLoading(true);

      setSubCatalogChanged(response.sub_catalog_id !== contract.sub_catalog_id);

      updateContract({
        variables: {
          contractId: contract.id,
          contractInput: {
            discount: response.discount,
            minimum_order_value: response.minimum_order_value || 0,
            custom_price_list_id: response.custom_price_list_id,
            sub_catalog_id: response.sub_catalog_id,
          },
        },
      });
    }
  }, [contract]);

  useEffect((): (() => void) | void => {
    const displaySuccessSnackbar = (): void => {
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });

      setLoading(false);
    };

    if (data?.update_contract_by_pk?.id) {
      if (subCatalogChanged) {
        const timeout = setTimeout(
          () => {
            displaySuccessSnackbar();
            setRefresh(true);
          },
          4000, // We need to wait for the indexation of packagings records. 4s corresponds to a high waiting time for a company with over 200 products.
        );

        return () => clearTimeout(timeout);
      }

      displaySuccessSnackbar();
    }
  }, [data]);

  const shouldDisplayPricesDetailsButton = realm === Business_Profile_Enum.Buyer && (!!contract.custom_price_list_id || !!contract.discount);

  return (
    <Card
      sx={{
        backgroundImage: `url(${LastOrderBgXs})`,
        backgroundSize: { xs: 340, md: 432, lg: 500, xl: 420 },
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'surfaceContainerLow.main',
        p: 3,
        height: 'calc(100% - 48px)',
      }}
    >
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 6, md: 12 }}>
          <Typography variant="displayLarge" sx={{ width: 110 }}>
            {t('contracts:your_agreement')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 6, md: 12 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 'fit-content',
              margin: 'auto',
              gap: 1,
              maxWidth: '100%',
              '& > *, & .MuiChip-root': {
                width: '100%',
              },
            }}
          >
            {realm === Business_Profile_Enum.Supplier && (
              <CatalogChip contract={contract} color="surfaceContainerHigh" />
            )}
            {ContractsUtils.displayPriceMode(realm!, contract) && (
              <PriceModeChip contract={contract} color="surfaceContainerHigh" />
            )}
            <MinimumOrderValueChip
              minimumOrderValue={contract.minimum_order_value!}
              disableTooltip
              color="surfaceContainerHigh"
              sx={{
                display: 'contents',
              }}
            />
          </Box>
        </Grid2>
        {realm === Business_Profile_Enum.Supplier && (
          <Grid2 size={{ xs: 12 }} sx={{ textAlign: 'center' }}>
            <LoadingButton
              variant="tonal"
              startIcon={<EditIcon />}
              onClick={open}
              loading={loading || isWaitingForSubCatalogsSync}
              sx={{
                width: '100%',
                maxWidth: {
                  md: 193,
                },
              }}
            >
              {t('common:update')}
            </LoadingButton>
            <ContractConditionsDialog
              open={isOpen}
              onClose={handleContractUpdate}
              title={t('contracts:update_your_agreement')}
              description={t('contracts:update_your_agreement_description', { company: company.tradeName })}
              contractId={contract.id}
              conditions={{
                discount: contract.discount,
                minimum_order_value: contract.minimum_order_value,
                custom_price_list_id: contract.custom_price_list_id,
                sub_catalog_id: contract.sub_catalog_id,
              }}
              commissionRate={contract.supplier_commission_rate as number}
              submitLabel={t('common:validate')}
            />
          </Grid2>
        )}
        {shouldDisplayPricesDetailsButton && (
          <Grid2 size={{ xs: 12 }}>
            <Button
              variant="outlined"
              onClick={openPackagingDetails}
              fullWidth
            >
              {t('common:see_details')}
            </Button>
            <PackagingPricesDetails
              open={isPackagingDetailsOpen}
              onClose={closePackagingDetails}
              customPriceListId={contract.custom_price_list_id}
              subCatalogId={contract.sub_catalog_id}
              companyId={company.id}
              globalDiscount={contract.discount}
            />
          </Grid2>
        )}
      </Grid2>
    </Card>
  );
}
