import { Base_Unit_Insert_Input, Packaging_Insert_Input, Product_Insert_Input } from 'kheops-graphql';
import { ProductFragment, ProductFragmentBaseUnit, ProductFragmentPackaging } from '../products';
import { DisplayPhoto } from '../../common/common';
import PhotosUtils from '../../common/utils/photos.utils';

export default class ProductUtils {
  static convertPackagingFragmentIntoInsertInput(packagings: ProductFragmentPackaging[], companyId: string): Packaging_Insert_Input[] {
    return packagings.map((packaging) => {
      return {
        company_id: companyId,
        sku: packaging.sku,
        trade_item_unit_descriptor: packaging.trade_item_unit_descriptor,
        unit_quantity: packaging.unit_quantity,
        net_content: packaging.net_content,
        content_measurement_unit: packaging.content_measurement_unit,
        price: packaging.price,
      };
    });
  }

  static convertBaseUnitFragmentIntoInsertInput(baseUnits: ProductFragmentBaseUnit[], companyId: string): Base_Unit_Insert_Input[] {
    return baseUnits.map((baseUnit) => {
      const unwrappedPhotos = baseUnit.photos.map(({ photo }: DisplayPhoto) => photo);
      const { main_photo, photos } = PhotosUtils.getFormattedBaseUnitPhotosInput(baseUnit.main_photo, unwrappedPhotos);
      const baseUnitInsertInput: Base_Unit_Insert_Input = {
        company_id: companyId,
        sku: baseUnit.sku,
        availability: baseUnit.availability,
        content_measurement_unit: baseUnit.content_measurement_unit,
        gtin: baseUnit.gtin,
        gtin_type: baseUnit.gtin_type,
        unit_net_content: baseUnit.unit_net_content,
        unit_quantity: baseUnit.unit_quantity,
        unit_type: baseUnit.unit_type,
        main_photo,
        photos,
        suggested_retail_price: baseUnit.suggested_retail_price,
        packagings: {
          data: ProductUtils.convertPackagingFragmentIntoInsertInput(baseUnit.packagings, companyId),
        },
      };

      return baseUnitInsertInput;
    });
  }

  static convertProductFragmentIntoInsertInput(product: ProductFragment): Product_Insert_Input {
    return {
      sku: product.sku,
      company_id: product.company_id,
      name: product.name,
      sub_family: product.sub_family,
      vat_rate: product.vat_rate,
      labels: {
        data: product.labels.map((productLabel) => ({ label: productLabel.label })),
      },
      base_units: {
        data: ProductUtils.convertBaseUnitFragmentIntoInsertInput(product.base_units, product.company_id),
      },
      ingredients: product.ingredients,
      allergens: product.allergens,
      calories: product.calories,
      calories_kj: product.calories_kj,
      fat: product.fat,
      saturated_fat: product.saturated_fat,
      carbohydrate: product.carbohydrate,
      sugars: product.sugars,
      sodium: product.sodium,
      protein: product.protein,
    };
  }
}
