import { Box, Button, Card, Chip, Dialog, dialogClasses, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import OrderHeader from './OrderHeader';
import OrderDeliveryDateForm from './OrderDeliveryDateForm';
import useOpenable from '../../hooks/useOpenable';
import OrderCreationInfo from './OrderCreationInfo';
import ReorderButton from './ReorderButton';
import { orderAtom } from '../state/state';
import OrderSuggestionChip from './OrderSuggestionChip';
import OrderAdditionalInformation from './OrderAdditionalInformation';
import OrderExportButton from './OrderExportButton';

export default function OrderInformation(): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const { open, close, isOpen } = useOpenable();
  const order = useAtomValue(orderAtom);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        [theme.breakpoints.up('lg')]: {
          maxHeight: 'calc(100vh - 136px)',
          overflowY: 'auto',
          position: 'sticky',
          top: '96px',
          px: 0,
          pt: 0,
        },
      }}
    >
      <OrderHeader compact={isSmall} />
      <Box
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
          px: {
            xs: 0,
            lg: 2,
          },
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <ReorderButton type="chip" orderReferenceId={order.reference_id} />
          <OrderExportButton
            orderReferenceId={order.reference_id}
            orderFriendlyId={order.friendly_id}
            orderId={order.id}
            variant="chip"
          />
        </Box>
        <OrderCreationInfo />
        <OrderDeliveryDateForm />
        <OrderAdditionalInformation />
      </Box>
      {isSmall && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <ReorderButton type="chip" orderReferenceId={order.reference_id} />
          <OrderExportButton
            orderReferenceId={order.reference_id}
            orderFriendlyId={order.friendly_id}
            orderId={order.id}
            variant="chip"
          />
          <Chip
            variant="assistive"
            onClick={open}
            icon={<InfoOutlinedIcon />}
            label={t('common:more_information')}
          />
          <Dialog
            open={isOpen}
            onClose={close}
            PaperProps={{
              sx: {
                p: 2,
                m: 1,
                gap: 2,
                width: '100%',
                minHeight: 620,
                boxSizing: 'border-box',
              },
            }}
            sx={{
              [`& .${dialogClasses.container}`]: {
                alignItems: 'flex-end',
              },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="titleMedium">
                {t('order:order_details')}
              </Typography>
              <Button variant="text" onClick={close}>
                <CloseIcon />
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {order.suggestor_id && <OrderSuggestionChip />}
              <OrderCreationInfo />
              <OrderDeliveryDateForm />
              <OrderAdditionalInformation compact />
            </Box>
          </Dialog>
        </Box>
      )}
    </Card>
  );
}
