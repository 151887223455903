import { Box, StepIconProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface StepIconColors {
  color: string;
  backgroundColor: string;
  borderColor: string;
}

interface CustomStepIconProps extends StepIconProps {
  canEditStep: boolean;
}
export default function CustomStepIcon({ active, completed, icon, canEditStep }: CustomStepIconProps): React.JSX.Element {
  const getIconColors = (): StepIconColors => {
    if (completed) {
      return {
        backgroundColor: 'primary.main',
        color: 'onPrimary.main',
        borderColor: 'primary.main',
      };
    }

    if (active) {
      if (canEditStep) {
        return {
          backgroundColor: 'primary.main',
          color: 'onPrimary.main',
          borderColor: 'primary.main',
        };
      }

      return {
        backgroundColor: 'transparent',
        color: 'primary.main',
        borderColor: 'primary.main',
      };
    }

    return {
      backgroundColor: 'transparent',
      color: 'divider',
      borderColor: 'divider',
    };
  };
  const { borderColor, color, backgroundColor } = getIconColors();

  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: 1, // we need a z index of 1 so that the connector doesnt overlap on the step icon
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid',
        borderColor,
        borderRadius: '50%',
        width: 42,
        height: 42,
        backgroundColor,
        '& svg': {
          width: 28,
          height: 28,
          color,
        },
      }}
    >
      {
        completed ? <CheckIcon /> : icon
      }
    </Box>
  );
}
