import { Button, Chip, SxProps } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useAddOrderToBasket } from '../../hooks/useAddOrderToBasket';

interface ReorderButtonProps {
  orderReferenceId: string;
  type: 'button' | 'chip',
  sx?: SxProps;
}

export default function ReorderButton({ orderReferenceId, type, sx }: ReorderButtonProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);
  const { addOrderToBasket, addedToBasket } = useAddOrderToBasket({ orderReferenceId });

  const label = useMemo((): string => {
    return t(addedToBasket ? 'common:added' : 'basket:order_again');
  }, [addedToBasket]);

  const icon = useMemo((): React.JSX.Element => {
    return addedToBasket ? <CheckCircleOutlineOutlinedIcon /> : <AddShoppingCartIcon />;
  }, [addedToBasket]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        type === 'button'
          ? (
            <Button
              variant="contained"
              startIcon={icon}
              onClick={addOrderToBasket}
              sx={sx}
            >
              {label}
            </Button>
          )
          : (
            <Chip
              variant="assistive"
              icon={icon}
              label={label}
              onClick={addOrderToBasket}
            />
          )
      }
    </>
  );
}
