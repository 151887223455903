import { Box, Chip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import FileUtils from '../../common/utils/file.utils';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import { contractsAtom } from '../../state';

interface CompanyTagsProps {
  attribute: string;
}

interface DisplayCompany {
  name: string;
  contractId: string;
  photo: string;
  minimumOrderValue: number;
}

export default function CompanyTags({ attribute }: CompanyTagsProps): React.JSX.Element {
  const { refine } = useRefinementList({ attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute);
  const contracts = useAtomValue(contractsAtom);

  const [companiesMap, setCompaniesMap] = useState<Map<string, DisplayCompany>>(new Map());

  useEffect(() => {
    const map = new Map<string, DisplayCompany>();

    contracts.forEach((contract) => {
      map.set(
        contract.company.tradeName,
        {
          name: contract.company.tradeName,
          contractId: contract.reference_id!,
          minimumOrderValue: contract.minimum_order_value || 0,
          photo: FileUtils.BuildCompanyImageUrl({ photo: contract.company.photos?.[0]?.photo, type: 'SUPPLIER', size: { height: 128, width: 128 } }),
        },
      );
    });

    setCompaniesMap(map);
  }, []);

  return (
    <>
      {currentRefinements.map((refinement) => {
        const company = companiesMap.get(refinement as string);

        if (!company) {
          return undefined;
        }

        return (
          <Chip
            variant="filter"
            key={refinement}
            avatar={(
              <Box component="img" src={company.photo} sx={{ borderRadius: '50%', height: '32px', '&&': { marginRight: 0 } }} />
            )}
            label={company.name}
            onDelete={() => refine(company.name)}
          />
        );
      })}
    </>
  );
}
