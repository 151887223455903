import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../../routes/AppRoutes';
import BackButton from '../../../common/components/BackButton';

export interface CustomPriceListFormHeaderProps {
  edit?: boolean;
}

export default function CustomPriceListFormHeader({ edit }: CustomPriceListFormHeaderProps): React.JSX.Element {
  const { t } = useTranslation(['price']);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <BackButton to={RoutePaths.COMPANY_CUSTOM_PRICE_LISTS} compact />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t(edit ? 'price:edit_your_custom_price_list' : 'price:create_custom_price_list')}
        </Typography>
      </Box>
      <Typography variant="bodyMedium" color="secondary">
        {t('price:prices_custom_price_list_description')}
      </Typography>
    </Box>
  );
}
