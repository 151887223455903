import React from 'react';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { Box, Chip, Typography } from '@mui/material';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import { useTranslation } from 'react-i18next';
import { CompanyPackaging } from './state';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import i18n from '../i18n';
import { ellipsisStyle } from '../common/utils/style.utils';

interface CompanyPackagnigCardProps {
  packaging: CompanyPackaging;
}

export default function CompanyPackagingCard({ packaging }: CompanyPackagnigCardProps): React.JSX.Element {
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const { t } = useTranslation(['products']);

  return (
    <>
      <SizedImage
        src={FileUtils.BuildPackagingImageUrl({
          photo: packaging.base_unit.main_photo,
          type: packaging.product.sub_family,
          size: {
            height: 80,
            width: 80,
            fit: 'contain',
          },
        })}
        alt={t('products:csu')}
        width={80}
        height={80}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', overflow: 'hidden' }}>
        <Typography
          variant="bodyLarge"
          sx={{
            ...ellipsisStyle,
            width: '100%',
          }}
        >
          {packaging.product.name}
        </Typography>
        <Typography variant="bodyMedium" color="onSurfaceVariant">
          {FormatBaseUnitQuantity(packaging.base_unit, 'fr', numberFormat)}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            pt: 1,
            flexWrap: 'wrap',
          }}
        >
          {packaging.base_unit.gtin && (
            <Chip
              variant="tag"
              label={packaging.base_unit.gtin}
              icon={<DocumentScannerOutlinedIcon sx={{ transform: 'rotate(90deg)' }} />}
              color="surfaceContainerHigh"
            />
          )}
          <Chip
            variant="tag"
            label={FormatLogisticalPackaging(packaging, 'fr', packaging.base_unit)}
            color="surfaceContainerHigh"
          />
        </Box>
      </Box>
    </>
  );
}
