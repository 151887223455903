import { Document_Type_Enum } from 'kheops-graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useSetAtom } from 'jotai';
import FileUtils from '../common/utils/file.utils';
import { commonSnackbarPropsAtom } from '../common/state/state';

interface UseDownloadFileParams {
  documentType: Document_Type_Enum;
  downloadUrl: string;
  orderId: string;
  documentReference?: string;
}

export function useDocumentDownloadFile({ documentType, downloadUrl, orderId, documentReference }: UseDownloadFileParams): () => void {
  const { t } = useTranslation(['order']);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  return useCallback(() => {
    let documentTitle = '';

    // eslint-disable-next-line default-case
    switch (documentType) {
      case Document_Type_Enum.DeliveryNote:
        documentTitle = `${t('order:delivery_note_document_title')}_${orderId}`;
        break;
      case Document_Type_Enum.Invoice:
      case Document_Type_Enum.OrderSummary:
        documentTitle = `${t('order:invoice_order_document_title')}_${documentReference}`.toUpperCase();
        break;
      case Document_Type_Enum.PurchaseOrder:
        documentTitle = `${t('order:purchase_order_document_title')}_${orderId}`;
        break;
    }

    FileUtils.DownloadFile(downloadUrl, documentTitle)
      .catch((error) => {
        setCommonSnackbarProps({
          label: t('order:document_download_failed'),
          snackbarProps: {
            open: true,
          },
          alertProps: {
            severity: 'error',
          },
        });
        Sentry.captureException(error);
      });
  }, [documentType, downloadUrl, orderId]);
}
