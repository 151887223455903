import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ShortShelfLifeDurationChipProps {
  duration: number;
}

export default function ShortShelfLifeDurationChip({ duration }: ShortShelfLifeDurationChipProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Chip
      variant="tag"
      label={`⏱️ ${t('products:short_shelf_life_days', { count: duration })}`}
      color="tertiaryContainer"
    />
  );
}
