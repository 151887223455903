import { Chip, ChipOwnProps } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CatalogIcon from '../../assets/icons/catalog.svg?react';
import { ContractPricingInfo } from '../utils/contracts.utils';

export interface CatalogChipProps {
  contract: ContractPricingInfo;
  color?: ChipOwnProps['color'];
}

export default function CatalogChip({ contract, color }: CatalogChipProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs']);

  const label = useMemo((): string => {
    return contract.sub_catalog?.name || t('catalogs:default_catalog');
  }, [contract]);

  return (
    <Chip
      label={label}
      variant="tag"
      icon={<CatalogIcon />}
      color={color}
    />
  );
}
