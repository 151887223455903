export enum RouteVariables {
  COMPANY_ID = ':companyId',
  PRODUCT_SKU = ':productSku',
  ORDER_REFERENCE_ID = ':orderReferenceId',
  PRODUCT_ID = ':productId',
  USER_ID = ':userId',
}

// Define all route paths
export const RoutePaths = {
  ROOT: '/',
  NAVIGATION: '/navigation',
  NAVIGATION_MAIN: '/navigation/main',
  NAVIGATION_CONTEXTS: '/navigation/contexts',
  NAVIGATION_PRICES: '/navigation/prices',
  CONTRACT_LIST: '/contracts',
  CONTRACT_COMPANY: `/contracts/company/${RouteVariables.COMPANY_ID}`,
  BASKET: '/basket',
  DISCOVERY: '/discovery',
  DISCOVERY_BUYERS: '/discovery/buyers',
  DISCOVERY_BUYER: `/discovery/buyers/${RouteVariables.COMPANY_ID}`,
  DISCOVERY_SUPPLIERS: '/discovery/suppliers',
  DISCOVERY_SUPPLIER: `/discovery/suppliers/${RouteVariables.COMPANY_ID}`,
  ORDERS_CONFIRMATION: '/orders-confirmation',
  SEARCH_PRODUCT: '/search-product',
  PACKAGING: `/packaging/${RouteVariables.PRODUCT_SKU}/${RouteVariables.COMPANY_ID}/`,
  PACKAGING_VERSION: `/packaging/${RouteVariables.PRODUCT_ID}`,
  PRODUCT: `/products/${RouteVariables.PRODUCT_SKU}/${RouteVariables.COMPANY_ID}/`,
  PRODUCT_ADD: '/products/add',
  PRODUCT_ADD_INFO: '/products/add/info',
  PRODUCT_ADD_PACKAGING: '/products/add/packaging',
  ORDER_TRACKING: `/orders/${RouteVariables.ORDER_REFERENCE_ID}`,
  ORDER_LIST: '/orders',
  CHAT_LIST: '/chats',
  USER: '/user',
  MY_COMPANY: '/my_company',
  COMPANY: '/company',
  COMPANY_PRODUCTS: '/company/products',
  COMPANY_DELIVERY_ZONES: '/company/delivery_zones',
  COMPANY_CUSTOM_PRICE_LISTS: '/company/prices/custom_price_lists',
  COMPANY_PRODUCTS_PRICES: '/company/prices/products',
  COMPANY_PRICES: '/company/prices',
  COMPANY_GLOBAL_DISCOUNTS: '/company/prices/global_discounts',
  COMPANY_PROFILE: '/company/profile',
  COMPANY_BUYER_DELIVERY_INFO: '/company/delivery',
  COMPANY_BUYER_BILLING_INFO: '/company/billing',
  COMPANY_CATALOGS: '/company/catalogs',
  CUSTOM_PRICE_LIST: '/custom_price_list',
  SUB_CATALOG: '/sub_catalog',
  LOGIN: '/login',
  OFFLINE: '/offline',
  PRELOGIN: '/prelogin',
  MAGICLINK: '/login/magic',
};
// Define associated type
export type RoutePathType = typeof RoutePaths[keyof typeof RoutePaths];
