import { Box, Card, Chip, Dialog, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import { useTranslation } from 'react-i18next';
import { UserDeliveryZone } from '../../common/models/user.models';
import MinimumOrderValueChip from '../../common/components/MinimumOrderValueChip';
import useOpenable from '../../hooks/useOpenable';
import DeliveryZoneSettings from '../../user/settingsDialog/DeliveryZoneSettings';

interface DeliveryZoneDisplayProps {
  deliveryZone: UserDeliveryZone;
  index: number;
  zoneListLength: number;
  deleteZone?: () => void;
  readOnly?: boolean
}

export default function DeliveryZoneDisplay({ deliveryZone, index, zoneListLength, deleteZone, readOnly }: DeliveryZoneDisplayProps): React.JSX.Element {
  const { t } = useTranslation(['settings']);
  const { open, close, isOpen } = useOpenable();
  const allAreasWording = zoneListLength > 1 ? 'settings:rest_of_france' : 'settings:all_france';

  return (
    <Card
      sx={{
        backgroundColor: 'surfaceContainerLow.main',
        borderRadius: 4,
        p: 2,
        maxWidth: 600,
      }}
    >
      <Typography variant="bodyLarge">
        {t('settings:zone', { index: index + 1 })}
      </Typography>
      <Box
        sx={{
          py: 1.5,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <MinimumOrderValueChip color="surfaceContainerHigh" minimumOrderValue={deliveryZone.minimum_order_value} />
        {deliveryZone.administrative_areas.map(({ area }) => (
          <Chip
            variant="tag"
            color="surfaceContainerHigh"
            key={area}
            label={area === 'ALL_AREAS' ? t(allAreasWording) : area}
            icon={<PlaceIcon />}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        {!!deleteZone && (
          <Chip
            variant="assistive"
            onClick={() => deleteZone()}
            disabled={zoneListLength === 1}
            icon={<DeleteIcon />}
            label={t('common:delete')}
          />
        )}
        {!readOnly && (
          <>
            <Chip
              variant="assistive"
              onClick={open}
              icon={<EditIcon />}
              label={t('common:modify')}
            />
            <Dialog
              open={isOpen}
              onClose={(): void => { /* noop */ }}
            >
              <DeliveryZoneSettings next={close} standAlone indexZoneToModify={index} />
            </Dialog>
          </>
        )}
      </Box>
    </Card>
  );
}
