import { Tab, TabProps, styled } from '@mui/material';
import { getStateLayerColor, StateLayer } from '../../theme/color.utils';

// there seems to be an issue with types as component is not recognized as a proprety
// hence why it was added manually
// https://github.com/mui/material-ui/issues/16846
const KheopsTab = styled(Tab)<TabProps & { component?: React.ElementType, to?: string}>(({ theme }) => ({
  color: theme.palette.onSurface.main,
  height: 56,
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.secondaryContainer.main,
    color: theme.palette.onSecondaryContainer.main,
    fontWeight: 400,
  },
  '&:hover': {
    backgroundColor: getStateLayerColor(StateLayer.Layer8, theme.palette.surfaceContainerLowest.main, theme.palette.onSurfaceVariant.main),
  },
  '& .MuiTab-iconWrapper': {
    flexShrink: 0,
    mr: 0.5,
  },
  borderRadius: theme.spacing(2),
}));

export default KheopsTab;
