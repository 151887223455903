import { Box, FormHelperText, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { Billing_Type_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { GetConvertedUnit } from 'kheops-utils';
import ValidatorsUtils from '../../../common/utils/validators.utils';
import { isPriceInputDisabled } from '../utils';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import { draftProductAtom } from '../state';

export interface BaseUnitSuggestedRetailPriceProps {
  baseUnitIndex: number;
}

export default function BaseUnitSuggestedRetailPrice({ baseUnitIndex }: BaseUnitSuggestedRetailPriceProps): React.JSX.Element {
  const { t } = useTranslation(['price', 'products', 'validation', 'packaging']);
  const { control, watch } = useFormContext<ProductPackagingsFormData>();
  const draftProduct = useAtomValue(draftProductAtom);
  const [baseUnit, packagings] = watch([
    `productPackagings.${baseUnitIndex}.csu`,
    `productPackagings.${baseUnitIndex}.packagings`,
  ]);

  const disabled = isPriceInputDisabled(baseUnit, packagings[0]);

  const isBulk = useMemo((): boolean => {
    return baseUnit.csu_type === 'BULK';
  }, [baseUnit.csu_type]);

  const volumeUnits = useMemo(() => {
    if (disabled) {
      return;
    }

    return GetConvertedUnit((isBulk ? packagings[0].content_measurement_unit : baseUnit.content_measurement_unit)!);
  }, [isBulk, disabled, packagings[0].content_measurement_unit, baseUnit.content_measurement_unit]);

  const priceMeasurementUnit = useMemo((): string | undefined => {
    if ((baseUnit.billing_type === Billing_Type_Enum.WeightDependent || isBulk) && volumeUnits) {
      return volumeUnits.volumeUnit === Measurement_Unit_Enum.L ? Measurement_Unit_Enum.L : volumeUnits.volumeUnit.toLowerCase();
    }
  }, [baseUnit.billing_type, isBulk, volumeUnits]);

  const { base_unit_price: priceExclTax } = packagings.reduce((acc, currentPriceData) => {
    if (acc.base_unit_price < currentPriceData.base_unit_price) {
      return currentPriceData;
    }

    return acc;
  }, packagings[0]);

  const tax = priceExclTax * draftProduct!.vat_rate;
  const minSuggestedRetailPrice = priceExclTax + tax;

  const priceFieldTitle = useMemo(() => {
    if (priceMeasurementUnit) {
      if (priceMeasurementUnit.toUpperCase() === Measurement_Unit_Enum.Unit) {
        return t('packaging:per_unit');
      }

      return t('packaging:per_measurement_unit', { measurementUnit: priceMeasurementUnit });
    }

    return t('products:base_unit_one');
  }, [priceMeasurementUnit]);

  return (
    <Box>
      <Typography variant="titleMedium" sx={{ mb: 2 }}>{t('price:suggest_retail_price_tax_included')}</Typography>
      <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
        {priceFieldTitle}
      </Typography>
      <Controller
        name={`productPackagings.${baseUnitIndex}.csu.suggested_retail_price`}
        control={control}
        rules={{
          min: {
            value: minSuggestedRetailPrice,
            message: t('validation:srp_inferior_to_price'),
          },
          validate: {
            pattern: (currentValue) =>
              !currentValue || ValidatorsUtils.validatePrice(currentValue as number) || t('validation:this_value_should_contain_only_2_decimals') as string,
          },
        }}
        render={({ field, fieldState }) => (
          <>
            <OutlinedInput
              disabled={disabled}
              value={field.value || ''}
              onChange={field.onChange}
              name={field.name}
              startAdornment={(
                <InputAdornment position="start">

                  {priceMeasurementUnit
                    ? `€/${t(`packaging:${priceMeasurementUnit}`, { count: 1 })}`
                    : <EuroIcon sx={{ fontSize: '1rem' }} />}
                </InputAdornment>
              )}
              error={!!fieldState.error}
              inputProps={{
                step: '.01',
                min: '0.01',
              }}
              fullWidth
              type="number"
              sx={{ height: 48 }}
              placeholder={t('price:price_placeholder')}
            />
            {fieldState.error && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
          </>
        )}
      />
    </Box>
  );
}
