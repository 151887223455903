import { Box, Chip, CircularProgress, Divider, MenuItem, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAtomValue, useSetAtom } from 'jotai';
import { BusinessProfile, Business_Profile_Enum, Document_Type_Enum, Order_Status_Enum, TaskAction } from 'kheops-graphql';
import DocumentDeleteDialog from './DocumentDeleteDialog';
import { OrderDocument } from './Documents';
import { useUploadDocument } from '../../../hooks/useUploadDocument';
import { currentContextAtom } from '../../../state';
import { documentDownloadUrlsAtom, orderAtom, orderStatusFlow } from '../../state/state';
import { useGetDownloadUrlLazyQuery } from '../../../queries/__generated__/getDownloadUrl.generated';
import useOrderActions from '../../../hooks/useOrderActions';
import { useDocumentDownloadFile } from '../../../hooks/useDownloadFile';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import { useDeleteDocumentByIdFromOrderMutation } from '../../../mutations/__generated__/deleteDocumentByIdOrder.generated';
import { OrderByReferenceIdDocument } from '../../../queries/__generated__/orderByReferenceId.generated';
import KheopsMenu from '../../../common/components/KheopsMenu';
import useOpenable from '../../../hooks/useOpenable';
import UploadInvoiceDrawer from './UploadInvoiceDrawer';

interface DocumentButtonProps {
  documentType: Document_Type_Enum;
  document?: OrderDocument;
}

export const SUPPORTED_DOCUMENT_TYPES = [
  'image/jp2',
  'image/jpe',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
];

const SNACKBAR_ADD_DOCUMENT_MESSAGE_KEYS: {[key in Document_Type_Enum]?: string} = {
  [Document_Type_Enum.Invoice]: 'your_invoice_has_been_successfully_added',
  [Document_Type_Enum.PurchaseOrder]: 'your_purchase_order_has_been_successfully_added',
  [Document_Type_Enum.DeliveryNote]: 'your_delivery_note_has_been_successfully_added',
};

const SNACKBAR_DELETE_DOCUMENT_MESSAGE_KEYS: {[key in Document_Type_Enum]?: string} = {
  [Document_Type_Enum.Invoice]: 'your_invoice_has_been_successfully_deleted',
  [Document_Type_Enum.PurchaseOrder]: 'your_purchase_order_has_been_successfully_deleted',
  [Document_Type_Enum.DeliveryNote]: 'your_delivery_note_has_been_successfully_deleted',
};

const SNACKBAR_EDIT_DOCUMENT_MESSAGE_KEYS: {[key in Document_Type_Enum]?: string} = {
  [Document_Type_Enum.Invoice]: 'your_invoice_has_been_successfully_edited',
  [Document_Type_Enum.PurchaseOrder]: 'your_purchase_order_has_been_successfully_edited',
  [Document_Type_Enum.DeliveryNote]: 'your_delivery_note_has_been_successfully_edited',
};

export default function DocumentButton({ documentType, document }: DocumentButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'order']);
  const {
    id: orderId,
    reference_id: orderReferenceId,
    status: orderStatus,
    friendly_id: orderFriendlyId,
    has_invoice_generation,
  } = useAtomValue(orderAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { updateOrder } = useOrderActions({ orderReferenceId, orderId });
  const [getDownloadUrl, { data, loading: isGetDownloadUrlLoading }] = useGetDownloadUrlLazyQuery();
  const [deleteDocument, { loading: isDeleteDocumentLoading }] = useDeleteDocumentByIdFromOrderMutation({ refetchQueries: [OrderByReferenceIdDocument], awaitRefetchQueries: true });
  const setDocumentDownloadUrls = useSetAtom(documentDownloadUrlsAtom);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const { open: openUploadInvoice, isOpen: isUploadInvoiceOpen, close: closeUploadInvoice } = useOpenable();
  const downloadFile = useDocumentDownloadFile({ documentType, downloadUrl, orderId: orderFriendlyId! });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const {
    handleFileChange,
    isDocumentUploadLoading,
    documentData: documentUploadData,
    reset: resetUploadDocument,
  } = useUploadDocument({ orderReferenceId, orderId, documentType });
  const isLoading = isDeleteDocumentLoading || isDocumentUploadLoading || isGetDownloadUrlLoading;

  const canUpload = useMemo(() => {
    switch (documentType) {
      case Document_Type_Enum.DeliveryNote:
        return orderStatusFlow.indexOf(orderStatus) >= orderStatusFlow.indexOf(Order_Status_Enum.OrderToBeReceivedByBuyer)
        && realm === Business_Profile_Enum.Supplier;
      case Document_Type_Enum.Invoice:
        return orderStatusFlow.indexOf(orderStatus) >= orderStatusFlow.indexOf(Order_Status_Enum.OrderToBeBilledBySupplier)
        && realm === Business_Profile_Enum.Supplier;
      case Document_Type_Enum.PurchaseOrder:
        return realm === Business_Profile_Enum.Buyer;
      default:
        return false;
    }
  }, [orderStatus]);

  const documentWording = useMemo(() => {
    switch (documentType) {
      case Document_Type_Enum.DeliveryNote:
        return 'order:delivery_note';
      case Document_Type_Enum.PurchaseOrder:
        return 'order:purchase_order';
      default:
        return 'order:invoice';
    }
  }, [documentType]);

  const canEditOrDeleteDocument = useMemo(() => {
    if (realm === Business_Profile_Enum.Supplier) {
      if (documentType === Document_Type_Enum.DeliveryNote || (documentType === Document_Type_Enum.Invoice && !has_invoice_generation && orderStatus === Order_Status_Enum.OrderToBePaidByBuyer)) {
        return true;
      }
    }

    if (realm === Business_Profile_Enum.Buyer) {
      if (documentType === Document_Type_Enum.PurchaseOrder) {
        return true;
      }
    }

    return false;
  }, [has_invoice_generation, orderStatus]);

  const canOnlyDownloadDocument = useMemo(() => (
    !canEditOrDeleteDocument && document
  ), [canEditOrDeleteDocument, document]);

  const shouldDisplayMenu = useMemo(() => (
    (canUpload && document) && !canOnlyDownloadDocument
  ), [canOnlyDownloadDocument, canUpload, document]);

  const buttonStartIcon = useMemo(() => {
    if (!document && canUpload) {
      return <AddIcon />;
    }

    if (canOnlyDownloadDocument) {
      return <FileDownloadOutlinedIcon />;
    }

    if (document && canEditOrDeleteDocument) {
      return <MoreVertIcon />;
    }
  }, [canUpload, canOnlyDownloadDocument, document]);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const openFileInput = (): void => {
    fileInputRef.current!.click();
  };

  const fileChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event);

    // empty the value attribute of the input file so we can re-upload the same file in case of error
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }, []);

  const handleDelete = useCallback(async (confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    await deleteDocument({
      variables: {
        documentId: document!.id,
        userBusinessProfile: realm as unknown as BusinessProfile,
      },
    });
    setIsDeleteModalOpened(false);
    setCommonSnackbarProps({
      label: t(`order:${SNACKBAR_DELETE_DOCUMENT_MESSAGE_KEYS[documentType]}`),
      snackbarProps: {
        open: true,
      },
    });
    handleClose();
  }, [document]);

  const handleDownloadClick = (): void => {
    downloadFile();
    handleClose();
  };

  const handleUploadClick = (): void => {
    if (documentType === Document_Type_Enum.Invoice) {
      openUploadInvoice();
    } else {
      openFileInput();
    }
    handleClose();
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (shouldDisplayMenu) {
      setAnchorEl(event.currentTarget);
      return;
    }

    if (!document) {
      // handle upload
      handleUploadClick();
    } else {
      // handle download
      handleDownloadClick();
    }
  };

  const menuComponent = useMemo(() => {
    if (!shouldDisplayMenu) {
      return;
    }

    return (
      <KheopsMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownloadClick} disableRipple sx={{ justifyContent: 'center' }}>
          <FileDownloadOutlinedIcon />
          <Typography variant="bodyMedium">{t('common:download')}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleUploadClick} disableRipple sx={{ justifyContent: 'center' }}>
          <EditOutlinedIcon />
          <Typography variant="bodyMedium">{t('common:modify')}</Typography>
        </MenuItem>
        {documentType !== Document_Type_Enum.Invoice && (
          <>
            <Divider />
            <MenuItem onClick={() => setIsDeleteModalOpened(true)} disableRipple sx={{ justifyContent: 'center' }}>
              <DeleteOutlineOutlinedIcon />
              <Typography variant="bodyMedium">{t('common:delete')}</Typography>
            </MenuItem>
          </>
        )}
      </KheopsMenu>
    );
  }, [anchorEl, shouldDisplayMenu, documentType]);

  useEffect(() => {
    if (data?.getDownloadUrl?.downloadUrl) {
      setDownloadUrl(data.getDownloadUrl.downloadUrl);
      setDocumentDownloadUrls({ key: documentType, value: data.getDownloadUrl.downloadUrl });
      resetUploadDocument();
    }
  }, [data]);

  useEffect(() => {
    if (document) {
      getDownloadUrl({
        variables: {
          bucket: document.domain,
          objectKey: document.path,
        },
      });
    }
  }, [document]);

  useEffect(() => {
    if (documentUploadData?.upsertDocumentOfOrder?.success) {
      if (orderStatus === Order_Status_Enum.OrderToBeBilledBySupplier && documentType === Document_Type_Enum.Invoice) {
        updateOrder(TaskAction.Approve);
      }

      const messageKeys = document ? SNACKBAR_EDIT_DOCUMENT_MESSAGE_KEYS : SNACKBAR_ADD_DOCUMENT_MESSAGE_KEYS;

      setCommonSnackbarProps({
        label: t(`order:${messageKeys[documentType]}`),
        snackbarProps: {
          open: true,
        },
      });
      resetUploadDocument();
    }
  }, [documentUploadData, orderStatus, updateOrder, document]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <DocumentDeleteDialog
        documentType={documentType}
        onClose={handleDelete}
        open={isDeleteModalOpened}
      />
      <Box
        ref={fileInputRef}
        component="input"
        type="file"
        accept={SUPPORTED_DOCUMENT_TYPES.join(', ')}
        sx={{ display: 'none' }}
        onChange={fileChanged}
      />
      <Chip
        variant="assistive"
        disabled={(!document && !canUpload) || isLoading}
        icon={isLoading ? <CircularProgress /> : buttonStartIcon}
        onClick={handleButtonClick}
        label={t(documentWording)}
      />
      {menuComponent}
      <UploadInvoiceDrawer open={isUploadInvoiceOpen} onClose={closeUploadInvoice} orderReferenceId={orderReferenceId} />
    </Box>
  );
}
