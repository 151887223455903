import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { PRIVACY_SETTING_WORDING } from './PrivacySettingSelect';
import { currentContextAtom, userAtom } from '../../state';

export default function EmailContactInformationDisplay(): React.JSX.Element {
  const user = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['common', 'settings']);

  return (
    <Box
      sx={{
        display: 'flex',
        mt: 2,
        gap: 1,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: '50%',
          },
        }}
      >
        <Typography sx={{ mb: 0.5 }} variant="bodySmall">{t('common:address')}</Typography>
        <Typography variant="bodyMedium" sx={{ wordBreak: 'break-word' }}>{user.email}</Typography>
      </Box>
      <Box sx={{ width: '50%' }}>
        <Typography sx={{ mb: 0.5 }} variant="bodySmall">
          {t('settings:information_visible_to')}
        </Typography>
        <Typography variant="bodyMedium">
          {
            user.contactInformation?.emailPrivacySetting
              ? t(PRIVACY_SETTING_WORDING[user.contactInformation.emailPrivacySetting][realm]!)
              : '-'
          }
        </Typography>
      </Box>
    </Box>
  );
}
