import { Box, Chip, IconButton, styled } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../../ProductFormPackagings';
import { DraftPhoto } from '../../state';

interface PhotoEditOverlayProps {
  csuIndex: number;
  src: DraftPhoto;
  onEdit: () => void;
  onDelete: () => void;
}

export default function PhotoEditOverlay({ csuIndex, src, onEdit, onDelete }: PhotoEditOverlayProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const { setValue, watch } = useFormContext<ProductPackagingsFormData>();
  const mainPhoto = watch(`productPackagings.${csuIndex}.csu.main_photo`);
  const currentPhotoIsMain = src.path === mainPhoto?.path;

  const OverlayButton = styled(IconButton)(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.greys.dark,
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        position: 'absolute',
        pt: 2,
        pr: 2,
        right: 0,
        zIndex: 1,
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        {currentPhotoIsMain && <Chip variant="tag" label={t('products:main_photo')} />}
        <OverlayButton
          onClick={() => setValue(`productPackagings.${csuIndex}.csu.main_photo`, src, { shouldDirty: true })}
        >
          { currentPhotoIsMain
            ? <StarIcon sx={{ color: 'marketing1.main' }} />
            : <StarOutlineIcon />}
        </OverlayButton>
      </Box>
      <OverlayButton
        onClick={onEdit}
      >
        <EditOutlinedIcon />
      </OverlayButton>
      <OverlayButton
        onClick={onDelete}
      >
        <DeleteOutlinedIcon />
      </OverlayButton>
    </Box>

  );
}
