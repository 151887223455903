import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, SvgIconTypeMap, SxProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { KheopsButtonVariant } from '../../providers/CustomThemeProvider';

export interface BackButtonProps {
  to: string;
  variant?: KheopsButtonVariant;
  shouldCheckHistory?: boolean;
  compact?: boolean;
  sx?: SxProps;
  Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
}

export default function BackButton({ to, variant = 'text', compact, sx, shouldCheckHistory = true, Icon = ArrowBackIcon }: BackButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const handleBackClick = (): void => {
    if (!shouldCheckHistory || window.history.length <= 2) {
      navigate(to);
    } else {
      window.history.back();
    }
  };

  if (compact) {
    return (
      <Button
        variant={variant}
        onClick={handleBackClick}
        sx={{ ...sx }}
      >
        <Icon />
      </Button>
    );
  }

  return (
    <Button
      variant={variant}
      onClick={handleBackClick}
      startIcon={<Icon />}
      sx={{ ...sx }}
    >
      {t('common:back')}
    </Button>
  );
}
