import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { OrdersCategories } from '../state/state';

type OrderCategoryChipProps = {
  label: string;
  selectedCategory: OrdersCategories;
  category: OrdersCategories;
  onClick: (category: OrdersCategories) => void;
}

export default function OrderCategoryChip({ label, category, selectedCategory, onClick }: OrderCategoryChipProps): React.JSX.Element {
  const isChipSelected = useMemo(() => (
    selectedCategory === category
  ), [selectedCategory, category]);

  return (
    <Chip
      label={label}
      variant="filter"
      className={isChipSelected ? 'Kheops-selected' : ''}
      onClick={() => onClick(category)}
    />
  );
}
