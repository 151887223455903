import { Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useHits, useSearchBox } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';

export default function QueryTag(): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { results } = useHits();
  const { query, clear } = useSearchBox();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!(query && results) && (
        <Chip
          icon={<SearchIcon />}
          onDelete={clear}
          label={`${t('discovery:advanced_search')} ${t('discovery:N_result', { count: results.nbHits })}`}
          variant="filter"
        />
      )}
    </>
  );
}
