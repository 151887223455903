import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import ProductIcon from '../../assets/icons/product.svg?react';
import { useProductsByCompanyQuery } from '../../queries/__generated__/productsByCompany.generated';
import { RoutePaths } from '../../routes/AppRoutes';
import ProductList from './ProductList';
import { currentContextAtom } from '../../state';
import BackButton from '../../common/components/BackButton';
import TopBar from '../../common/components/TopBar';
import SearchInput from '../../common/components/SearchInput';
import { productFiltersAtom } from './state';

export default function ProductListPage(): React.JSX.Element {
  const { companyId } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['common', 'products']);
  const { data: productsByCompanyQuery } = useProductsByCompanyQuery({
    variables: { companyId },
  });
  const [productFilters, setProductFilters] = useAtom(productFiltersAtom);

  const handleSearchInputChange = (query: string): void => {
    setProductFilters({ ...productFilters, query, page: 1 });
  };

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          flexDirection: 'column',
          alignItems: 'start',
          px: {
            xs: 2,
            sm: 4,
          },
          py: 1.5,
          gap: 1.5,
          height: 'fit-content',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BackButton
            to={RoutePaths.NAVIGATION_MAIN}
            shouldCheckHistory={false}
            compact
          />
          <ProductIcon height={32} width={32} />
          <Typography variant="titleLarge" sx={{ ml: 1 }}>
            {t('common:product_other')}
            {productsByCompanyQuery?.product?.length && ` (${productsByCompanyQuery.product.length})`}
          </Typography>
        </Box>
        <SearchInput
          onChange={(value) => handleSearchInputChange(value)}
          value={productFilters.query}
          sx={{
            height: 48,
            backgroundColor: 'surfaceContainerHighest.main',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
          fullWidth
        />
      </TopBar>
      <Box
        sx={{
          height: {
            lg: 'calc(100% - 48px)',
          },
          overflow: {
            lg: 'auto',
          },
          mt: {
            xs: 15,
            lg: 0,
          },
          mb: {
            xs: 10,
            lg: 0,
          },
          p: {
            xs: 1,
            lg: 3,
          },
        }}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          !productsByCompanyQuery
            ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 4,
                }}
              >
                <CircularProgress size={128} />
              </Box>
            )
            : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    rowGap: 1,
                    justifyContent: 'space-between',
                    alignItems: {
                      xs: 'start',
                      sm: 'center',
                    },
                  }}
                >
                  <Typography variant="titleLarge" sx={{ display: { xs: 'none', lg: 'block' } }}>
                    {t('common:product', { count: productsByCompanyQuery.product.length })} ({productsByCompanyQuery.product.length})
                  </Typography>
                  {!!productsByCompanyQuery.product?.length && (
                    <Button
                      variant="contained"
                      component={Link}
                      startIcon={<AddIcon />}
                      to={RoutePaths.PRODUCT_ADD_INFO}
                      state={{ origin: RoutePaths.COMPANY_PRODUCTS }}
                      sx={{
                        position: {
                          xs: 'fixed',
                          lg: 'initial',
                        },
                        width: {
                          xs: 'calc(100% - 32px)',
                          lg: 'initial',
                        },
                        bottom: 32,
                        left: 16,
                        zIndex: 10,
                      }}
                    >
                      {t('products:add_a_product')}
                    </Button>
                  )}
                </Box>
                <ProductList products={productsByCompanyQuery.product} />
              </>
            )
        }
      </Box>
    </>
  );
}
