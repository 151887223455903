import { Blend, argbFromHex, hexFromArgb } from '@material/material-color-utilities';

// https://github.com/ZakAlbert/react-material-you-theme/blob/main/src/theme/M3/utils/getStayeLayerColor.ts#L10
export enum StateLayer {
  Layer8 = 0.08,
  Layer12 = 0.12,
  Layer16 = 0.16,
  Layer38 = 0.38,
}

export const getStateLayerColor = (state: StateLayer, containerColor: string, contentColor: string): string => {
  return hexFromArgb(
    Blend.cam16Ucs(
      argbFromHex(containerColor),
      argbFromHex(contentColor),
      state,
    ),
  );
};
