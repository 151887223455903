import { Box, Card, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Product_Family_Name_Enum } from 'kheops-graphql';
import { productFamilyMap, ProductFamilyMap } from 'kheops-utils';
import { PackagingFamilyTypeIntl, ProductFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import ProductFamilyChip from '../settingsDialog/ProductFamilyChip';
import { productFamilyImageMap } from '../../discovery/state';

export interface UsersDepartmentsByFamilyProps {
  selectedDepartments: ProductFamilyMap;
}

export default function UserDepartmentsByFamily({ selectedDepartments }: UsersDepartmentsByFamilyProps): React.JSX.Element {
  const { t } = useTranslation(['product-family', 'settings']);

  const isProductFamilySelected = (productFamily: Product_Family_Name_Enum): boolean => {
    return selectedDepartments[productFamily].length === productFamilyMap[productFamily].length;
  };

  return (
    <>
      {(Object.keys(selectedDepartments) as Product_Family_Name_Enum[]).map((productFamily) => {
        if (!selectedDepartments[productFamily].length) {
          return;
        }

        return (
          <Card
            key={productFamily}
            sx={{
              p: 2,
              backgroundColor: 'surfaceContainerLow.main',
              borderRadius: 4,
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <img src={productFamilyImageMap[productFamily]} alt={productFamily} height="64" style={{ borderRadius: '100%' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Typography variant="bodyLarge">
                {t(`product-family:${ProductFamilyTypeIntl[productFamily]}`)}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                {
                  isProductFamilySelected(productFamily)
                    ? <Chip variant="tag" color="surfaceContainerHigh" label={t('settings:all_departments')} />
                    : (
                      <>
                        {selectedDepartments[productFamily].map((subFamily) => {
                          return (
                            <ProductFamilyChip
                              subFamily={subFamily}
                              variant="tag"
                              color="surfaceContainerHigh"
                              label={t(`product-family:${[PackagingFamilyTypeIntl[subFamily]]}`)}
                              key={subFamily}
                            />
                          );
                        })}
                      </>
                    )
                }
              </Box>
            </Box>
          </Card>
        );
      })}
    </>
  );
}
