import { Chip } from '@mui/material';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import { currentContextAtom } from '../../state';
import FilterUtils from './filter.utils';
import useRefine from '../../hooks/useRefine';
import useDiscoveryDefaultFilters, { SearchMode } from '../../hooks/useDiscoveryDefaultFilters';

export interface ContractStatusFilterTagProps {
  attribute: string;
  searchMode?: SearchMode;
}

export default function ContractStatusFilterTag({ attribute, searchMode }: ContractStatusFilterTagProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { refine, uiState } = useRefine();
  const { companyId, realm, companyAddress } = useAtomValue(currentContextAtom);
  const defaultFilters = useDiscoveryDefaultFilters(searchMode);
  const signedContractFilter = FilterUtils.GetSignedContractFilter(attribute, companyId);
  const deliveryZoneFilter = FilterUtils.GetSupplierCanDeliverFilter(companyAddress.administrativeAreaLevel2!);
  const [hasContractStatusFilter, setHasContractStatusFilter] = useState(false);
  const [hasDeliveryZoneFilter, setHasDeliveryZoneFilter] = useState(false);

  const defaultFiltersList = useMemo(() => {
    return defaultFilters ? [defaultFilters] : [];
  }, [defaultFilters]);

  const handleContractStatusTagDelete = useCallback((): void => {
    const filtersList = [...defaultFiltersList, signedContractFilter];

    if (hasDeliveryZoneFilter) {
      filtersList.push(deliveryZoneFilter);
    }

    const filters = filtersList.join(' AND ');

    refine({ filters });
  }, [refine, hasDeliveryZoneFilter, defaultFiltersList]);

  const handleDeliveryZoneTagDelete = useCallback((): void => {
    const filtersList = [...defaultFiltersList];

    if (!hasContractStatusFilter) {
      filtersList.push(signedContractFilter);
    }

    const filters = filtersList.join(' AND ');

    refine({ filters });
  }, [refine, hasContractStatusFilter, defaultFiltersList]);

  useEffect(() => {
    setHasContractStatusFilter(uiState.filters !== undefined && !uiState.filters.includes(signedContractFilter));
    setHasDeliveryZoneFilter(!!uiState.filters?.includes(deliveryZoneFilter));
  }, [uiState]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasContractStatusFilter && (
        <Chip
          icon={<StorefrontOutlinedIcon />}
          label={t(DiscoveryWordingsKeys[realm].contract_status_all_option)}
          onDelete={handleContractStatusTagDelete}
          variant="filter"
        />
      )}
      {hasDeliveryZoneFilter && (
        <Chip
          icon={<StorefrontOutlinedIcon />}
          label={t(DiscoveryWordingsKeys[realm].delivery_zone_tag)}
          onDelete={handleDeliveryZoneTagDelete}
          variant="filter"
        />
      )}
    </>
  );
}
