import { Box, Button, Dialog, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { ProductFamilyMap, productFamilyMap } from 'kheops-utils';
import { Product_Family_Name_Enum } from 'kheops-graphql';
import EmptyDepartmentsPlaceholder from './EmptyDepartmentsPlaceholder';
import useOpenable from '../../hooks/useOpenable';
import UserDepartmentsByFamily from './UserDepartmentsByFamily';
import GlobalDepartmentSetting from './GlobalDepartmentSetting';
import ProductFamiliesSettings from '../settingsDialog/ProductFamiliesSettings';
import { currentContextAtom } from '../../state';

export default function UserDepartments(): React.JSX.Element {
  const { t } = useTranslation(['settings']);
  const context = useAtomValue(currentContextAtom);
  const { open, close, isOpen } = useOpenable();

  const selectedDepartmentsMap = useMemo((): ProductFamilyMap => {
    const result = {
      [Product_Family_Name_Enum.Beverages]: [],
      [Product_Family_Name_Enum.FreshFood]: [],
      [Product_Family_Name_Enum.Grocery]: [],
      [Product_Family_Name_Enum.NonFoodProducts]: [],
    } as ProductFamilyMap;

    if (context.productFamilies?.length) {
      context.productFamilies.forEach(({ product_family, product_sub_family }) => {
        if (product_family) {
          result[product_family] = productFamilyMap[product_family];
        } else if (product_sub_family) {
          const productFamily = Object.entries(productFamilyMap).find(([, subFamilies]) => subFamilies.includes(product_sub_family))![0] as Product_Family_Name_Enum;

          result[productFamily].push(product_sub_family);
        }
      });
    }

    return result;
  }, [context]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        mx: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="titleLarge">
          {t('settings:your_departments')}
        </Typography>
        <Button
          variant="tonal"
          startIcon={<EditIcon />}
          onClick={open}
        >
          {t('common:modify')}
        </Button>
      </Box>
      {
        !context.productFamilies?.length
          ? <EmptyDepartmentsPlaceholder />
          : (
            <>
              {
                context.productFamilies[0].global_setting
                  ? <GlobalDepartmentSetting globalSetting={context.productFamilies[0].global_setting} />
                  : <UserDepartmentsByFamily selectedDepartments={selectedDepartmentsMap} />
              }
              <Dialog
                open={isOpen}
                onClose={(): void => { /* noop */ }}
                sx={{
                  mx: {
                    xs: 2,
                    sm: 0,
                  },
                  '& .MuiPaper-root.MuiDialog-paper': {
                    minWidth: {
                      xs: '100%',
                      sm: 640,
                    },
                  },
                }}
              >
                <ProductFamiliesSettings next={close} standAlone />
              </Dialog>
            </>
          )
      }
    </Box>
  );
}
