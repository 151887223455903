import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Chip, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useAtomValue, useSetAtom } from 'jotai';
import { Custom_Price_List_Packaging_Insert_Input } from 'kheops-graphql';
import { currentContextAtom } from '../../state';
import { RoutePaths } from '../../routes/AppRoutes';
import { PageWrapper } from '../../common/components/PageWrapper';
import useCustomPriceListFormDefaultValues from './hooks/useCustomPriceListFormDefaultValues';
import useUpsertCustomPriceListMutation, { UpsertCustomPriceListMutationVariables } from './hooks/useUpsertCustomPriceListMutation';
import { DraftCustomPriceList } from './state';
import CustomPriceListInfoForm from './CustomPriceListInfoForm';
import CustomPriceListProductsForm from './CustomPriceListProductsForm';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import CustomPriceListFormButtons from './CustomPriceListFormButtons';
import CustomPriceListFormHeader from './hooks/CustomPriceListFormHeader';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';
import { ellipsisStyle } from '../../common/utils/style.utils';
import CustomPriceListInfoFormDrawerButton from './CustomPriceListInfoFormDrawerButton';

export default function CustomPriceListForm(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['price', 'catalogs']);
  const { companyId } = useAtomValue(currentContextAtom);
  const customPriceListId = searchParams.get('customPriceListId') || undefined;
  const contractId = searchParams.get('contractId') || undefined;
  const copyCustomPriceListId = searchParams.get('copyCustomPriceListId') || undefined;
  const defaultValues = useCustomPriceListFormDefaultValues({
    customPriceListId: customPriceListId || copyCustomPriceListId,
    contractId,
    copy: !!copyCustomPriceListId,
  });
  const form = useForm<DraftCustomPriceList>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    reset,
    formState,
    watch,
  } = form;
  const { upsertCustomPriceListMutation } = useUpsertCustomPriceListMutation();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [shouldDisplayProductsFormMobile, setShouldDisplayProductsFormMobile] = useState(!!(customPriceListId || copyCustomPriceListId));
  const [customPriceListContracts, setCustomPriceListContracts] = useState<string[]>(defaultValues.contracts);
  const [customPriceListName, setCustomPriceListName] = useState<string>(defaultValues.name);
  const [customPriceListFormName, customPriceListFormContracts] = [watch('name'), watch('contracts')];

  useEffect(() => {
    if (shouldDisplayProductsFormMobile && !customPriceListName) {
      setCustomPriceListContracts(customPriceListFormContracts);
      setCustomPriceListName(customPriceListFormName);
    }
  }, [shouldDisplayProductsFormMobile, customPriceListFormName]);

  const onSubmit = useCallback(async (draftCustomPriceList: DraftCustomPriceList): Promise<void> => {
    const upsertCustomPriceListMutationVariables = {} as UpsertCustomPriceListMutationVariables;
    const customPriceListPackagingsPayload = Object.entries(draftCustomPriceList.prices).reduce((acc, [packagingSku, draftPrice]) => {
      if (draftPrice.discount) {
        acc.push({
          packaging_sku: parseInt(packagingSku, 10),
          discount: draftPrice.discount / 100,
          company_id: companyId,
          custom_price_list_id: customPriceListId || undefined,
        });
      }

      return acc;
    }, [] as Custom_Price_List_Packaging_Insert_Input[]);
    let successLabel: string;

    if (!customPriceListId) {
      upsertCustomPriceListMutationVariables.upsertCustomPriceListVariables = {
        custom_price_list: {
          name: draftCustomPriceList.name,
          company_id: companyId,
          custom_price_list_packagings: {
            data: customPriceListPackagingsPayload,
          },
        },
      };
      upsertCustomPriceListMutationVariables.addedContractIds = draftCustomPriceList.contracts;

      successLabel = 'price:custom_price_list_created';
    } else {
      const deletedPackagingSkus = Object.entries(defaultValues.prices).reduce((acc, [packagingSku, draftPrice]) => {
        if (!draftPrice) {
          acc.push(parseInt(packagingSku, 10));
        }

        return acc;
      }, [] as number[]);

      upsertCustomPriceListMutationVariables.upsertCustomPriceListVariables = {
        id: customPriceListId,
        name: draftCustomPriceList.name,
        now: new Date().toISOString(),
        deletedPackagingSkus,
        customPriceListPackagings: customPriceListPackagingsPayload,
      };
      upsertCustomPriceListMutationVariables.addedContractIds = draftCustomPriceList.contracts.filter((value) => !defaultValues.contracts.includes(value));
      upsertCustomPriceListMutationVariables.deletedContractIds = defaultValues.contracts.filter((value) => !draftCustomPriceList.contracts.includes(value));

      successLabel = 'price:custom_price_list_updated';
    }

    const newCustomPriceListId = await upsertCustomPriceListMutation(upsertCustomPriceListMutationVariables);

    if (location.state?.origin) {
      navigate(
        {
          pathname: location.state.origin,
          search: newCustomPriceListId ? `?${createSearchParams({ newCustomPriceListId })}` : undefined,
        },
        {
          state: { newCustomPriceListId, newCustomPriceListName: draftCustomPriceList.name },
        },
      );
    } else {
      navigate(RoutePaths.COMPANY_CUSTOM_PRICE_LISTS);
    }

    setCommonSnackbarProps({
      label: t(successLabel),
      snackbarProps: {
        open: true,
      },
    });
  }, [customPriceListId, defaultValues]);

  useEffect((): void => {
    reset(defaultValues);
  }, [defaultValues]);

  const formComponent = useMemo(() => {
    if (!isDownLg) {
      return (
        <Grid2
          container
          sx={{
            height: 'calc(100vh - 168px)',
          }}
          columnSpacing={2}
        >
          <Grid2 size={{ xs: 0, lg: 4 }} sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: '100%' }}>
              <CustomPriceListFormHeader edit={!!customPriceListId} />
              <CustomPriceListInfoForm />
              <CustomPriceListFormButtons copy={!!copyCustomPriceListId} edit={!!customPriceListId} fullWidth />
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 8 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% + 24px)',
                pb: {
                  lg: 0,
                },
                p: 0,
                gap: 3,
                borderRadius: 0,
              }}
            >
              <CustomPriceListProductsForm />
            </Box>
          </Grid2>
        </Grid2>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 164px)',
          mt: 8,
        }}
      >
        {shouldDisplayProductsFormMobile
          ? (
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <CustomPriceListProductsForm />
              <CustomPriceListFormButtons
                parentBoxSx={{
                  position: 'fixed',
                  bottom: 0,
                  width: 'calc(100vw - 32px)',
                  left: 0,
                  mx: 2,
                }}
                fullWidth
                shouldDisplayCancelButton={false}
                copy={!!copyCustomPriceListId}
                edit={!!customPriceListId}
              />
            </Box>
          )
          : (
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
              <CustomPriceListInfoForm />
              <Button
                variant="contained"
                disabled={!formState.isValid}
                onClick={() => setShouldDisplayProductsFormMobile(true)}
                startIcon={<AddIcon />}
                sx={{
                  mt: 4,
                }}
              >
                {t('catalogs:add_packagings')}
              </Button>
            </Box>
          )}
      </Box>
    );
  }, [isDownLg, shouldDisplayProductsFormMobile, formState]);

  return (
    <PageWrapper
      sx={{
        [theme.breakpoints.down('lg')]: {
          px: 2,
        },
      }}
    >
      <FormProvider {...form}>
        <TopBar
          sx={{
            display: {
              xs: 'flex',
              lg: 'none',
            },
            flexDirection: 'column',
            alignItems: 'start',
            p: 1.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              my: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <BackButton
                to={RoutePaths.COMPANY_CUSTOM_PRICE_LISTS}
                shouldCheckHistory={false}
                Icon={shouldDisplayProductsFormMobile ? CloseIcon : ArrowBackIcon}
                compact
              />
              {shouldDisplayProductsFormMobile
                ? (
                  <>
                    <Typography sx={{ maxWidth: 'calc(100vw - 228px)', ...ellipsisStyle }} variant="titleLarge">
                      {customPriceListName}
                    </Typography>
                    <Chip
                      variant="tag"
                      label={t('catalogs:N_store_count', { count: customPriceListContracts.length })}
                      icon={<StoreOutlinedIcon />}
                    />
                  </>
                )
                : (
                  <Typography variant="titleLarge">
                    {t('price:create_custom_price_list')}
                  </Typography>
                )}
            </Box>
            {shouldDisplayProductsFormMobile && (
              <CustomPriceListInfoFormDrawerButton
                name={customPriceListName}
                contracts={customPriceListContracts}
                setContracts={setCustomPriceListContracts}
                setName={setCustomPriceListName}
              />
            )}
          </Box>
        </TopBar>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          {formComponent}
        </Box>
      </FormProvider>
    </PageWrapper>
  );
}
