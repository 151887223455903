import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlgoliaHit } from 'instantsearch.js';
import { useAtomValue } from 'jotai';
import { Contract_Status_Enum } from 'kheops-graphql';
import { RoutePaths } from '../../routes/AppRoutes';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import { PackagingFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import CompanyDistanceChip from '../../common/components/CompanyDistanceChip';
import MinimumOrderValueChip from '../../common/components/MinimumOrderValueChip';
import ContractStatusTag from '../../search/ContractStatusTag';
import { SupplierHit } from '../../search/search';
import { contractsAtom, currentContextAtom } from '../../state';
import { currentSubFamilyAtom, imageSizeByBreakpoint } from '../state';
import SearchUtils from '../../common/utils/search.utils';
import ImageDarkOverlay from '../../common/components/ImageDarkOverlay';
import { ellipsisStyle } from '../../common/utils/style.utils';

interface DiscoveryCompanyHitProps {
  hit: AlgoliaHit<SupplierHit>;
}

export default function DiscoveryCompanyHit({ hit }: DiscoveryCompanyHitProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery']);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const breakpoint = useCurrentBreakpoint();
  const contracts = useAtomValue(contractsAtom);
  const { companyId, companyAddress } = useAtomValue(currentContextAtom);
  const currentSubFamily = useAtomValue(currentSubFamilyAtom);

  const contractStatusBadge = useMemo(() => {
    const signedPartner = hit.partners.find((partner) => partner.includes(`${companyId}_${Contract_Status_Enum.Signed}`));

    if (!signedPartner) {
      return undefined;
    }

    return (
      <ContractStatusTag status={Contract_Status_Enum.Signed} />
    );
  }, []);

  const minimumOrderValue = useMemo(() => {
    let res: number | undefined;

    if (contracts.length) {
      const contract = contracts.find(({ supplyingCompanyId }) => supplyingCompanyId === hit.objectID);

      if (contract && contract.status === Contract_Status_Enum.Signed) {
        res = contract.minimum_order_value!;
      }
    }

    if (res === undefined) {
      hit.mov_per_delivery_zones?.forEach((areaMov) => {
        const [area, mov] = areaMov.split('|');

        if (area === companyAddress.administrativeAreaLevel2 || (area === 'ALL_AREAS' && res === undefined)) {
          res = Number(mov);
        }
      });
    }

    return res;
  }, [hit]);

  const canDeliver = useMemo(() => {
    if (!hit.partners.includes(`${companyId}_SIGNED`) && hit.delivery_zones?.length) {
      return SearchUtils.canItDeliverAnArea(hit.delivery_zones, companyAddress.administrativeAreaLevel2!);
    }

    return true;
  }, [hit]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'row-reverse',
          sm: 'column',
        },
      }}
    >
      <Link
        to={generatePath(RoutePaths.DISCOVERY_SUPPLIER, { companyId: hit.objectID })}
        state={{ origin: 'DISCOVERY' }}
      >
        <SizedImage
          src={FileUtils.BuildCompanyImageUrl({ photo: hit.photo, type: hit.business_profile, size: imageSizeByBreakpoint[breakpoint] })}
          alt={t('common:company')}
          height={imageSizeByBreakpoint[breakpoint].width!}
          width={imageSizeByBreakpoint[breakpoint].height!}
          sx={{
            flexShrink: 0,
            border: !hit.photo ? '1px solid' : 'unset',
            borderColor: 'divider',
            borderRadius: 6,
          }}
          overlay={(
            <>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  p: 2,
                }}
              >
                {!isXs && (
                  <>
                    {/* _geoloc's type is wrong, it should also accept lon for longitude, as it is configured that way in our records. */}
                    <CompanyDistanceChip
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      targetGeoLoc={{ lat: hit._geoloc!.lat, lng: (hit._geoloc! as any).lon }}
                    />
                    {minimumOrderValue !== undefined && (
                      <MinimumOrderValueChip minimumOrderValue={minimumOrderValue} />
                    )}
                  </>
                )}
              </Box>

              {!canDeliver && (
                <ImageDarkOverlay label={t('discovery:dont_deliver_in_your_area')} />
              )}
            </>
          )}
        />
      </Link>
      <Box
        sx={{
          my: {
            xs: 2,
            sm: 1,
          },
          mr: {
            xs: 0.5,
            sm: 2,
          },
          ml: 2,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {contractStatusBadge}
            <Tooltip title={hit.tradeName} enterDelay={500} enterNextDelay={500}>
              <Typography
                variant="bodyLarge"
                sx={{
                  ml: contractStatusBadge ? 0.5 : 0,
                  ...ellipsisStyle,
                }}
              >
                {hit.tradeName}
              </Typography>
            </Tooltip>
          </Box>
          {!isXs && currentSubFamily === 'ALL' && hit.product_families?.length && (
            <Typography variant="bodyMedium" color="secondary">
              {t(`product-family:${PackagingFamilyTypeIntl[hit.product_families[0]]}`)}
              {hit.product_families.length > 1 && ` (+${hit.product_families.length - 1})`}
            </Typography>
          )}
        </Box>
        {isXs && (
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
            }}
          >
            <CompanyDistanceChip
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              targetGeoLoc={{ lat: hit._geoloc!.lat, lng: (hit._geoloc! as any).lon }}
            />
            {minimumOrderValue !== undefined && (
              <MinimumOrderValueChip minimumOrderValue={minimumOrderValue} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
