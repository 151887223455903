import { Chip } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import { PackagingFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';

interface ProductFamilyTagsProps {
  attribute: string;
}

export default function ProductFamilyTags({ attribute }: ProductFamilyTagsProps): React.JSX.Element {
  const { t } = useTranslation(['product-family']);
  const { refine } = useRefinementList({ attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute) as string[];

  return (
    <>
      {currentRefinements.map((refinement) => (
        <Chip
          key={refinement}
          variant="filter"
          icon={<ShoppingBasketOutlinedIcon />}
          label={t(`product-family:${PackagingFamilyTypeIntl[refinement as Product_Sub_Family_Name_Enum]}`)}
          onDelete={() => refine(refinement)}
        />
      ))}
    </>
  );
}
