import { Box, Button, Chip, SvgIcon, Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { currentContractAtom } from '../state';
import MinimumOrderValue from '../../assets/icons/minimum_order_value.svg?react';
import DealImage from '../../assets/images/deal.png';
import { RoutePaths } from '../../routes/AppRoutes';
import ContractsUtils from '../../common/utils/contracts.utils';
import PriceModeChip from '../../common/components/PriceModeChip';
import { currentContextAtom } from '../../state';
import CommissionInfo from '../../common/components/CommissionInfo';

const OFFER_ACCEPTED_WORDINGS = {
  [Business_Profile_Enum.Buyer]: {
    description: 'contracts:new_deal_description_as_buyer',
    cta: 'contracts:make_a_first_order',
  },
  [Business_Profile_Enum.Supplier]: {
    description: 'contracts:new_deal_description_as_supplier',
    cta: 'contracts:suggest_a_first_order',
  },
};

export default function AcceptedOfferMessage(): React.JSX.Element {
  const { t } = useTranslation(['contracts']);
  const currencyFormat = useCurrencyFormat({ maximumFractionDigits: 0 });
  const { realm } = useAtomValue(currentContextAtom);
  const currentContract = useAtomValue(currentContractAtom);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {currentContract && !!realm && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 3, px: 3 }}>
          <Box
            sx={{
              display: 'flex',
              columnGap: {
                xs: 0,
                md: 4.5,
              },
              justifyContent: 'space-between',
              backgroundColor: 'primaryContainer.main',
              borderRadius: 6,
              flexWrap: {
                xs: 'wrap',
                lg: 'nowrap',
              },
            }}
          >
            <Box sx={{ position: 'relative', display: 'flex' }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  top: {
                    xs: -60,
                    md: -40,
                    lg: 0,
                  },
                  left: {
                    sm: -20,
                    lg: 0,
                  },
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="165" height="169" viewBox="0 0 165 169" fill="none">
                  {/* eslint-disable-next-line react/no-unknown-property */}
                  <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M41.7455 -100.996C96.0448 -100.573 145.252 -61.6097 160.945 -2.49022C175.744 53.2613 148.326 109.321 106.483 142.892C66.6112 174.882 14.3921 178.749 -25.8656 147.39C-69.9549 113.046 -98.3904 53.6704 -82.5871 -4.38704C-66.1766 -64.6749 -13.7461 -101.428 41.7455 -100.996Z" fill="white" />
                </svg>
              </Box>
              <Typography
                variant="displayMedium"
                sx={{
                  p: 3,
                  position: 'relative',
                  zIndex: 10,
                  width: 105,
                  margin: {
                    lg: 'auto',
                  },
                }}
              >
                {t('contracts:new_deal')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 1,
                py: {
                  xs: 2,
                  lg: 3,
                },
                order: {
                  xs: 3,
                  lg: 2,
                },
                flex: {
                  xs: 1,
                  lg: 'unset',
                },
                px: {
                  xs: 2,
                  lg: 0,
                },
              }}
            >
              <Typography variant="bodyMedium">
                {t(
                  OFFER_ACCEPTED_WORDINGS[realm].description,
                  { tradeName: realm === Business_Profile_Enum.Buyer ? currentContract!.supplying_company.tradeName : currentContract!.buying_company.tradeName },
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: {
                    xs: 'center',
                    lg: 'start',
                  },
                }}
              >
                <Chip
                  variant="tag"
                  icon={<SvgIcon component={MinimumOrderValue} inheritViewBox sx={(theme) => ({ fill: theme.palette.marketing3.main })} />}
                  label={t('contracts:N_minimum_order_value', { value: currencyFormat.format(currentContract!.minimum_order_value!) })}
                />
                {ContractsUtils.displayPriceMode(realm!, currentContract) && (
                  <PriceModeChip contract={currentContract} />
                )}
              </Box>
              {realm === Business_Profile_Enum.Supplier && (
                <CommissionInfo commission={currentContract.supplier_commission_rate!} />
              )}
            </Box>
            <Box
              component="img"
              src={DealImage}
              sx={{
                order: {
                  xs: 2,
                  lg: 3,
                },
                width: {
                  xs: 132,
                  lg: 165,
                },
                height: {
                  xs: 128,
                  lg: 170,
                },
              }}
            />
          </Box>
          <Button
            component={Link}
            to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: realm === Business_Profile_Enum.Buyer ? currentContract!.supplyingCompanyId : currentContract!.buyingCompanyId })}
            variant="contained"
            startIcon={<AddShoppingCartIcon />}
            sx={{ alignSelf: 'center', mb: 3 }}
          >
            {t(OFFER_ACCEPTED_WORDINGS[realm].cta)}
          </Button>
        </Box>
      )}
    </>
  );
}
