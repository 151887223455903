import { useCallback, useEffect, useState } from 'react';
import { Box, Fab } from '@mui/material';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CloseIcon from '@mui/icons-material/Close';
import { DataCaptureView, FrameSourceState, LaserlineViewfinder } from 'scandit-web-datacapture-core';
import { Barcode, BarcodeCaptureListener, BarcodeCaptureOverlay, BarcodeCaptureOverlayStyle } from 'scandit-web-datacapture-barcode';
import { useAtomValue } from 'jotai';
import { barcodeCaptureAtom } from '../state/state';

interface BarcodeCaptureButtonProps {
  onCapture: (barcodes: Barcode[]) => void;
}

export default function BarcodeCaptureButton({ onCapture }: BarcodeCaptureButtonProps): React.JSX.Element {
  const barcodeCapture = useAtomValue(barcodeCaptureAtom);
  const [dataCaptureViewNode, setDataCaptureViewNode] = useState<HTMLDivElement>();
  const [dataCaptureViewDisplay, setDataCaptureViewDisplay] = useState('none');
  const dataCaptureViewRef = useCallback((node: HTMLDivElement) => {
    if (node && barcodeCapture) {
      const initDataCaptureView = async (): Promise<void> => {
        const view = await DataCaptureView.forContext(barcodeCapture.context);
        view.connectToElement(node);

        const barcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
          barcodeCapture,
          view,
          BarcodeCaptureOverlayStyle.Frame,
        );
        const viewfinder = new LaserlineViewfinder();

        await barcodeCaptureOverlay.setViewfinder(viewfinder);
      };

      setDataCaptureViewNode(node);
      initDataCaptureView();
    }
  }, [barcodeCapture]);

  const handleBarcodeCaptureClick = async (): Promise<void> => {
    await barcodeCapture!.context!.frameSource!.switchToDesiredState(FrameSourceState.On);
    barcodeCapture!.setEnabled(true);
    setDataCaptureViewDisplay('flex');
  };

  const handleDisableBarcodeCapture = async (): Promise<void> => {
    setDataCaptureViewDisplay('none');
    await barcodeCapture!.context!.frameSource!.switchToDesiredState(FrameSourceState.Off);
    barcodeCapture!.setEnabled(false);
  };

  useEffect(() => {
    if (barcodeCapture && dataCaptureViewNode) {
      const listener: BarcodeCaptureListener = {
        didScan: async (capture, session): Promise<void> => {
          onCapture(session.newlyRecognizedBarcodes);
          handleDisableBarcodeCapture();
        },
      };

      barcodeCapture.addListener(listener);

      return () => barcodeCapture.removeListener(listener);
    }
  }, [barcodeCapture, dataCaptureViewNode]);

  return (
    <>
      <Box
        ref={dataCaptureViewRef}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: dataCaptureViewDisplay,
          zIndex: 10,
        }}
      />
      {!!dataCaptureViewNode && (
        <>
          <Fab
            onClick={handleBarcodeCaptureClick}
            size="extraLarge"
            color="accent"
            sx={{
              backgroundColor: 'tertiary.main',
              '&:hover': {
                backgroundColor: 'tertiary.main',
              },
              color: 'onTertiary.main',
              display: {
                xs: dataCaptureViewDisplay === 'flex' ? 'none' : 'flex',
                md: 'none',
              },
              position: 'fixed',
              bottom: {
                xs: 80,
                md: 32,
              },
              right: {
                xs: '50%',
              },
              mr:'-36px'
            }}
          >
            <DocumentScannerIcon sx={{ transform: 'rotate(90deg)' }} />
          </Fab>
          <Fab
            size="small"
            onClick={handleDisableBarcodeCapture}
            sx={{
              backgroundColor: 'background.paper',
              display: {
                xs: dataCaptureViewDisplay,
                md: 'none',
              },
              position: 'fixed',
              top: {
                xs: 16,
                sm: 32,
              },
              right: {
                xs: 16,
                sm: 32,
              },
            }}
          >
            <CloseIcon />
          </Fab>
        </>
      )}
    </>
  );
}
