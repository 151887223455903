import { Box, Button, Dialog, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import ContactInformationTemplate from './ContactInformationTemplate';
import EmailContactInformationDisplay from './EmailContactInformationDisplay';
import PhoneContactInformationDisplay, { PhoneType } from './PhoneContactInformationDisplay';
import useOpenable from '../../hooks/useOpenable';
import ContactInformationSettings from '../settingsDialog/ContactInformationSettings';

export default function ContactInformationCard(): React.JSX.Element {
  const { open, close, isOpen } = useOpenable();
  const { t } = useTranslation(['common', 'settings']);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="titleLarge">
          {t('settings:your_contact_information_short')}
        </Typography>
        <Button
          variant="tonal"
          startIcon={<EditIcon />}
          onClick={open}
        >
          {t('common:modify')}
        </Button>
      </Box>
      <ContactInformationTemplate
        mobilePhoneNumberComponent={<PhoneContactInformationDisplay phoneType={PhoneType.MOBILE} />}
        landlineNumberComponent={<PhoneContactInformationDisplay phoneType={PhoneType.LANDLINE} />}
        emailComponent={<EmailContactInformationDisplay />}
      />
      <Dialog
        open={isOpen}
        onClose={(): void => { /* noop */ }}
        sx={{
          mx: {
            xs: 2,
            sm: 0,
          },
          '& .MuiPaper-root.MuiDialog-paper': {
            minWidth: {
              xs: '100%',
              sm: 640,
            },
          },
        }}
      >
        <ContactInformationSettings
          next={close}
          standAlone
          submitLabel={t('common:validate')}
          cancelLabel={t('common:cancel')}
        />
      </Dialog>
    </Box>
  );
}
