import { Box, Button, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { DiscoveryView, discoveryViewAtom } from './state';

const SwitchButtonStyle: SxProps<Theme> = {
  width: 108,
  px: 0,
  color: 'onSurface.main',
};

const SelectedViewButtonStyle: SxProps<Theme> = {
  ...SwitchButtonStyle,
  backgroundColor: 'tertiary.main',
  color: 'onTertiary.main',
  '&:hover': {
    backgroundColor: 'tertiary.main',
  },
  '&:focus': {
    backgroundColor: 'tertiary.main',
  },
  '&:focus:not(:focus-visible)': {
    backgroundColor: 'tertiary.main',
  },
  '&:active': {
    backgroundColor: 'tertiary.main',
  },
};

const NonSelectedViewButtonStyle: SxProps<Theme> = {
  ...SwitchButtonStyle,
  backgroundColor: 'surface.main',
  color: 'onSurface.main',
  '&:hover': {
    backgroundColor: 'surfaceContainerLow.main',
  },
  '&:focus': {
    backgroundColor: 'surfaceContainerLow.main',
  },
  '&:focus:not(:focus-visible)': {
    backgroundColor: 'surfaceContainerLow.main',
  },
  '&:active': {
    backgroundColor: 'surfaceContainerLow.main',
  },
};

export default function DiscoveryViewSwitch(): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery', 'search']);
  const [discoveryView, setDiscoveryView] = useAtom(discoveryViewAtom);
  const getViewButtonStyle = (view: DiscoveryView): SxProps<Theme> => {
    return discoveryView === view ? SelectedViewButtonStyle : NonSelectedViewButtonStyle;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        border: '1px solid',
        borderColor: 'outline.main',
        borderRadius: 10,
      }}
    >
      <Box sx={{ height: 40, display: 'flex', alignSelf: 'start' }}>
        <Button
          variant="contained"
          sx={{ ...getViewButtonStyle('PRODUCTS') }}
          onClick={() => setDiscoveryView('PRODUCTS')}
        >
          {t('common:product_other')}
        </Button>
        <Button
          variant="contained"
          sx={{ ...getViewButtonStyle('SUPPLIERS') }}
          onClick={() => setDiscoveryView('SUPPLIERS')}
        >
          {t('search:suppliers')}
        </Button>
      </Box>
    </Box>
  );
}
