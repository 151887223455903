import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderPreview } from '../../hooks/useOrders';
import OrderRow from './OrderRow';

interface OrderTableProps {
  orders: OrderPreview[] | undefined;
}

export default function OrderTable({ orders }: OrderTableProps): React.JSX.Element {
  const { t } = useTranslation(['order']);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {
        orders?.length
          ? orders?.map((order) => {
            return (
              <OrderRow key={order.id} order={order} />
            );
          })
          : (
            <Box sx={{ textAlign: 'center', pt: 3 }}>
              <Typography variant="titleMedium">
                {t('order:no_order_matches_your_search')}
              </Typography>
            </Box>
          )
      }
    </Box>
  );
}
