import { useEffect, useMemo } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Contract_Status_Enum } from 'kheops-graphql';
import { useTranslation } from 'react-i18next';
import { usePackagingsByCompanyQuery } from '../../../../queries/__generated__/packagingsByCompany.generated';
import { currentContextAtom } from '../../../../state';
import { companyPackagingsAtom } from '../../../state';
import { DraftSubCatalog, DraftSubCatalogAvailabilityCollection, filteredCompanyPackagingsAtom } from '../state';
import { useSubCatalogByIdLazyQuery } from '../../../../queries/__generated__/subCatalogById.generated';

export interface UseSubCatalogFormDefaultValuesParams {
  subCatalogId?: string | null;
  contractId?: string | null;
  copy?: boolean;
}

export default function useSubCatalogFormDefaultValues({ subCatalogId, contractId, copy }: UseSubCatalogFormDefaultValuesParams): DraftSubCatalog {
  const { t } = useTranslation(['price']);
  const { companyId } = useAtomValue(currentContextAtom);
  const [subCatalogByIdQuery, { data: subCatalogData }] = useSubCatalogByIdLazyQuery();
  const { data: catalogPackagingsData } = usePackagingsByCompanyQuery({
    variables: {
      companyId,
      skuPackagingsToIgnore: [],
    },
  });
  const [catalogPackagings, setCatalogPackagings] = useAtom(companyPackagingsAtom);
  const setFilteredCatalogPackagings = useSetAtom(filteredCompanyPackagingsAtom);

  const defaultValues = useMemo((): DraftSubCatalog => {
    const values: DraftSubCatalog = {
      name: '',
      contracts: [],
      packagingsAvailability: {},
    };

    if (contractId) {
      values.contracts.push(contractId);
    }

    if (!subCatalogData?.sub_catalog_by_pk) {
      values.packagingsAvailability = catalogPackagings.reduce((acc, packaging) => {
        acc[packaging.sku] = {
          isAvailable: true,
        };

        return acc;
      }, {} as DraftSubCatalogAvailabilityCollection);

      return values;
    }

    if (subCatalogData?.sub_catalog_by_pk) {
      if (copy) {
        values.name = t('price:name_copy', { name: subCatalogData.sub_catalog_by_pk.name });
      } else {
        values.name = subCatalogData.sub_catalog_by_pk.name;
        values.contracts = subCatalogData.sub_catalog_by_pk.contracts.reduce((acc, { id, status }) => {
          if (status === Contract_Status_Enum.Signed) {
            acc.push(id);
          }

          return acc;
        }, [] as string[]);
      }
    }

    const subCatalogPackagingsSKusSet = new Set(subCatalogData?.sub_catalog_by_pk?.sub_catalog_packagings.map((data) => data.packaging_sku));

    values.packagingsAvailability = catalogPackagings.reduce((acc, packaging) => {
      if (subCatalogPackagingsSKusSet.has(packaging.sku)) {
        acc[packaging.sku] = {
          isAvailable: true,
        };
      } else {
        acc[packaging.sku] = {
          isAvailable: false,
        };
      }

      return acc;
    }, {} as DraftSubCatalogAvailabilityCollection);

    return values;
  }, [catalogPackagings, subCatalogData]);

  useEffect((): void => {
    if (catalogPackagingsData?.packaging?.length) {
      setCatalogPackagings(catalogPackagingsData?.packaging);
      setFilteredCatalogPackagings(catalogPackagingsData?.packaging);
    }
  }, [catalogPackagingsData]);

  useEffect(() => {
    if (subCatalogId) {
      subCatalogByIdQuery({ variables: { id: subCatalogId }, fetchPolicy: 'network-only' });
    }
  }, []);

  return defaultValues;
}
