import { Box, Card, Container, Divider, Typography } from '@mui/material';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { RoutePaths } from '../routes/AppRoutes';
import { PageWrapper } from '../common/components/PageWrapper';
import BackButton from '../common/components/BackButton';
import TopBar from '../common/components/TopBar';
import KheopsAvatar from '../common/components/KheopsAvatar';
import ContactInformationCard from './contactInformation/ContactInformationCard';
import UserDepartments from './departments/UserDepartments';
import { currentContextAtom, userAtom } from '../state';
import { ellipsisStyle } from '../common/utils/style.utils';

export default function UserPage(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const user = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.NAVIGATION_MAIN}
          shouldCheckHistory={false}
          compact
        />
        <PersonOutlinedIcon height={28} width={28} />
        <Typography variant="titleLarge" sx={{ ml: 1, ...ellipsisStyle }}>
          {t('common:personal_information')}
        </Typography>
      </TopBar>
      <PageWrapper>
        <Container
          fixed
          disableGutters
        >
          <Card
            sx={{
              maxWidth: 640,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mx: 'auto',
              mt: {
                xs: 8,
                lg: 0,
              },
              mb: {
                xs: 10,
                lg: 0,
              },
            }}
          >
            <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
              <KheopsAvatar userAvatars={user.avatars} size={112} sx={{ borderRadius: 15 }} />
              <Typography variant="headlineSmall">
                {user.first_name} {user.last_name}
              </Typography>
            </Box>
            {realm === Business_Profile_Enum.Buyer && <UserDepartments />}
            {realm === Business_Profile_Enum.Buyer && <Divider />}
            <ContactInformationCard />
          </Card>
        </Container>
      </PageWrapper>
    </>
  );
}
