import { useCallback, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';
import { BasketItem, currentBasketItemsAtom, isBasketDrawerOpenAtom } from '../basket/state/state';
import { RoutePaths } from '../routes/AppRoutes';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { useGetReorderableOrderLazyQuery } from '../queries/__generated__/getReorderableOrder.generated';

interface UseAddOrderToBasketProps {
  orderReferenceId: string;
  onClick?: () => void;
}

interface AddOrderToBasket {
  addOrderToBasket: () => void;
  addedToBasket: boolean;
}

export function useAddOrderToBasket({ orderReferenceId, onClick }: UseAddOrderToBasketProps): AddOrderToBasket {
  const { t } = useTranslation(['basket', 'common', 'error']);
  const matchShoppingPage = useMatch(RoutePaths.SEARCH_PRODUCT);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const setIsBasketDrawerOpen = useSetAtom(isBasketDrawerOpenAtom);
  const [basketItems, editBasketItem] = useAtom(currentBasketItemsAtom);
  const [addedToBasket, setAddedToBasket] = useState(false);
  const [getReorderableOrder] = useGetReorderableOrderLazyQuery();

  const addOrderToBasket = useCallback(async () => {
    const { data } = await getReorderableOrder({
      variables: {
        orderReferenceId,
      },
      fetchPolicy: 'network-only',
    });

    const orderData = data!.getReorderableOrder!;

    const { availablePackagings: packagings, priceUpdatedPackagings, unavailablePackagings, contractReferenceId } = orderData;

    const newBasketItems = packagings.reduce((acc, { quantity, id }) => {
      const itemId = `${id}_${contractReferenceId}`;
      const oldBasketItem = basketItems.get(itemId);

      acc.push({
        id: oldBasketItem?.id,
        packagingId: id,
        quantity: oldBasketItem ? oldBasketItem.quantity + quantity : quantity,
        contractId: contractReferenceId,
      });

      return acc;
    }, [] as BasketItem[]);

    if (unavailablePackagings.length || priceUpdatedPackagings.length) {
      let label: string;

      if (unavailablePackagings.length && priceUpdatedPackagings.length) {
        label = t('basket:some_products_have_changed_since_last_order');
      } else if (unavailablePackagings.length) {
        label = unavailablePackagings.length > 1 ? t('basket:some_products_are_unavailable') : t('basket:one_product_is_unavailable', { name: unavailablePackagings[0] });
      } else {
        label = priceUpdatedPackagings.length > 1 ? t('basket:prices_have_changed_since_last_order') : t('basket:one_price_has_changed_since_last_order', { name: priceUpdatedPackagings[0] });
      }

      setCommonSnackbarProps({
        label,
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'warning',
        },
      });
    }

    editBasketItem(...newBasketItems);

    if (!matchShoppingPage) {
      setIsBasketDrawerOpen(true);
    }

    setAddedToBasket(true);
    setTimeout((): void => {
      setAddedToBasket(false);
      onClick?.();
    }, 500);
  }, [basketItems]);

  return {
    addOrderToBasket,
    addedToBasket,
  };
}
