import { Box, Card, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAtomValue } from 'jotai';
import { Link, generatePath } from 'react-router-dom';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { Business_Profile_Enum, OrderRecommendation } from 'kheops-graphql';
import { signedContractsAtom } from '../../state';
import { RoutePaths } from '../../routes/AppRoutes';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { ellipsisStyle, linkStyle } from '../../common/utils/style.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import AddOrderToBasketButton from '../../common/components/AddOrderToBasketButton';
import { longDateFormat } from '../../common/state/state';

export interface OrderRecommendationCardProps {
  recommendation: OrderRecommendation;
  onAddToBasket: () => void;
  sxCard?: SxProps<Theme>;
}

export default function OrderRecommendationCard({ recommendation, onAddToBasket, sxCard }: OrderRecommendationCardProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const currencyFormat = useCurrencyFormat();
  const contracts = useAtomValue(signedContractsAtom);
  const contract = contracts.find(({ reference_id }) => reference_id === recommendation!.contractReferenceId)!;
  const [clicked, setClicked] = useState(false);

  const setCardAsClicked = (): void => {
    setClicked(true);

    // This timeout is only used to let the card translation animation be played.
    setTimeout(() => {
      setClicked(false);
      onAddToBasket();
    }, 300);
  };

  return (
    <Card
      className={clicked ? 'kheops-clicked' : undefined}
      sx={{
        p: 2,
        flexShrink: 0,
        ...sxCard,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <SizedImage
          src={
            FileUtils.BuildCompanyImageUrl({
              photo: contract.company.photos[0]?.photo,
              size: { height: 98, width: 72 },
              type: Business_Profile_Enum.Supplier,
            })
          }
          borderRadius={6}
          alt={t('common:company')}
          height={98}
          width={72}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, minWidth: 0 }}>
          <Box component={Link} to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: contract.supplyingCompanyId })} sx={{ ...linkStyle }}>
            <Typography variant="bodyMedium" sx={{ ...ellipsisStyle }}>
              {contract.company.tradeName}
            </Typography>
          </Box>

          <Typography variant="bodySmall">
            {format(recommendation.orderCreatedAt, longDateFormat, { locale: fr })}
          </Typography>
          <Typography variant="titleSmall" color="primary">
            {currencyFormat.format(recommendation.totalPriceExlTax)}
          </Typography>
        </Box>
      </Box>
      <AddOrderToBasketButton
        orderReferenceId={recommendation.orderReferenceId}
        fullWidth
        onClick={setCardAsClicked}
      />
    </Card>
  );
}
