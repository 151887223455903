import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { RoutePaths } from '../../routes/AppRoutes';
import { useDeleteCustomPriceListMutation } from '../../mutations/__generated__/deleteCustomPriceList.generated';
import { CustomPriceListsByCompanyIdDocument, CustomPriceListsByCompanyIdQuery } from '../../queries/__generated__/customPriceListsByCompanyId.generated';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import useOpenable from '../../hooks/useOpenable';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import PropertyCard from '../../common/components/PropertyCard';
import StoresChip from '../StoresChip';

export interface CustomPriceListInfoBoxProps {
  customPriceList: CustomPriceListsByCompanyIdQuery['custom_price_list'][number];
}

export default function CustomPriceListInfoBox({ customPriceList }: CustomPriceListInfoBoxProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [deleteCustomPriceList] = useDeleteCustomPriceListMutation({
    refetchQueries: [CustomPriceListsByCompanyIdDocument, ContractsAsSupplierDocument],
    awaitRefetchQueries: true,
  });
  const { open, close, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMoreActionsClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleConfirm = (confirm: boolean): void => {
    close();

    if (confirm) {
      deleteCustomPriceList({ variables: { id: customPriceList.id } });

      setCommonSnackbarProps({
        label: t('common:delete_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  };

  return (
    <>
      <PropertyCard
        title={customPriceList.name}
        onClick={() => navigate(`${RoutePaths.CUSTOM_PRICE_LIST}?customPriceListId=${customPriceList.id}`)}
        subTitles={[
          <StoresChip
            storesList={customPriceList.contracts.map(({ buying_company }) => ({ tradeName: buying_company.tradeName, brand: buying_company.brand! }))}
          />,
        ]}
        actionButton={(
          <Button
            variant="text"
            onClick={handleMoreActionsClick}
            sx={{ mt: -0.5 }}
          >
            <MoreVertIcon />
          </Button>
        )}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 4,
            mt: 1,
            gap: 2,
          },
          '& .MuiMenuItem-root': {
            py: 1,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 26,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
            color: 'black',
          },
        }}
      >
        <MenuItem onClick={() => navigate(`${RoutePaths.CUSTOM_PRICE_LIST}?customPriceListId=${customPriceList.id}`)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:modify')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`${RoutePaths.CUSTOM_PRICE_LIST}?copyCustomPriceListId=${customPriceList.id}`)}>
          <ListItemIcon>
            <ContentCopyOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:duplicate')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={open}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={isOpen}
        onClose={handleConfirm}
        cancelLabel={t('common:cancel')}
        confirmLabel={t('common:delete')}
        title={t('price:delete_custom_price_list')}
        message={(
          <>
            <Typography variant="bodyMedium" color="secondary">
              {t('price:delete_custom_price_list_description')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                border: '1px solid',
                borderColor: 'divider',
                textAlign: 'left',
                borderRadius: 4,
                p: 2,
                mt: 3,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="bodyMedium">
                  {customPriceList.name}
                </Typography>
                {!!customPriceList.contracts.length && (
                  <Typography variant="bodySmall" color="secondary">
                    {customPriceList.contracts.map(({ buying_company }) => buying_company.tradeName).join(', ')}
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      />
    </>
  );
}
