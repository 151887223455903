import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { CompanyInfo } from './companyInfo/CompanyInfoCard';
import CompanyDistanceChip from '../common/components/CompanyDistanceChip';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';
import { currentContextAtom } from '../state';
import { ContractPreview } from '../hooks/useContracts';
import CommissionInfo from '../common/components/CommissionInfo';

interface CompanyInfoChipsProps {
  company: CompanyInfo;
  contract?: ContractPreview;
}

export default function CompanyInfoChips({ company, contract }: CompanyInfoChipsProps): React.JSX.Element {
  const { companyAddress, realm } = useAtomValue(currentContextAtom);
  let mov: number | undefined;

  if (contract?.status !== Contract_Status_Enum.Signed) {
    company.delivery_zones.forEach(({ administrative_areas, minimum_order_value }) => {
      if ((administrative_areas[0].area === 'ALL_AREAS' && mov === undefined) || administrative_areas.some(({ area }) => area === companyAddress.administrativeAreaLevel2)) {
        mov = minimum_order_value;
      }
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
        mb: 1,
        '& .MuiChip-root': {
          backgroundColor: 'surfaceContainerHigh.main',
        },
      }}
    >
      <CompanyDistanceChip targetGeoLoc={{ lat: company.addresses[0].latitude, lng: company.addresses[0].longitude }} />
      {mov !== undefined && (
        <MinimumOrderValueChip minimumOrderValue={mov as number} />
      )}
      {realm === Business_Profile_Enum.Supplier && !!contract?.supplier_commission_rate && (
        <CommissionInfo short commission={contract.supplier_commission_rate} />
      )}
    </Box>
  );
}
