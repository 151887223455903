import { Card, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Global_Product_Family_Enum } from 'kheops-graphql';

export interface GlobalDepartmentSettingProps {
  globalSetting: Global_Product_Family_Enum
}

export default function GlobalDepartmentSetting({ globalSetting }: GlobalDepartmentSettingProps): React.JSX.Element {
  const { t } = useTranslation(['settings']);

  return (
    <Card
      sx={{
        p: 2,
        backgroundColor: 'surfaceContainerLow.main',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'start',
      }}
    >
      <Chip
        variant="tag"
        color="surfaceContainerHigh"
        label={t(globalSetting === Global_Product_Family_Enum.All ? 'settings:all_departments' : 'settings:no_departments')}
      />
    </Card>
  );
}
