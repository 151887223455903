import { Chip } from '@mui/material';
import ViewHeadlineRoundedIcon from '@mui/icons-material/ViewHeadlineRounded';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';

export default function GtinTags(): React.JSX.Element {
  const attribute = 'hasGtin';
  const { t } = useTranslation(['search']);
  const { refine } = useRefinementList({ attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute);

  return (
    <>
      {currentRefinements.map((refinement) => (
        <Chip
          key={refinement}
          variant="filter"
          icon={<ViewHeadlineRoundedIcon sx={{ transform: 'rotate(90deg)' }} />}
          label={t(refinement === 'true' ? 'search:product_with_gtin' : 'search:product_without_gtin')}
          onDelete={() => refine(refinement as string)}
        />
      ))}
    </>
  );
}
