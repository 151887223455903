import { Box, Dialog, DialogContent, DialogTitle, Typography, Chip, Divider, Button } from '@mui/material';
import { useConfigure, useHits } from 'react-instantsearch';
import CloseIcon from '@mui/icons-material/Close';
import { formatPriceByBillingType, ComputePackagingWeight, round } from 'kheops-utils';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { AlgoliaHit } from 'instantsearch.js';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Billing_Type_Enum } from 'kheops-graphql';
import FullTextSearch from '../../common/components/FullTextSearch';
import SearchPagination from '../../search/SearchPagination';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { ProductHit } from '../../search/search';
import SearchUtils from '../../common/utils/search.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { editedOrderItemsAtom, orderAtom } from '../state/state';
import { usePackagingVersionByIdLazyQuery } from '../../queries/__generated__/packagingVersionById.generated';
import { formatPackagingPriceIncludingDescriptor } from '../../common/utils/common.utils';

interface AddPackagingsDialogProps {
  companyId: string;
  filters: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AddPackagingsDialog({ companyId, filters, isOpen, setIsOpen }: AddPackagingsDialogProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'discovery', 'common', 'search']);
  const [getPackagingVersionById, { loading }] = usePackagingVersionByIdLazyQuery();
  const orderData = useAtomValue(orderAtom);
  const currencyFormat = useCurrencyFormat();
  const pageSize = 5;
  useConfigure({
    hitsPerPage: pageSize,
    facetFilters: [
      `company_id:${companyId}`,
    ],
    filters,
  });
  const { items, results } = useHits<AlgoliaHit<ProductHit>>();
  const setEditItems = useSetAtom(editedOrderItemsAtom);

  const getCustomPriceListDiscountByPackagingSku = (sku: number): number | undefined => {
    return orderData.contract.custom_price_list?.custom_price_list_packagings.find(({ packaging_sku }) => packaging_sku === sku)?.discount;
  };

  const handleAddButton = async (id: string): Promise<void> => {
    const { data } = await getPackagingVersionById({
      variables: {
        id,
      },
    });
    const packagingData = data!.packaging_history_by_pk!;
    const customPriceListDiscount = getCustomPriceListDiscountByPackagingSku(packagingData.sku);
    const discountedPackagingPrice = round(packagingData.price * (1 - (customPriceListDiscount || orderData.contract.discount)));

    setEditItems({
      id: packagingData.id,
      quantity: 1,
      newItem: {
        quantity: 1,
        previousQuantity: 0,
        defaultQuantity: 0,
        price: discountedPackagingPrice,
        defaultPrice: 0,
        packaging: {
          ...packagingData,
          price: discountedPackagingPrice,
        },
        previousWeight: 0,
        defaultPackagingWeight: packagingData.base_unit?.billing_type === Billing_Type_Enum.WeightDependent ? ComputePackagingWeight(packagingData, packagingData.base_unit) : undefined,
        hasJustBeenAdded: true,
      },
    });
    setIsOpen(false);
  };

  const packagingsDisplays = useMemo(() => {
    return items.map((item) => {
      const discount = (getCustomPriceListDiscountByPackagingSku(parseInt(item.objectID.split('-')[0], 10)) || orderData.contract.discount);
      const discountedPackagingPrice = item.price * (1 - discount);
      const price = SearchUtils.getFormatedUnitPrice(
        {
          ...item,
          price: discountedPackagingPrice,
          volumePrice: {
            value: item.volumePrice.value * (1 - discount),
            unit: item.volumePrice.unit,
          },
        },
        currencyFormat.format,
      );

      return (
        <Box
          key={item.packaging_id}
          sx={{
            display: 'flex',
            py: 2,
            '&:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
          }}
        >
          <SizedImage
            src={FileUtils.BuildPackagingImageUrl({
              photo: item.photo,
              type: item.family,
              size: { height: 160, fit: 'contain' },
            })}
            borderRadius={0}
            alt={t('products:csu')}
            width={72}
            height={72}
          />
          <Box
            sx={{
              flex: 1,
              pl: 1,
            }}
          >
            <Typography variant="bodyMedium">{item.name}</Typography>
            <Box sx={{ my: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
              <Chip variant="tag" label={item.baseUnit} />
              <Typography variant="bodyMedium">{item.packaging}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                flexDirection: {
                  sm: 'row',
                  xs: 'column',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="titleMedium" color="primary">
                  {price}
                </Typography>
                <Typography variant="bodySmall" color="secondary">
                  {formatPackagingPriceIncludingDescriptor(
                    t,
                    formatPriceByBillingType(currencyFormat.format(discountedPackagingPrice), item.billingType),
                    item.packagingTradeItemUnitDescriptor,
                  )}
                </Typography>
              </Box>
              <LoadingButton
                variant="outlined"
                loading={loading}
                startIcon={<AddShoppingCartIcon />}
                onClick={() => handleAddButton(item.packaging_id)}
              >
                {t('common:add')}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      );
    });
  }, [items, loading, orderData]);

  const handleCancel = (): void => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 960,
          },
          /* height is set to fit current packagingDisplays, if their height was to change, for example adding new information,
          then this height should we changed accordingly as well
          */
          maxHeight: '95%',
          height: 1080,
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{ display: 'flex', alignItems: 'start' }}
        >
          <Typography variant="displayMedium" sx={{ flex: 1, ml: 5 }}>{t('products:add_a_base_unit')}</Typography>
          <Button variant="text" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </Button>
        </Box>

        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: {
              sm: 340,
              xs: 263,
            },
            mb: 2,
            mx: 'auto',
            pt: 0.25,
          }}
        >
          <FullTextSearch label={t('discovery:search_a_product')} />
        </Box>
        {
          results?.nbHits
            ? (
              <>
                <Box>
                  {packagingsDisplays}
                </Box>
                {
                  results.nbHits > pageSize && (
                    <SearchPagination
                      sx={{
                        '& .MuiPagination-ul': {
                          justifyContent: 'center',
                        },
                      }}
                    />
                  )
                }
              </>
            )
            : (<Typography textAlign="center" sx={{ my: 3 }}>{t('search:no_result_for_this_search')}</Typography>)
          }
      </DialogContent>
    </Dialog>
  );
}
