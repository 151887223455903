import { atom } from 'jotai';
import { CompanyPackaging } from '../../state';

export interface DraftSubCatalogAvailability {
  isAvailable: boolean;
}

export type DraftSubCatalogAvailabilityCollection = {
  [packagingSku: string]: DraftSubCatalogAvailability
}

export interface DraftSubCatalog {
  name: string;
  contracts: string[];
  packagingsAvailability: DraftSubCatalogAvailabilityCollection;
}

export const filteredCompanyPackagingsAtom = atom<CompanyPackaging[]>([]);
export const displayedCompanyPackagingsAtom = atom<CompanyPackaging[]>([]);
