import { InputAdornment, SxProps } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { Dispatch, SetStateAction } from 'react';
import CheckboxAutocomplete from '../../common/components/CheckboxAutocomplete';
import { ProductFamily } from '../../hooks/useSortedProductFamiliesMap';

interface ProductSubFamilyFilterProps {
  productFamilyMap: ProductFamily[];
  selectedProductFamilyMap: ProductFamily[];
  setProductFamilies: Dispatch<SetStateAction<Product_Sub_Family_Name_Enum[]>>;
  sx?: SxProps;
}

export default function ProductSubFamilyFilter({ productFamilyMap, selectedProductFamilyMap, setProductFamilies, sx }: ProductSubFamilyFilterProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);

  return (
    <CheckboxAutocomplete
      options={productFamilyMap}
      onChange={(values) => setProductFamilies(values.map((option) => option.value))}
      values={selectedProductFamilyMap}
      label={t('discovery:departments')}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={sx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: 0.5 }}>
            <ShoppingBasketOutlinedIcon sx={{ ml: 0.5, fontSize: '1.125rem' }} />
          </InputAdornment>
        ),
        sx: {
          minHeight: 48,
        },
      }}
    />
  );
}
