import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'basket',
      'chat',
      'catalogs',
      'comments',
      'common',
      'contracts',
      'date',
      'discovery',
      'error',
      'login',
      'offline',
      'order',
      'order-status',
      'packaging',
      'price',
      'products',
      'product-family',
      'search',
      'settings',
      'validation',
    ],
    fallbackLng: 'fr',
    supportedLngs: ['fr'],
    preload: ['fr'],
    returnNull: false,
    interpolation: { escapeValue: false },
    debug: import.meta.env.DEV,
  });

export default i18n;
