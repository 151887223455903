import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { Box, Typography } from '@mui/material';

export interface BaseUnitGtinProps {
  gtin: string;
}

export default function BaseUnitGtin({ gtin }: BaseUnitGtinProps): React.JSX.Element {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <DocumentScannerIcon sx={{ mr: 0.5, rotate: '90deg', color: 'text.secondary', fontSize: '1.25rem' }} />
      <Typography variant="bodySmall" color="onSurfaceVariant.main">
        {gtin}
      </Typography>
    </Box>
  );
}
