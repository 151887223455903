import { Box, Button, Card, Grid2, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { useState, MouseEvent } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { OrderPreview } from '../../hooks/useOrders';
import { RoutePaths } from '../../routes/AppRoutes';
import OrderStatusChip from '../tracking-page/OrderStatusChip';
import OrderSuggestionChip from '../tracking-page/OrderSuggestionChip';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { getCompanyShortName } from '../../common/utils/common.utils';
import BrandBox from '../../common/components/BrandBox';
import { useAddOrderToBasket } from '../../hooks/useAddOrderToBasket';
import { longDateFormat } from '../../common/state/state';

interface OrderRowProps {
  order: OrderPreview;
}

export default function OrderRow({ order }: OrderRowProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'order', 'price', 'basket']);
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const currencyFormat = useCurrencyFormat();
  const navigate = useNavigate();
  const { addOrderToBasket } = useAddOrderToBasket({ orderReferenceId: order.reference_id });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onRowClick = (): void => {
    navigate(
      generatePath(RoutePaths.ORDER_TRACKING, { orderReferenceId: order.reference_id }),
    );
  };

  const handleIconButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleReorderClick = (event: MouseEvent): void => {
    addOrderToBasket();
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const MenuComponent = (
    <>
      <Button variant="text" onClick={handleIconButtonClick}>
        <MoreVertIcon />
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 4,
          },
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            px: 2,
          }}
          onClick={handleReorderClick}
        >
          <AddShoppingCartIcon sx={{ height: 18, width: 18, mr: 0.5 }} />
          <Typography>
            {t('basket:order_again')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <Card
      onClick={onRowClick}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        p: 2,
        borderRadius: 4,
      }}
    >
      <Grid2
        container
        size={{ xs: 12 }}
        data-testid={order.reference_id}
      >
        <Grid2
          size={{ xs: 10, lg: 6 }}
          sx={{
            display: 'flex',
            [theme.breakpoints.down('lg')]: {
              mb: 1.5,
            },
          }}
        >
          <SizedImage
            src={
              FileUtils.BuildCompanyImageUrl({
                photo: order.contract.company.photos[0]?.photo,
                type: order.contract.company.business_profile,
                size: { height: 128, width: 128 },
              })
            }
            alt={t('common:company')}
            height={80}
            width={80}
            sx={{
              mr: 2,
              '& .Kheops-brand': {
                width: 32,
                height: 32,
                borderRadius: 3,
                position: 'absolute',
                bottom: 8,
                right: 8,
              },
              '& .Kheops-brandLogo': {
                width: 18,
              },
            }}
            borderRadius={4}
            overlay={
              order.contract.company.brand && <BrandBox brand={order.contract.company.brand} />
            }
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography variant="labelSmall" color="secondary">{`# ${order.friendly_id}`}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: 0.5,
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  flexBasis: {
                    xs: '100%',
                    lg: 'auto',
                  },
                  flex: 1,
                  mr: {
                    lg: 1,
                    md: 0,
                  },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isDownLg && !!order.suggestor_id && (
                  <OrderSuggestionChip short />
                )}
                <Typography variant="bodyLarge">
                  {getCompanyShortName(order.contract.company.tradeName, order.contract.company.business_profile)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <OrderStatusChip status={order.status} />
                {!isDownLg && !!order.suggestor_id && <OrderSuggestionChip />}
              </Box>
            </Box>
          </Box>
        </Grid2>
        {isDownLg && (
          <Grid2 size={{ xs: 2 }} sx={{ textAlign: 'right' }}>
            {MenuComponent}
          </Grid2>
        )}
        <Grid2 container size={{ xs: 12, lg: 6 }} columnSpacing={{ xs: 3, lg: 0 }}>
          <Grid2
            size={{ lg: 4 }}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0.5 }}
          >
            <Typography variant="bodySmall">
              {t('order:order_date')}
            </Typography>
            <Typography variant="bodyMedium">
              {format(order.created_at, longDateFormat)}
            </Typography>
          </Grid2>
          <Grid2
            size={{ xs: 'auto', lg: 4 }}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0.5 }}
          >
            <Typography variant="bodySmall">
              {t('price:price_tax_included')}
            </Typography>
            <Typography variant="bodyMedium">
              {currencyFormat.format(order.total_price_to_pay_incl_tax)}
            </Typography>
          </Grid2>
          <Grid2
            size={{ xs: 'auto', lg: 4 }}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0.5 }}
          >
            <Typography variant="bodySmall">
              {t('price:price_without_tax_total')}
            </Typography>
            <Typography variant="bodyMedium">
              {currencyFormat.format(order.total_price_to_pay_excl_tax)}
            </Typography>
          </Grid2>
        </Grid2>
        <Box
          sx={{
            position: 'absolute',
            right: 16,
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          {MenuComponent}
        </Box>
      </Grid2>
    </Card>
  );
}
